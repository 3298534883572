import { Outlet } from 'react-router-dom';
import PageContainer from '../../components/common/PageContainer';
import ProductTable from './ProductTable';

const ProductList = () => {
  return (
    <PageContainer title='Product Management'>
      <ProductTable />
      <Outlet />
    </PageContainer>
  );
};

export default ProductList;
