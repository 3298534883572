import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import type { ApiError, RootState } from '../index';
import type { UserAccount, UserAccountState } from './types';
import { AxiosError } from 'axios';
import { showSuccessToast } from '../toast/slice';
import userAccountsApi from '../../services/userAccounts';

const initialState: UserAccountState = {
  status: 'idle',
  error: undefined,
  data: [],
};

export const getUserAccounts = createAsyncThunk<
  UserAccount[],
  void,
  {
    rejectValue: ApiError;
    state: RootState;
  }
>('useraccounts/getAll', async (_, thunkAPI) => {
  try {
    const response = await userAccountsApi.getUserAccounts();
    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createUserAccount = createAsyncThunk<
  UserAccount,
  Partial<UserAccount>,
  {
    rejectValue: ApiError;
    state: RootState;
  }
>('useraccounts/create', async (userAccountData, thunkAPI) => {
  try {
    const response = await userAccountsApi.createUserAccount(userAccountData);

    // append user role
    const role = thunkAPI.getState().roles.data.find((r) => r.id === userAccountData.roleId);

    response.userRole = role as UserAccount['userRole'];

    // show success toast
    thunkAPI.dispatch(showSuccessToast('User Account has been successfully added'));

    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateUserAccount = createAsyncThunk<
  { id: string },
  {
    id: string;
    userAccount: Partial<UserAccount>;
  },
  {
    rejectValue: ApiError;
    state: RootState;
  }
>('useraccount/update', async (args, thunkAPI) => {
  try {
    const { id, userAccount } = args;

    const response = await userAccountsApi.updateUserAccount(id, userAccount);

    // show success toast
    thunkAPI.dispatch(showSuccessToast('User Account has been successfully updated'));

    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deleteUserAccount = createAsyncThunk<
  void,
  string,
  {
    rejectValue: ApiError;
    state: RootState;
  }
>('useraccounts/delete', async (id, thunkAPI) => {
  try {
    const response = await userAccountsApi.deleteUserAccount(id);

    // show success toast
    thunkAPI.dispatch(showSuccessToast('User Account has been successfully deleted'));

    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const userAccountsSlice = createSlice({
  name: 'userAccountsSlice',
  initialState,
  reducers: {
    clearPurchaseOrderState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getUserAccounts.pending, (state) => {
      state.status = 'fetching';
    });

    builder.addCase(getUserAccounts.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(createUserAccount.fulfilled, (state, action) => {
      state.data.unshift(action.payload);
    });
    builder.addCase(updateUserAccount.fulfilled, (state, action) => {
      const { id, userAccount } = action.meta.arg;
      const updatedRoleIndex = state.data.findIndex((item) => item.id === id);
      const current = state.data[updatedRoleIndex];
      state.data[updatedRoleIndex] = { ...current, ...userAccount } as UserAccount;
    });
    builder.addCase(deleteUserAccount.fulfilled, (state, action) => {
      state.data = state.data.filter((item) => item.id !== action.meta.arg); // arg is `id`
    });
    builder.addMatcher(
      isAnyOf(createUserAccount.pending, updateUserAccount.pending, deleteUserAccount.pending),
      (state) => {
        state.status = 'loading';
      }
    );
    builder.addMatcher(
      isAnyOf(
        getUserAccounts.fulfilled,
        createUserAccount.fulfilled,
        updateUserAccount.fulfilled,
        deleteUserAccount.fulfilled
      ),
      (state) => {
        state.status = 'idle';
        state.error = undefined;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getUserAccounts.rejected,
        createUserAccount.rejected,
        updateUserAccount.rejected,
        deleteUserAccount.rejected
      ),
      (state, action) => {
        state.status = 'idle';

        if (action.payload) {
          state.error = action.payload.error;
        } else {
          state.error = action.error.message;
        }
      }
    );
  },
});

export const { clearPurchaseOrderState } = userAccountsSlice.actions;

export default userAccountsSlice.reducer;
