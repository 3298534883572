import { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/AddRounded';

interface AppMediaUploadButtonProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
}

const UploadButton = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 12,
  fontWeight: 500,
  gap: '8px',
  padding: '36px 36px',
  border: `1px dashed #ccc`,
  borderRadius: 4,
  cursor: 'pointer',
  transition: 'all 0.3s',

  '&.disabled': {
    pointerEvents: 'none',
    color: '#ccc',

    '& > div': {
      backgroundColor: '#ccc',
    },
  },

  '&:hover': {
    borderColor: theme.palette.primary.main,
  },

  '& > div': {
    width: 38,
    height: 38,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const AppMediaUploadButton = forwardRef<HTMLInputElement, AppMediaUploadButtonProps>(
  ({ label, name, ...rest }, ref) => {
    return (
      <Stack>
        <label htmlFor={name} style={{ width: 'max-content' }}>
          <input ref={ref} accept='image/*' type='file' hidden name={name} id={name} {...rest} />
          <UploadButton className={rest.disabled ? 'disabled' : ''}>
            <Stack>
              <AddIcon />
            </Stack>
            {label ? label : 'Upload Photo'}
          </UploadButton>
        </label>
      </Stack>
    );
  }
);

export default AppMediaUploadButton;
