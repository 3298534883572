import { flexRender } from '@tanstack/react-table';

import orderColumns from './orderColumnDef';
import { useAppSelector } from '../../../store';
import { selectOrderState } from '../../../store/order/selectors';
import { TablePagination } from '@mui/material';
import { selectPurchaseOrderState } from '../../../store/purchaseOrder/selectors';
import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from '../../../components/common/app-table/ui';
import useDefaultTable from '../../../components/common/app-table/useDefaultTable';
import { Order } from '../../../store/order/types';
import { useRequestedAssetAccess } from '../../../hooks/usePermissions';

const OrderTable = () => {
  const { data, status } = useAppSelector(selectOrderState);
  const { status: poStatus } = useAppSelector(selectPurchaseOrderState);

  const acc = useRequestedAssetAccess();

  const displayedColumns = orderColumns.filter(
    (col) => acc?.displayFields.includes(col.id as keyof Order) || col.id === 'actions'
  );

  const { dataCount, pageIndex, pageSize, table } = useDefaultTable<Order>(data, displayedColumns);

  const loading = status === 'fetching' || poStatus === 'fetching';

  const emptyText = 'No orders yet';

  return (
    <>
      <AppTableContainer>
        <AppTable stickyHeader>
          <AppTableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <AppTableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <AppTableCell
                    align='center'
                    key={header.id}
                    className={[
                      header.column.columnDef.enablePinning ? 'pinned' : '',
                      header.column.columnDef.id === 'status' ? 'order-status' : '',
                    ].join(' ')}
                  >
                    <AppTableSort
                      canSort={header.column.getCanSort()}
                      currentSort={header.column.getIsSorted()}
                      onSort={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </AppTableSort>
                  </AppTableCell>
                ))}
              </AppTableRow>
            ))}
          </AppTableHead>
          <AppTableBody dataCount={dataCount} loading={loading} emptyText={emptyText}>
            {table.getRowModel().rows.map((row) => (
              <AppTableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <AppTableCell
                    key={cell.id}
                    align='center'
                    sx={{ color: row.original.status === 'Failed' ? 'error.main' : 'unset' }}
                    className={[
                      cell.column.columnDef.enablePinning ? 'pinned' : '',
                      cell.column.columnDef.id === 'status' ? 'order-status' : '',
                    ].join(' ')}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </AppTableCell>
                ))}
              </AppTableRow>
            ))}
          </AppTableBody>
        </AppTable>
      </AppTableContainer>
      <TablePagination
        sx={{ bgcolor: 'white' }}
        component='div'
        count={dataCount}
        page={pageIndex}
        onPageChange={(e, page) => table.setPageIndex(page)}
        rowsPerPage={pageSize}
        onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
        rowsPerPageOptions={[10, 20, 50, 100]}
      />
    </>
  );
};

export default OrderTable;
