import useAuth from '../../hooks/useAuth';
import { useAppDispatch, useAppSelector } from '../../store';
import { login } from '../../store/auth/slice';
import { Stack, TextField, Typography, IconButton } from '@mui/material';
import { Link, Navigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormHelperText from '@mui/material/FormHelperText';
import { getAuthState } from '../../store/auth/selector';
import { Box } from '@mui/system';
import AuthActionButton from '../../components/common/AuthActionButton';

type AuthFields = { email: string; password: string };

const loginSchema = yup.object({
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string().required('Password is required'),
});

const LoginPage = () => {
  const isAuth = useAuth();
  const { status, error: serverError } = useAppSelector(getAuthState);

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AuthFields>({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(loginSchema),
    mode: 'onChange',
  });

  const [isPassword, setIsPassword] = useState(true);

  const onSubmit = ({ email, password }: AuthFields) => {
    dispatch(login({ email, password }));
  };

  if (isAuth) {
    return <Navigate to='/' replace />;
  }

  return (
    <Stack
      height='100vh'
      width='100vw'
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={2}
      bgcolor='#FDFDFD'
    >
      <Box
        sx={{
          padding: 6,
          boxShadow: '0px 0px 15px rgba(0,0,0,0.15)',
          margin: 10,
          borderRadius: 2,
          bgcolor: 'white',
        }}
      >
        <Stack mb={4}>
          <img
            alt='coinbase'
            width='auto'
            height={50}
            style={{ alignSelf: 'center' }}
          />
        </Stack>
        <Stack
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          sx={{ width: '300px' }}
        >
          <Stack spacing={1} width='300px' mb={1}>
            <Typography fontSize={13} component='label' htmlFor='email'>
              Email
            </Typography>
            <TextField
              size='small'
              placeholder='Email'
              {...register('email')}
              error={!!errors.email}
              helperText={errors.email?.message}
              inputProps={{ sx: { fontSize: 14 } }}
            />
          </Stack>
          <Stack spacing={1} width='300px' mb={2}>
            <Typography fontSize={13} component='label' htmlFor='password'>
              Password
            </Typography>
            <TextField
              size='small'
              type={isPassword ? 'password' : 'text'}
              placeholder='Password'
              {...register('password')}
              error={!!errors.password}
              helperText={errors.password?.message}
              InputProps={{
                sx: { fontSize: 14 },
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => setIsPassword((prev) => !prev)}
                      aria-label='toggle password visibility'
                      edge='end'
                    >
                      {isPassword ? (
                        <VisibilityOff fontSize='small' />
                      ) : (
                        <Visibility fontSize='small' />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {serverError && (
              <FormHelperText
                error
                sx={{ pt: 2, justifyContent: 'center', display: 'flex' }}
              >
                {serverError}
              </FormHelperText>
            )}
          </Stack>
          <Stack gap={2} alignItems='center'>
            <AuthActionButton
              label='Login'
              isForLogin={false}
              isLoading={status === 'loading' ? true : false}
              width={180}
            />
            <Typography fontSize={14}>
              Forgot Password? Click{' '}
              <Link to={`/auth/forgot-password`}>Here</Link>
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

export default LoginPage;
