import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { useEffect, useRef } from 'react';
import assetApi from '../../services/asset';
import store, { RootState, AppStatus, ApiError, useAppDispatch } from '../index';
import { Product } from '../product/types';
import { showSuccessToast } from '../toast/slice';

export interface CartItem {
  productId: string;
  quantity: number;
}

export interface AssetCart {
  poNumber: string;
  cart: CartItem[];
}

interface ExtraState {
  status: AppStatus;
}

export const createAssetTransaction = createAsyncThunk<
  void,
  string,
  {
    rejectValue: ApiError;
    state: RootState;
  }
>('asset-transaction/create', async (poNumber, thunkAPI) => {
  try {
    const cart = cartAdapter.getSelectors().selectAll(thunkAPI.getState().cart);
    const response = await assetApi.createTransaction({ poNumber, cart });

    thunkAPI.dispatch(showSuccessToast('Asset Checkout was successful'));

    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const cartAdapter = createEntityAdapter<CartItem>({
  selectId: (cart) => cart.productId,
});

const cartSlice = createSlice({
  name: 'cart',
  initialState: cartAdapter.getInitialState<ExtraState>({
    status: 'idle',
  }),
  reducers: {
    loadCartFromCache: cartAdapter.setAll,
    changeQuantity: cartAdapter.setOne,
    removeFromCart: cartAdapter.removeOne,
    clearCart: cartAdapter.removeAll,
  },
  extraReducers: (builder) => {
    builder.addCase(createAssetTransaction.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(createAssetTransaction.fulfilled, (state) => {
      state.status = 'idle';
    });
    builder.addCase(createAssetTransaction.rejected, (state) => {
      state.status = 'error';
    });
  },
});

export const cartReducer = cartSlice.reducer;

export const { changeQuantity, removeFromCart, clearCart, loadCartFromCache } = cartSlice.actions;

const cartSelector = cartAdapter.getSelectors<RootState>((state) => state.cart);

export const getCartByProductId = (id: string) => (state: RootState) =>
  cartSelector.selectById(state, id);

export const getTotalCartQuantity = (state: RootState) => {
  const totalQty = Object.values(state.cart.entities).reduce((acc, curr) => {
    return acc + (curr ? curr.quantity : 0);
  }, 0);

  return totalQty;
};

export const getTotalPrice = (state: RootState) => {
  const productIdsInCart = state.cart.ids;
  const productsInCart = state.products.data.reduce<Record<string, Product>>((prev, curr) => {
    return productIdsInCart.includes(curr.id) ? { ...prev, [curr.id]: curr } : prev;
  }, {});

  const totalPrice = cartSelector.selectAll(state).reduce((total, cart) => {
    return total + productsInCart[cart.productId]?.price * cart.quantity;
  }, 0);

  return totalPrice;
};

export const getCartStatus = (state: RootState) => state.cart.status;

// hook for caching the cart
// useful when connection is lost
// AIM:
// 1. store in local storage the latest cart data after every cart action dispatch
// 2. load whatever cart data is in the local storage as cartSlice init value on page reload
const key = 'coin-asset-cart';

export function useCachedCartData() {
  const hasLoaded = useRef(false);

  const dispatch = useAppDispatch();

  // for initial load
  useEffect(() => {
    if (hasLoaded.current) return;

    if (typeof window === 'undefined') return;

    try {
      const storedCartData = window.localStorage.getItem(key);
      const cartData = storedCartData ? JSON.parse(storedCartData) : [];
      dispatch(loadCartFromCache(cartData));
    } catch (error) {
      dispatch(loadCartFromCache([]));
    }

    return () => {
      hasLoaded.current = true;
    };
  }, [dispatch]);
}

export function useSaveCartToCache() {
  const cartData = store.getState().cart.entities;
  const dispatch = useAppDispatch();
  const firstMount = useRef(false);

  // for saving into local storage
  useEffect(() => {
    if (!firstMount.current) {
      if (typeof window === 'undefined') return;

      try {
        const storedCartData = window.localStorage.getItem(key);
        const cartData = storedCartData ? JSON.parse(storedCartData) : [];
        dispatch(loadCartFromCache(cartData));
      } catch (error) {
        dispatch(loadCartFromCache([]));
      }
      firstMount.current = true;
      return;
    }

    window.localStorage.setItem(key, JSON.stringify(cartData));
    console.log('Saved');
  }, [cartData, dispatch]);
}

export function clearCartCache() {
  window.localStorage.removeItem(key);
}
