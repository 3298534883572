import { useNavigate } from 'react-router-dom';

import { Stack, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import ActionButton from '../../../components/common/ActionButton';
import type { Role } from '../../../store/role/types';

const RoleActions = ({ role }: { role: Role }) => {
  const navigate = useNavigate();

  const { id } = role;

  const goToRoleForm = () => navigate(`/users/roles/edit/${id}`);

  const openDeleteDialog = () => navigate(`/users/roles/delete/${id}`);

  return (
    <Stack direction='row' alignItems='center' gap={1.5}>
      <Tooltip title='Edit' placement='top'>
        <ActionButton aria-label='edit' onClick={goToRoleForm} disabled={role.id === 'ROLE::1'}>
          <EditIcon />
        </ActionButton>
      </Tooltip>
      <Tooltip title='Delete' placement='top' onClick={openDeleteDialog}>
        <ActionButton aria-label='delete' disabled={role.id === 'ROLE::1'}>
          <DeleteIcon />
        </ActionButton>
      </Tooltip>
    </Stack>
  );
};

export default RoleActions;
