import { Route, Routes } from 'react-router-dom';
import AppToast from './components/common/AppToast';
import AuthenticatedRoute from './components/common/AuthenticatedRoute';
import AuthPage from './modules/Auth';
import AppContent from './modules/Layout';

function App() {
  return (
    <>
      <Routes>
        <Route path='/auth/*' element={<AuthPage />} />
        <Route
          path='*'
          element={
            <AuthenticatedRoute>
              <AppContent />
            </AuthenticatedRoute>
          }
        />
      </Routes>
      <AppToast />
    </>
  );
}

export default App;
