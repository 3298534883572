import { Stack, Tooltip } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { useNavigate } from 'react-router-dom';
import ViewButton from '../../../components/common/ViewButton';
import { PurchaseOrder } from '../../../store/purchaseOrder/types';
import ActionButton from '../../../components/common/ActionButton';
import { useRequestedAssetAccess } from '../../../hooks/usePermissions';

interface PurchaseOrderActionsProps {
  purchaseOrder: PurchaseOrder;
  state?: any;
}

const PurchaseOrderActions = ({ purchaseOrder, state }: PurchaseOrderActionsProps) => {
  const { id } = purchaseOrder;

  const navigate = useNavigate();

  const acc = useRequestedAssetAccess();

  const goToPurchaseOrderForm = () => navigate(`/requested-assets/edit/${id}`);

  const openDeleteDialog = () => navigate(`/requested-assets/delete/${id}`);

  return (
    <Stack direction='row' alignItems='center' justifyContent='center' gap={1.5}>
      {acc?.access.editPO && (
        <Tooltip title='Edit' placement='top'>
          <ActionButton aria-label='edit' onClick={goToPurchaseOrderForm}>
            <EditIcon />
          </ActionButton>
        </Tooltip>
      )}
      {acc?.access.deletePO && (
        <Tooltip title='Delete' placement='top' onClick={openDeleteDialog}>
          <ActionButton aria-label='delete'>
            <DeleteIcon />
          </ActionButton>
        </Tooltip>
      )}
      <ViewButton
        state={state.original.poNumber}
        viewLink={`/requested-assets/po/${state.original.id}`}
      />
    </Stack>
  );
};

export default PurchaseOrderActions;
