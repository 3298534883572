import { useEffect } from 'react';
import { useAppDispatch } from '../store';
import { getUserAccounts } from '../store/accounts/slice';

export default function useGetUserAccounts() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserAccounts());
  }, [dispatch]);
}
