import { IconButton, Tooltip } from '@mui/material';
import ExportIcon from '@mui/icons-material/FileDownloadOutlined';

interface ExportButtonProps {
  onClick: () => void;
}

const ExportButton = ({ onClick }: ExportButtonProps) => {
  return (
    <Tooltip title='Export'>
      <IconButton
        onClick={onClick}
        aria-label='export'
        color='primary'
        sx={{
          bgcolor: 'primary.main',
          color: 'white',
          borderRadius: 2,
          '&:hover': { bgcolor: 'primary.main' },
        }}
      >
        <ExportIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ExportButton;
