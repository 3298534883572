import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import AppDialog from './AppDialog';
interface Ownprops {
  showDialog: boolean;
  cancelNavigation: any;
  confirmNavigation: any;
  blockMessage: string;
}

const ConfirmPromptDialog: React.FC<Ownprops> = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
  blockMessage,
}) => {
  return (
    <AppDialog
      open={showDialog}
      onDialogClose={cancelNavigation}
      title='Discard Changes'
      maxWidth='sm'
    >
      <Typography textAlign='center'>{blockMessage}</Typography>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='center'
        mt={4}
        mb={2}
        gap={2}
      >
        <Button
          variant='contained'
          color='error'
          disableElevation
          sx={{ textTransform: 'capitalize', pointerEvents: 'initial' }}
          onClick={confirmNavigation}
        >
          Yes
        </Button>
        <Button
          color='secondary'
          variant='outlined'
          disableElevation
          sx={{ textTransform: 'capitalize' }}
          onClick={cancelNavigation}
        >
          No
        </Button>
      </Stack>
    </AppDialog>
  );
};

export default ConfirmPromptDialog;
