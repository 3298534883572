import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AppDialog from '../../components/common/AppDialog';

import useCurrentProduct from '../../hooks/useCurrentProduct';
import { appColors } from '../../theme';
import { formatCurrency } from '../../utils/helpers';
import AddToOrderButton from './AddToOrderButton';
import AssetMedia from './AssetMedia';

const productPropertyMap = {
  'Product Description': 'description',
  "Manufacturer's Part Number": 'partNumber',
  'Asset Type': 'assetType',
  'IT Spec': 'itSpec',
  Brand: 'brand',
  'Stock Status': 'stockStatus',
  'Lead Time': 'leadTime',
};

const AssetDetail = () => {
  const navigate = useNavigate();

  const product = useCurrentProduct();

  const handleClose = () => navigate('/assets', { replace: true });

  if (!product) return null;

  const title = `${product.name} - ${formatCurrency(product.price, 'Php')}`;

  return (
    <AppDialog open onDialogClose={handleClose} title={title} maxWidth='md'>
      <Box display='grid' gridTemplateColumns='300px auto' gap={10}>
        <Box>
          <AssetMedia productMedia={product.media} />
        </Box>
        <Stack>
          <Stack gap={2} maxHeight='350px' overflow='auto'>
            {Object.entries(productPropertyMap).map(([label, property]) => {
              if (!product[property as keyof typeof product]) return null;

              return (
                <Stack key={property} gap={1}>
                  <Typography
                    fontSize={13}
                    sx={{ textTransform: 'uppercase', color: appColors.textGray }}
                  >
                    {label}
                  </Typography>
                  <Typography fontSize={14}>{product[property as keyof typeof product]}</Typography>
                </Stack>
              );
            })}
          </Stack>
          <Stack mt={3}>
            <AddToOrderButton productId={product.id} />
          </Stack>
        </Stack>
      </Box>
    </AppDialog>
  );
};

export default AssetDetail;
