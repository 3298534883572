import { Routes, Route } from 'react-router-dom';
import NoAccessView from '../../components/common/NoAccessView';

import useGetProducts from '../../hooks/useGetProducts';
import ProductDeleteDialog from './ProductDeleteDialog';

import ProductForm from './ProductForm';
import ProductList from './ProductList';

const ProductManagementPage = () => {
  const hasPermission = useGetProducts();

  if (!hasPermission) return <NoAccessView />;

  return (
    <>
      <Routes>
        <Route path='/' element={<ProductList />}>
          <Route path='delete/:id' element={<ProductDeleteDialog />} />
        </Route>
        <Route path='/create' element={<ProductForm />} />
        <Route path='/edit/:id' element={<ProductForm />} />

        <Route path='*' element={<ProductList />} />
      </Routes>
    </>
  );
};

export default ProductManagementPage;
