import { forwardRef } from 'react';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectProps } from '@mui/material/Select';

import ArrowDownIcon from '@mui/icons-material/ExpandMoreOutlined';

import { SelectOptionType } from './form-types';
import { SxProps } from '@mui/system';
import { appColors } from '../../../theme';

interface OwnSelectProps {
  label: string;
  options?: SelectOptionType[];
  placeholderColor?: string;
  helperText?: string;
  containerStyles?: SxProps;
}

type AppSelectProps = OwnSelectProps & Omit<SelectProps, 'label | containerStyles'>;

const AppSelect = forwardRef<HTMLInputElement, AppSelectProps>(
  ({ label, options, placeholderColor, helperText, containerStyles, ...rest }, ref) => {
    return (
      <FormControl sx={{ mb: 1, ...containerStyles }}>
        <Typography
          component='label'
          color={appColors.textGray}
          fontSize={13}
          htmlFor={rest.name}
          mb='5px'
        >
          {label}
        </Typography>
        <Select
          id={rest.name}
          ref={ref}
          {...rest}
          size='small'
          displayEmpty
          IconComponent={ArrowDownIcon}
          sx={{
            fontSize: 14,
            // '& .MuiSvgIcon-root': { color: 'primary.main' },
            ...rest.sx,
          }}
          renderValue={(selected) => {
            if (!selected) {
              return (
                <Typography
                  component='small'
                  fontSize={14}
                  fontWeight={400}
                  color={appColors.textGray}
                >
                  {rest.placeholder}
                </Typography>
              );
            }
            return options?.find((i) => i.value === (selected as string))?.label;
          }}
        >
          <MenuItem disabled value=''>
            <Typography
              component='small'
              fontSize={14}
              fontWeight={400}
              color={placeholderColor ? placeholderColor : '#ddd'}
            >
              {rest.placeholder}
            </Typography>
          </MenuItem>
          {options &&
            options.map((item) => (
              <MenuItem key={item.id} value={item.value} sx={{ width: '100%', fontSize: 14 }}>
                {item.label}
              </MenuItem>
            ))}
        </Select>
        {rest.error && (
          <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default AppSelect;
