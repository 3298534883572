import React from "react";
import { Stack, Typography } from "@mui/material";
import { appColors } from "../../../theme";

interface Props {
  row: any;
}

const PurchaseOrderStatus = ({ row }: Props) => {
 
  return (
    <Stack
      width='100%'
      borderRadius='100px'
      bgcolor={appColors.darkerBluishWhite}
    >
      <Stack
        py={0.5}
        px={1}
        width={
          row.original.completionStatus < 10
            ? 10 + "%"
            : row.original.completionStatus + "%"
        }
        borderRadius='100px'
        bgcolor={
          row.original.completionStatus < 1 ? "transparent" : "primary.main"
        }
        color='white'
      >
        <Typography>{row.original.completionStatus}%</Typography>
      </Stack>
    </Stack>
  );
};

export default PurchaseOrderStatus;
