import axios from 'axios';

// CONSTANTS
export const apiEndpoints = {
  login: 'login',
  users: 'users',
  orders: 'orders',
  ordersByPo: 'orders/po',
  purchaseOrders: 'purchase-orders',
  products: 'products',
  roles: 'roles',
  assets: 'assets',
  resetPassword: 'reset-password',
  forgotPassword: 'forgot-password',
};

// ENV VARIABLES/TOKENS
const API_URL = process.env.REACT_APP_API_URL;
const LOGIN_TOKEN = process.env.REACT_APP_LOGIN_TOKEN;
const REGISTER_TOKEN = process.env.REACT_APP_REGISTER_TOKEN;
const RESET_PASSWORD_TOKEN = process.env.REACT_APP_CHANGE_PASSWORD_TOKEN;
const FORGOT_TOKEN = process.env.REACT_APP_FORGOT_PASSWORD_TOKEN;
// axios instance
export const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const storedToken = localStorage.getItem('accessToken');

    let _config = { ...config };

    if (storedToken) {
      const token = JSON.parse(storedToken);
      _config = {
        ...config,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return _config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const loginApiClient = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Bearer ${LOGIN_TOKEN}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const registerApiClient = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Bearer ${REGISTER_TOKEN}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const changePasswordApiClient = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Bearer ${RESET_PASSWORD_TOKEN}`,

    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const forgotPasswordApiClient = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Bearer ${FORGOT_TOKEN}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
