import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from '@mui/material';

export interface AppDialogProps extends DialogProps {
  title: string;
  onDialogClose: () => void;
}

const AppDialog = (props: AppDialogProps) => {
  const { open, onDialogClose, title, children, ...rest } = props;
  return (
    <Dialog
      open={open}
      //   onClose={onDialogClose}
      fullWidth
      maxWidth='sm'
      {...rest}
    >
      <DialogContent>
        <Stack mt={1} mb={3}>
          <Typography fontSize={22} fontWeight={500}>
            {title}
          </Typography>
          <IconButton
            onClick={onDialogClose}
            disableRipple
            aria-label='close'
            size='small'
            sx={{ position: 'absolute', top: 12, right: 12 }}
          >
            <CloseIcon fontSize='small' sx={{ color: '#222' }} />
          </IconButton>
        </Stack>
        <Divider sx={{ mb: 3 }} />
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default AppDialog;
