import { useState, MouseEvent } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';

const statusOptions = ['All', 'Active', 'Inactive'];

const AccountActiveFilter = ({
  onClick,
}: {
  onClick: (value: string) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label='filter'
        size='small'
        id='filter-button'
        aria-controls={open ? 'filter-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <ExpandMore fontSize='inherit' />
      </IconButton>
      <Menu
        id='filter-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          'aria-labelledby': 'filter-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {statusOptions.map((option) => (
          <MenuItem
            key={option}
            onClick={() => {
              onClick(option);
            }}
            sx={{ fontSize: 13 }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default AccountActiveFilter;
