import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/ClearOutlined';
import InputAdornment from '@mui/material/InputAdornment';

type AppSearchFieldProps = {
  searchKeyword: string;
  onSearchKeywordChange: (value: string) => void;
  placeholder?: string;
  id?: string;
};

const AppSearchField = ({
  searchKeyword,
  onSearchKeywordChange,
  placeholder = 'Search',
  id,
}: AppSearchFieldProps) => {
  return (
    <TextField
      aria-label='search'
      sx={{
        bgcolor: 'white',
        flex: 1,
        minWidth: '240px',
        borderColor: 'white',
        '& fieldset': { borderColor: 'white' },
      }}
      size='small'
      id={id}
      placeholder={placeholder}
      value={searchKeyword}
      onChange={(e) => onSearchKeywordChange(e.target.value)}
      InputProps={{
        sx: { fontSize: '14px' },
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon fontSize='small' sx={{ color: '#ccc' }} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position='end'>
            {searchKeyword && (
              <IconButton
                edge='end'
                onClick={() => onSearchKeywordChange('')}
                aria-label='clear search query'
              >
                <ClearIcon fontSize='small' />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default AppSearchField;
