import { AssetCart } from '../store/asset/cartSlice';
import { apiClient, apiEndpoints } from './apiClient';

const createTransaction = async (assetCart: AssetCart) => {
  const response = await apiClient.post<void>(apiEndpoints.assets, assetCart);
  return response.data;
};

const assetApi = {
  createTransaction,
};

export default assetApi;
