import { useAppSelector } from '../store';
import { useParams } from 'react-router-dom';

import { selecUserAccountsById } from '../store/accounts/selectors';

export default function useCurrentUser() {
  const { id } = useParams<{ id: string }>();
  const currentUser = useAppSelector(selecUserAccountsById(id as string));

  return currentUser;
}
