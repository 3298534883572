import { apiClient, apiEndpoints } from './apiClient';
import { Order } from '../store/order/types';

type GetReturnType = {
  status: string;
  count: number;
  orders: Order[];
};

type GetOneReturnType = {
  status: string;
  order: Order;
};

const getOrdersByPO = async (purchaseOrderId: string) => {
  const response = await apiClient.get<GetReturnType>(
    `${apiEndpoints.ordersByPo}/${purchaseOrderId}`
  );
  return response.data.orders;
};

const getOrderById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(`${apiEndpoints.orders}/${id}`);
  return response.data.order;
};

const importOrders = async (formData: FormData) => {
  const response = await apiClient.post<GetReturnType>(`${apiEndpoints.orders}/import`, formData);
  return response.data.orders;
};

const updateOrderStatus = async (id: string, status: Order['status']) => {
  const response = await apiClient.put<GetOneReturnType>(`${apiEndpoints.orders}/${id}`, {
    status,
  });
  return response.data.order;
};

const orderApi = {
  getOrdersByPO,
  getOrderById,
  importOrders,
  updateOrderStatus,
};

export default orderApi;
