import { Route, Routes, Navigate } from 'react-router-dom';
import useIdle from '../hooks/useIdle';
import { useModuleToShowFirst } from '../hooks/usePermissions';
import AssetPage from './Asset/AssetPage';
import ProductManagementPage from './Product/ProductManagementPage';
import RequestedAssetPage from './RequestedAsset/RequestedAssetPage';
import UsersPage from './Users/UsersPage';

const AMIContent = () => {
  const routeToShowFirst = useModuleToShowFirst();

  useIdle();

  return (
    <Routes>
      <Route path='assets/*' element={<AssetPage />} />
      <Route path='requested-assets/*' element={<RequestedAssetPage />} />
      <Route path='products/*' element={<ProductManagementPage />} />
      <Route path='users/*' element={<UsersPage />} />
      <Route path='/' element={<Navigate to={routeToShowFirst} />} />
      {/* <Route path='*' element={<Navigate to='/' />} /> */}
    </Routes>
  );
};

export default AMIContent;
