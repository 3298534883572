import { useAppSelector } from "../store";
import { useParams } from 'react-router-dom';

import { selectPurchaseOrderById } from "../store/purchaseOrder/selectors";

export default function useCurrentPO() {
    const { id } = useParams<{ id: string }>();
    const currentPO = useAppSelector(selectPurchaseOrderById(id as string));
  
    return currentPO;
  }