import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import type { ApiError, RootState } from '../index';
import type { Product, ProductState } from './types';
import productApi from '../../services/product';
import { showSuccessToast } from '../toast/slice';

const initialState: ProductState = {
  status: 'idle',
  error: undefined,
  data: [],
};

export const getProducts = createAsyncThunk<
  Product[],
  void,
  {
    rejectValue: ApiError;
    state: RootState;
  }
>('products/getAll', async (_, thunkAPI) => {
  try {
    const response = await productApi.getProducts();

    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createProduct = createAsyncThunk<
  Product,
  Partial<Product>,
  {
    rejectValue: ApiError;
    state: RootState;
  }
>('products/create', async (productData, thunkAPI) => {
  try {
    const response = await productApi.createProduct(productData);

    // show success toast
    thunkAPI.dispatch(showSuccessToast('Product has been successfully added'));

    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const duplicateProduct = createAsyncThunk<
  Product,
  string,
  {
    rejectValue: ApiError;
    state: RootState;
  }
>('products/duplicate', async (id, thunkAPI) => {
  try {
    const product = thunkAPI.getState().products.data.find((p) => p.id === id);

    if (!product) throw new Error('The product that you are duplicating does not exist');

    const productData = {
      ...product,
      name: product.name + '-copy',
    } as Partial<Product>;

    delete productData.id;

    const response = await productApi.createProduct(productData);

    // show success toast
    thunkAPI.dispatch(showSuccessToast('Product has been successfully duplicated'));

    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateProduct = createAsyncThunk<
  Product,
  {
    id: string;
    product: Partial<Product>;
    prevDisplayInAssetsVal?: boolean;
    forDisplayInAsset?: boolean;
  },
  {
    rejectValue: ApiError;
    state: RootState;
  }
>('products/update', async (args, thunkAPI) => {
  try {
    const { id, product, forDisplayInAsset } = args;

    const response = await productApi.updateProduct(id, product);

    if (!forDisplayInAsset) {
      // show success toast
      thunkAPI.dispatch(showSuccessToast('Product has been successfully updated'));
    }

    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deleteProduct = createAsyncThunk<
  void,
  string,
  {
    rejectValue: ApiError;
    state: RootState;
  }
>('products/delete', async (id, thunkAPI) => {
  try {
    const response = await productApi.deleteProduct(id);

    // show success toast
    thunkAPI.dispatch(showSuccessToast('Product has been successfully deleted'));

    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    clearProductState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state) => {
      state.status = 'fetching';
    });
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(updateProduct.pending, (state, action) => {
      // optimistic update
      const { id, product, forDisplayInAsset } = action.meta.arg;

      if (forDisplayInAsset) {
        const updatedProductIndex = state.data.findIndex((item) => item.id === id);

        state.data[updatedProductIndex].isDisplayedOnAssets = Boolean(product.isDisplayedOnAssets);
      }
    });
    builder.addCase(updateProduct.fulfilled, (state, action) => {
      const { id } = action.meta.arg;
      const updatedProductIndex = state.data.findIndex((item) => item.id === id);

      state.data[updatedProductIndex] = action.payload;
    });
    builder.addCase(updateProduct.rejected, (state, action) => {
      // revoke isDisplayedOnAssets update when there's an error
      const { id, forDisplayInAsset, prevDisplayInAssetsVal } = action.meta.arg;

      if (forDisplayInAsset) {
        const updatedProductIndex = state.data.findIndex((item) => item.id === id);

        state.data[updatedProductIndex].isDisplayedOnAssets = Boolean(prevDisplayInAssetsVal);
      }
    });
    builder.addCase(deleteProduct.fulfilled, (state, action) => {
      state.data = state.data.filter((item) => item.id !== action.meta.arg); // arg is `id`
    });
    builder.addMatcher(
      isAnyOf(createProduct.fulfilled, duplicateProduct.fulfilled),
      (state, action) => {
        state.data.unshift(action.payload);
      }
    );
    builder.addMatcher(
      isAnyOf(
        createProduct.pending,
        duplicateProduct.pending,
        updateProduct.pending,
        deleteProduct.pending
      ),
      (state) => {
        state.status = 'loading';
      }
    );
    builder.addMatcher(
      isAnyOf(
        getProducts.fulfilled,
        createProduct.fulfilled,
        duplicateProduct.fulfilled,
        updateProduct.fulfilled,
        deleteProduct.fulfilled
      ),
      (state) => {
        state.status = 'idle';
        state.error = undefined;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getProducts.rejected,
        createProduct.rejected,
        duplicateProduct.rejected,
        updateProduct.rejected,
        deleteProduct.rejected
      ),
      (state, action) => {
        state.status = 'idle';

        if (action.payload) {
          state.error = action.payload.error;
        } else {
          state.error = action.error.message;
        }
      }
    );
  },
});

const productReducer = productSlice.reducer;

// actions
export const { clearProductState } = productSlice.actions;

// selectors
export const selectProductState = (state: RootState) => state.products;

export const selectProductById = (id: string) => (state: RootState) =>
  state.products.data.find((p) => p.id === id);

export const selectProductsForAsset =
  (stockFilter: 'All' | Product['stockStatus']) => (state: RootState) =>
    stockFilter === 'All'
      ? state.products.data.filter((p) => p.isDisplayedOnAssets)
      : state.products.data.filter((p) => p.isDisplayedOnAssets && p.stockStatus === stockFilter);

export const selectProductsInCart = (state: RootState) => {
  const productIdsInCart = state.cart.ids;
  const productsInCart = state.products.data.filter((p) => productIdsInCart.includes(p.id));
  return productsInCart;
};

export default productReducer;
