import { useEffect, useState } from 'react';
// @ts-ignore
import createActivityDetector from 'activity-detector';
import { useAppDispatch } from '../store';
import { logout } from '../store/auth/slice';

export default function useIdle() {
  const [isIdle, setIsIdle] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // 21600000
    const activityDetector = createActivityDetector({
      timeToIdle: 21600000, // 6 hr
      inactivityEvents: [],
    });
    activityDetector.on('idle', () => {
      console.log('IDLE: logging out...');
      setIsIdle(true);
      dispatch(logout());
    });
    activityDetector.on('active', () => {
      console.log('ACTIVE');
      setIsIdle(false);
    });

    return () => {
      activityDetector.stop();
    };
  }, [dispatch]);

  return isIdle;
}
