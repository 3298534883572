import { Stack, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/system';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { useNavigate } from 'react-router-dom';
import { UserAccount } from '../../../store/accounts/types';

const ActionButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  borderRadius: 6,
  width: 32,
  height: 32,

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },

  '& > svg': {
    fontSize: 18,
  },
}));
interface Ownprops {
  userAccount: UserAccount;
}

const AccountsActionButton = ({ userAccount }: Ownprops) => {
  const { id } = userAccount;

  const navigate = useNavigate();

  const goToUserAccountForm = () => navigate(`/users/accounts/edit/${id}`);

  const openDeleteDialog = () => navigate(`/users/accounts/delete/${id}`);

  return (
    <Stack direction='row' gap={1.5}>
      <Tooltip title='Edit' placement='top'>
        <ActionButton aria-label='edit' onClick={goToUserAccountForm}>
          <EditIcon />
        </ActionButton>
      </Tooltip>
      <Tooltip title='Delete' placement='top' onClick={openDeleteDialog}>
        <ActionButton aria-label='delete'>
          <DeleteIcon />
        </ActionButton>
      </Tooltip>
    </Stack>
  );
};

export default AccountsActionButton;
