import { flexRender } from '@tanstack/react-table';
import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from '../../components/common/app-table/ui';
import useDefaultTable from '../../components/common/app-table/useDefaultTable';
import { useAppSelector } from '../../store';
import { selectProductsInCart } from '../../store/product/slice';
import { Product } from '../../store/product/types';
import cartColumns from './cartColumns';

const CheckoutTable = () => {
  const productsInCart = useAppSelector(selectProductsInCart);
  const { table, dataCount } = useDefaultTable<Product>(productsInCart, cartColumns);

  return (
    <AppTableContainer>
      <AppTable stickyHeader>
        <AppTableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <AppTableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <AppTableCell key={header.id}>
                  <AppTableSort
                    canSort={header.column.getCanSort()}
                    currentSort={header.column.getIsSorted()}
                    onSort={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </AppTableSort>
                </AppTableCell>
              ))}
            </AppTableRow>
          ))}
        </AppTableHead>
        <AppTableBody dataCount={dataCount}>
          {table.getRowModel().rows.map((row) => (
            <AppTableRow key={row.id} sx={{ cursor: 'pointer' }}>
              {row.getVisibleCells().map((cell) => (
                <AppTableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </AppTableCell>
              ))}
            </AppTableRow>
          ))}
        </AppTableBody>
      </AppTable>
    </AppTableContainer>
  );
};

export default CheckoutTable;
