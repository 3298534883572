import { Outlet } from 'react-router-dom';
import PageContainer from '../../../components/common/PageContainer';
import useGetUserAccounts from '../../../hooks/useGetUserAccounts';
import AccountsTable from './AccountsTable';

const AccountsPage = () => {
  useGetUserAccounts();
  return (
    <PageContainer title='User Account'>
      <AccountsTable />
      <Outlet />
    </PageContainer>
  );
};

export default AccountsPage;
