import { useState, useEffect } from 'react';

type MediaPreviewOptions = {
  initialSrc: File | string;
  initialFileName: string;
};

export default function useMediaPreview({ initialSrc, initialFileName }: MediaPreviewOptions) {
  const [mediaPreview, setMediaPreview] = useState<string | null>(() =>
    typeof initialSrc === 'string' ? initialSrc : null
  );

  const [mediaType, setMediaType] = useState<string>('');
  const [longMediaType, setLongMediaType] = useState<string>('');

  // BEGIN: handling media preview
  useEffect(() => {
    if (typeof initialSrc !== 'string') {
      const reader = new FileReader();
      reader.onloadend = () => {
        setMediaPreview(reader.result as string);
      };
      reader.readAsDataURL(initialSrc);
      setLongMediaType(initialSrc.type);
      setMediaType(initialSrc.type.substring(0, 5));
    }
  }, [initialSrc]);
  // END: handling media preview

  return {
    mediaType,
    longMediaType,
    mediaPreview,
    fileName: typeof initialSrc === 'string' ? initialFileName : initialSrc?.name,
  };
}
