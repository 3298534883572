import { apiClient, apiEndpoints } from './apiClient';
import { PurchaseOrder } from '../store/purchaseOrder/types';
import { Order } from '../store/order/types';

type GetReturnType = {
  status: string;
  count: number;
  purchaseOrders: PurchaseOrder[];
};

type GetOneReturnType = {
  status: string;
  purchaseOrder: PurchaseOrder;
};
type GetOrdersReturnType = {
  status: string;

  count: number;
  orders: Order[];
};
const getPurchaseOrders = async () => {
  const poResponse = await apiClient.get<GetReturnType>(
    apiEndpoints.purchaseOrders
  );

  // fetch the orders
  const ordersResponse = await apiClient.get<GetOrdersReturnType>(
    apiEndpoints.orders
  );

  const completionStatusByPo = (poId: string) => {
    const ordersByPo = ordersResponse.data.orders.filter(
      (o) => o.purchaseOrderId === poId
    );

    const totalDelivered = ordersByPo.filter(
      (item) => item.status === 'Delivered'
    ).length;

    const completionStatus =
      totalDelivered === 0
        ? 0
        : Math.trunc((totalDelivered / ordersByPo.length) * 100);
    return completionStatus;
  };
  const purchaseOrderData = poResponse.data.purchaseOrders.map((po) => ({
    ...po,
    completionStatus: completionStatusByPo(po.id),
  }));

  return purchaseOrderData;
};

const getPurchaseOrderById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.purchaseOrders}/${id}`
  );
  return response.data.purchaseOrder;
};

const createPurchaseOrder = async (
  purchaseOrderData: Partial<PurchaseOrder>
) => {
  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.purchaseOrders,
    purchaseOrderData
  );
  return response.data.purchaseOrder;
};

const updatePurchaseOrder = async (
  id: string,
  purchaseOrderData: Partial<PurchaseOrder>
) => {
  const formData: Partial<PurchaseOrder> = {
    ...purchaseOrderData,
  };

  const purchaseOrder = { ...formData };
  delete purchaseOrder.id;

  const response = await apiClient.put<GetOneReturnType>(
    `${apiEndpoints.purchaseOrders}/${id}`,
    purchaseOrder
  );
  return response.data.purchaseOrder;
};

const deletePurchaseOrder = async (id: string) => {
  const response = await apiClient.delete(
    `${apiEndpoints.purchaseOrders}/${id}`
  );

  return response.data.purchaseOrder;
};

const purchaseOrderApi = {
  getPurchaseOrders,
  getPurchaseOrderById,
  createPurchaseOrder,
  updatePurchaseOrder,
  deletePurchaseOrder,
};

export default purchaseOrderApi;
