import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import type { ApiError, RootState } from '../index';
import type { PurchaseOrder, PurchaseOrderState } from './types';
import purchaseOrderApi from '../../services/purchaseOrder';
import { AxiosError } from 'axios';
import { showSuccessToast } from '../toast/slice';

const initialState: PurchaseOrderState = {
  status: 'idle',
  error: undefined,
  data: [],
};

export const getPurchaseOrders = createAsyncThunk<
  PurchaseOrder[],
  void,
  {
    rejectValue: ApiError;
    state: RootState;
  }
>('requested-assets/getAll', async (_, thunkAPI) => {
  try {
    const response = await purchaseOrderApi.getPurchaseOrders();
    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createPurchaseOrder = createAsyncThunk<
  PurchaseOrder,
  Partial<PurchaseOrder>,
  {
    rejectValue: ApiError;
    state: RootState;
  }
>('requested-assets/create', async (purchaseOrderData, thunkAPI) => {
  try {
    const response = await purchaseOrderApi.createPurchaseOrder(
      purchaseOrderData
    );

    // show success toast
    thunkAPI.dispatch(
      showSuccessToast('Purchase Order has been successfully added')
    );

    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updatePurchaseOrder = createAsyncThunk<
  PurchaseOrder,
  {
    id: string;
    purchaseOrder: Partial<PurchaseOrder>;
  },
  {
    rejectValue: ApiError;
    state: RootState;
  }
>('requested-assets/update', async (args, thunkAPI) => {
  try {
    const { id, purchaseOrder } = args;

    const response = await purchaseOrderApi.updatePurchaseOrder(
      id,
      purchaseOrder
    );

    // show success toast
    thunkAPI.dispatch(
      showSuccessToast('Purchase Order has been successfully updated')
    );

    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deletePurchaseOrder = createAsyncThunk<
  void,
  string,
  {
    rejectValue: ApiError;
    state: RootState;
  }
>('requested-assets/delete', async (id, thunkAPI) => {
  try {
    const response = await purchaseOrderApi.deletePurchaseOrder(id);

    // show success toast
    thunkAPI.dispatch(
      showSuccessToast('Purchase Order has been successfully deleted')
    );

    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const purchaseOrderSlice = createSlice({
  name: 'purchaseOrders',
  initialState,
  reducers: {
    clearPurchaseOrderState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getPurchaseOrders.pending, (state) => {
      state.status = 'fetching';
    });
    builder.addCase(getPurchaseOrders.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(createPurchaseOrder.fulfilled, (state, action) => {
      state.data.unshift(action.payload);
    });
    builder.addCase(deletePurchaseOrder.fulfilled, (state, action) => {
      state.data = state.data.filter((item) => item.id !== action.meta.arg); // arg is `id`
    });

    builder.addMatcher(
      isAnyOf(
        createPurchaseOrder.pending,
        updatePurchaseOrder.pending,
        deletePurchaseOrder.pending
      ),
      (state) => {
        state.status = 'loading';
      }
    );
    builder.addMatcher(
      isAnyOf(
        getPurchaseOrders.fulfilled,
        createPurchaseOrder.fulfilled,
        updatePurchaseOrder.fulfilled,
        deletePurchaseOrder.fulfilled
      ),
      (state) => {
        state.status = 'idle';
        state.error = undefined;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getPurchaseOrders.rejected,
        createPurchaseOrder.rejected,
        updatePurchaseOrder.rejected,
        deletePurchaseOrder.rejected
      ),
      (state, action) => {
        state.status = 'idle';

        if (action.payload) {
          state.error = action.payload.error;
        } else {
          state.error = action.error.message;
        }
      }
    );
  },
});

export const { clearPurchaseOrderState } = purchaseOrderSlice.actions;

export default purchaseOrderSlice.reducer;
