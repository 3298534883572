import SidebarContextProvider from "./sidebarContext";

import SidebarMenuItems from "./SidebarMenuItems";
import { SidebarProps } from "./types";

const Sidebar = (props: SidebarProps) => {
  return (
    <SidebarContextProvider contextProps={props}>
      <SidebarMenuItems />
    </SidebarContextProvider>
  );
};

export default Sidebar;
