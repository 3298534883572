import { Routes, Route } from 'react-router-dom';

import OrderHistory from './Order/OrderHistory';
import OrderList from './Order/OrderList';
import PurchaseOrderList from './PurchaseOrder/PurchaseOrderList';
import PurchaseOrderForm from './PurchaseOrder/PurchaseOrderForm';
import PurchaseOrderDeleteDialog from './PurchaseOrder/PurchaseOrderDeleteDialog';
import { usePermission } from '../../hooks/usePermissions';
import NoAccessView from '../../components/common/NoAccessView';

const RequestedAssetPage = () => {
  const hasPermission = usePermission('Requested Asset');

  if (!hasPermission) return <NoAccessView />;

  return (
    <Routes>
      <Route path='/' element={<PurchaseOrderList />}>
        <Route path='delete/:id' element={<PurchaseOrderDeleteDialog />} />
      </Route>

      <Route path='/create' element={<PurchaseOrderForm />} />
      <Route path='/edit/:id' element={<PurchaseOrderForm />} />

      <Route path='po/:purchaseOrderId' element={<OrderList />} />
      <Route
        path='po/:purchaseOrderId/orders/:orderId'
        element={<OrderHistory />}
      />
      <Route path='*' element={<PurchaseOrderList />} />
    </Routes>
  );
};

export default RequestedAssetPage;
