import { createColumnHelper, flexRender } from '@tanstack/react-table';
import { Stack, Typography } from '@mui/material';
import type { OrderHistoryItem } from '../../../store/order/types';
import useDefaultTable from '../../../components/common/app-table/useDefaultTable';

import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from '../../../components/common/app-table/ui';
import { formatHistoryDate, sortOderHistory } from '../../../utils/helpers';

// history table column def
const columnHelper = createColumnHelper<OrderHistoryItem>();

const orderHistoryColumns = [
  columnHelper.accessor('dateUpdated', {
    id: 'dateUpdated',
    header: 'Date & Time',
    cell: (props) => formatHistoryDate(props.getValue()),
  }),
  columnHelper.accessor('statusMessage', {
    id: 'statusMessage',
    header: 'Description',
    cell: (props) => props.getValue(),
  }),
];

interface OrderUpdatesProps {
  orderHistory: OrderHistoryItem[];
}

const OrderUpdates = ({ orderHistory }: OrderUpdatesProps) => {
  const orderUpdates = sortOderHistory(orderHistory);

  const { table, dataCount } = useDefaultTable<OrderHistoryItem>(orderUpdates, orderHistoryColumns);

  return (
    <Stack>
      <Typography component='h4' fontWeight='bold' mb={1}>
        Order Updates
      </Typography>
      <AppTable stickyHeader>
        <AppTableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <AppTableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <AppTableCell sx={{ py: 1.2 }} key={header.id}>
                  <AppTableSort
                    canSort={header.column.getCanSort()}
                    currentSort={header.column.getIsSorted()}
                    onSort={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </AppTableSort>
                </AppTableCell>
              ))}
            </AppTableRow>
          ))}
        </AppTableHead>
        <AppTableBody dataCount={dataCount} emptyText='No updates yet'>
          {table.getRowModel().rows.map((row) => (
            <AppTableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <AppTableCell sx={{ py: 1.4 }} key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </AppTableCell>
              ))}
            </AppTableRow>
          ))}
        </AppTableBody>
      </AppTable>
    </Stack>
  );
};

export default OrderUpdates;
