import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { ProductMedia } from '../../store/product/types';
import { Box, IconButton, styled } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

import defaultProductThumbnail from '../../assets/images/product-default-thumbnail.png';

interface MediaPreviewProps {
  src: string;
  alt: string;
}

const MediaPreview = ({ src, alt }: MediaPreviewProps) => {
  return (
    <Box
      width='100%'
      height='300px'
      mx='auto'
      textAlign='center'
      px={2}
      sx={{ '& > img': { objectFit: 'contain', aspectRatio: '1 / 1' } }}
    >
      <img src={src} alt={alt} width='100%' />
    </Box>
  );
};

interface BtnProps {
  clickHandler: () => void;
  canBeClicked: boolean;
  label: string;
}

const SliderButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 2,
  backgroundColor: `rgba(0, 0, 0, 0.2)`,
  color: '#eee',
  width: 32,
  height: 32,

  '&.next': {
    right: 0,
  },

  '&.prev': {
    left: 0,
  },

  '&:hover': {
    backgroundColor: `rgba(0, 0, 0, 0.6)`,
  },
}));

const SliderIndicator = styled(IconButton)(({ theme }) => ({
  zIndex: 2,
  backgroundColor: `rgba(0, 0, 0, 0.2)`,
  color: '#eee',
  width: '10px',
  height: '10px',
  margin: '4px',
  padding: 0,

  '&:hover': {
    backgroundColor: `rgba(0, 0, 0, 0.6)`,
  },

  '&.selected': {
    backgroundColor: '#0052FF',
  },
}));

const NextButton = (props: BtnProps) => {
  return (
    <SliderButton
      className='next'
      onClick={props.clickHandler}
      aria-label={props.label}
      disabled={props.canBeClicked}
    >
      <ChevronRight />
    </SliderButton>
  );
};

const PrevButton = (props: BtnProps) => {
  return (
    <SliderButton
      className='prev'
      onClick={props.clickHandler}
      aria-label={props.label}
      disabled={props.canBeClicked}
    >
      <ChevronLeft />
    </SliderButton>
  );
};

const AssetMedia = ({ productMedia }: { productMedia: ProductMedia[] }) => {
  if (productMedia.length === 0)
    return <MediaPreview src={defaultProductThumbnail} alt='coinbase asset' />;

  if (productMedia.length === 1)
    return <MediaPreview src={productMedia[0].source as string} alt={productMedia[0].fileName} />;

  return (
    <Box
      sx={{
        '& .control-dots': {
          margin: 0,
        },
      }}
    >
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        showIndicators={productMedia.length > 1}
        emulateTouch
        infiniteLoop
        renderArrowNext={(clickHandler, hasNext, label) => (
          <NextButton clickHandler={clickHandler} canBeClicked={hasNext} label={label} />
        )}
        renderArrowPrev={(clickHandler, hasPrev, label) => (
          <PrevButton clickHandler={clickHandler} canBeClicked={hasPrev} label={label} />
        )}
        renderIndicator={(clickHandler, isSelected, index, label) => (
          <SliderIndicator
            onClick={clickHandler}
            aria-label={label}
            className={isSelected ? 'selected' : ''}
          />
        )}
      >
        {productMedia.map(({ source, fileName }, index) => (
          <MediaPreview key={`${fileName}_${index}`} src={source as string} alt={fileName} />
        ))}
      </Carousel>
    </Box>
  );
};

export default AssetMedia;
