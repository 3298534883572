import { Typography, Stack, Button } from '@mui/material';
import AppDialog from '../../components/common/AppDialog';

interface ChangePasswordSuccessDialogProps {
  title: string;
  message: string;
  onOk: () => void;
}

const ChangePasswordSuccessDialog = ({
  message,
  title,
  onOk,
}: ChangePasswordSuccessDialogProps) => {
  return (
    <AppDialog open onDialogClose={onOk} title={title}>
      <Typography textAlign='center'>{message}</Typography>

      <Stack
        direction='row'
        alignItems='center'
        justifyContent='center'
        mt={4}
        mb={2}
        gap={2}
      >
        <Button
          variant='contained'
          disableElevation
          sx={{ textTransform: 'capitalize' }}
          onClick={onOk}
        >
          Ok
        </Button>
      </Stack>
    </AppDialog>
  );
};

export default ChangePasswordSuccessDialog;
