import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Stack,
  Typography,
  InputAdornment,
  IconButton,
  FormHelperText,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import AppTextField from '../../components/common/forms/AppTextField';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import { getAuthState } from '../../store/auth/selector';
import ChangePasswordSuccessDialog from './ChangePasswordSuccessDialog';
import { setNewPassword } from '../../store/auth/slice';
import AuthActionButton from '../../components/common/AuthActionButton';

const passwordCriteria = [
  'The password length must be between 8-36 characters',
  'The password must contain at least one lower case letter',
  'The password must contain at least one upper case letter',
  'The password must contain at least one number',
  'The password must contain at least one special character',
];

type Inputs = {
  newPassword: string;
  confirmPassword: string;
};

type ResetPassParams = {
  token: string;
};

const validationSchema = yup.object({
  newPassword: yup
    .string()
    .matches(
      /^(?=.{8,36}$)/,
      'The password length must be between 8-36 characters'
    )
    .matches(
      /^(?=.*[a-z])/,
      'The password must contain at least one lower case letter'
    )
    .matches(
      /^(?=.*[A-Z])/,
      'The password must contain at least one upper case letter'
    )
    .matches(/^(?=.*[0-9])/, 'The password must contain at least one number')
    .matches(
      /^(?=.*[!@#%&])/,
      'The password must contain at least one special character'
    )
    .required('This field is required.')
    .trim(),
  confirmPassword: yup
    .string()
    .trim()
    .required('This field is required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords do not match.'),
});

const PasswordVisibilityButton = ({
  isPassword,
  onToggle,
}: {
  isPassword: boolean;
  onToggle: () => void;
}) => {
  return (
    <IconButton
      onClick={onToggle}
      aria-label='toggle password visibility'
      edge='end'
    >
      {isPassword ? (
        <Visibility fontSize='small' />
      ) : (
        <VisibilityOff fontSize='small' />
      )}
    </IconButton>
  );
};

const ResetPasswordPage = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordConfirmShown, setPasswordConfirmShown] = useState(false);
  const [successShown, setSuccessShown] = useState(false);

  const navigate = useNavigate();
  const { token } = useParams<ResetPassParams>();
  const { status, error: serverError } = useAppSelector(getAuthState);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: { newPassword: '', confirmPassword: '' },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const newPass = watch('newPassword');

  const onSubmit: SubmitHandler<Inputs> = ({
    newPassword,
    confirmPassword,
  }) => {
    if (!token) return;
    if (status === 'loading') return;

    dispatch(setNewPassword({ newPassword, confirmPassword, token }))
      .unwrap()
      .then(() => {
        reset();
        setSuccessShown(true);
      });
  };

  const handleDialogOk = () => {
    setSuccessShown(false);
    navigate('/auth', { replace: true });
  };

  const styleCriteria = (criteria: string) => {
    const matchErrors = errors?.newPassword?.types?.matches;

    return matchErrors && (matchErrors as string[]).includes(criteria)
      ? 'error'
      : newPass !== ''
      ? 'blue'
      : '#a4a4a4';
  };

  return (
    <>
      {successShown && (
        <ChangePasswordSuccessDialog
          onOk={handleDialogOk}
          message='Password has been successfully changed.'
          title='Reset Password'
        />
      )}
      <Stack
        width='100vw'
        height='100vh'
        bgcolor='#FDFDFD'
        alignItems='center'
        justifyContent='center'
      >
        <Stack
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          width={400}
          borderRadius='10px'
          bgcolor='white'
          sx={{ boxShadow: '0px 0px 15px rgba(0,0,0,0.15)' }}
          // gap={3}
          p={4}
        >
          <Stack alignItems='flex-start'>
            <img
              alt='coinbase'
              width='auto'
              height={32}
              style={{ alignSelf: 'flex-start' }}
            />
          </Stack>
          <Stack gap={1}>
            <Typography fontWeight='bold' fontSize={22} mb={1}>
              Set Your New Password
            </Typography>
            <AppTextField
              label='Password'
              type={passwordShown ? 'text' : 'password'}
              placeholder='Enter your password'
              {...register('newPassword')}
              // error={!!errors.newPassword}
              // helperText={errors.newPassword?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <PasswordVisibilityButton
                      isPassword={passwordShown}
                      onToggle={() => setPasswordShown((v) => !v)}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <AppTextField
              label='Confirm Password'
              type={passwordConfirmShown ? 'text' : 'password'}
              placeholder='Confirm your password'
              {...register('confirmPassword')}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <PasswordVisibilityButton
                      isPassword={passwordConfirmShown}
                      onToggle={() => setPasswordConfirmShown((v) => !v)}
                    />
                  </InputAdornment>
                ),
              }}
            />

            {serverError && (
              <FormHelperText
                error
                sx={{ pt: 2, justifyContent: 'center', display: 'flex' }}
              >
                {serverError}
              </FormHelperText>
            )}
          </Stack>
          <Stack my={2} gap={0.5}>
            {passwordCriteria.map((criteria, idx) => (
              <Typography
                fontSize={12}
                key={idx}
                color={styleCriteria(criteria)}
              >
                {criteria}
              </Typography>
            ))}
          </Stack>
          <AuthActionButton
            disabled={
              errors.newPassword || errors.confirmPassword ? true : false
            }
            isForLogin={false}
            isLoading={status === 'loading' ? true : false}
            width={37}
            label='Set New Password'
          />
        </Stack>
      </Stack>
    </>
  );
};

export default ResetPasswordPage;
