import { createColumnHelper } from '@tanstack/react-table';
import ViewButton from '../../../components/common/ViewButton';

import type { Order } from '../../../store/order/types';
import { formatCurrency } from '../../../utils/helpers';
import OrderStatusSelect from './OrderStatusSelect';

const columnHelper = createColumnHelper<Order>();

const orderColumns = [
  columnHelper.accessor('partNumber', {
    id: 'partNumber',
    header: "Manufacturer's Part Number",
    cell: (row) => row.renderValue(),
  }),
  columnHelper.accessor('serialNumber', {
    id: 'serialNumber',
    header: 'Serial Number',
    cell: (row) => row.renderValue(),
  }),
  columnHelper.accessor('assetType', {
    id: 'assetType',
    header: 'Asset Type',
    cell: (row) => row.renderValue(),
  }),
  columnHelper.accessor('model', {
    id: 'model',
    header: 'Model',
    cell: (row) => row.renderValue(),
  }),
  columnHelper.accessor('inventoryLocation', {
    id: 'inventoryLocation',
    header: 'Inventory Location',
    cell: (row) => row.renderValue(),
  }),
  columnHelper.accessor('itSpec', {
    id: 'itSpec',
    header: 'IT Spec',
    cell: (row) => row.renderValue(),
  }),
  columnHelper.accessor('poNumber', {
    id: 'poNumber',
    header: 'PO Number',
    cell: (row) => row.renderValue(),
  }),
  columnHelper.accessor('trackingNumber', {
    id: 'trackingNumber',
    header: 'Tracking Number',
    cell: (row) => row.renderValue(),
  }),
  columnHelper.accessor('vendor', {
    id: 'vendor',
    header: 'Vendor',
    cell: (row) => row.renderValue(),
  }),
  columnHelper.accessor('purchasePrice', {
    id: 'purchasePrice',
    header: 'Purchase Price',
    cell: (row) => formatCurrency(row.getValue(), 'Php'),
  }),
  columnHelper.accessor('purchaseDate', {
    id: 'purchaseDate',
    header: 'Purchase Date',
    cell: (row) => row.renderValue(),
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: 'Status',
    cell: ({ row }) => <OrderStatusSelect value={row.original.status} orderId={row.original.id} />,
    enablePinning: true,
  }),
  columnHelper.display({
    id: 'actions',
    header: 'Action',
    cell: ({ row }) => (
      <ViewButton
        viewLink={`/requested-assets/po/${row.original.purchaseOrderId}/orders/${row.original.id}`}
      />
    ),
    enablePinning: true,
  }),
];

export default orderColumns;
