import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { useAppSelector } from '../../store';
import LoadingIndicator from './LoadingIndicator';

const AuthenticatedRoute = ({ children }: { children: ReactNode }) => {
  const isAuth = useAuth();

  const { status } = useAppSelector((state) => state.auth);

  if (status === 'loading') return <LoadingIndicator show />;

  if (!isAuth) {
    return <Navigate to='/auth' replace />;
  }

  return <>{children}</>;
};

export default AuthenticatedRoute;
