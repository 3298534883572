import { Button, ButtonProps } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { appColors } from '../../theme';

const FormAddButton = (props: ButtonProps) => {
  return (
    <Button
      startIcon={<AddIcon />}
      disableElevation
      {...props}
      sx={{
        textTransform: 'capitalize',
        bgcolor: 'text.primary',
        color: 'white',
        minWidth: 200,
        borderRadius: 2,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        position: 'absolute',
        bottom: 0,
        left: 16,
        height: 40,
        fontSize: 13,

        '&:hover': {
          bgcolor: 'text.primary',
        },

        '&:disabled': {
          backgroundColor: appColors.textGray,
          color: appColors.lightDark,
          cursor: 'not-allowed',
        },
      }}
    >
      {props.children}
    </Button>
  );
};

export default FormAddButton;
