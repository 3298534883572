import { flexRender } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../store';
import { selectRoleState } from '../../../store/role/slice';
import useDefaultTable from '../../../components/common/app-table/useDefaultTable';
import { Role } from '../../../store/role/types';

import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';

import roleColumns from './roleColumns';

import FormAddButton from '../../../components/common/FormAddButton';
import AppSearchField from '../../../components/common/forms/AppSearchField';

import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from '../../../components/common/app-table/ui';

const RoleTable = () => {
  const { data, status } = useAppSelector(selectRoleState);

  const { table, dataCount, searchFilter, setSearchFilter, pageIndex, pageSize } =
    useDefaultTable<Role>(data, roleColumns);

  const navigate = useNavigate();

  const loading = status === 'fetching';

  const emptyText = searchFilter ? 'No roles found' : 'No roles yet';

  const goToRoleForm = () => navigate('/users/roles/create');

  return (
    <>
      <Box position='absolute' top={-56} right={0}>
        <AppSearchField
          onSearchKeywordChange={setSearchFilter}
          searchKeyword={searchFilter ?? ''}
        />
      </Box>
      <AppTableContainer>
        <AppTable stickyHeader>
          <AppTableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <AppTableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <AppTableCell key={header.id} sx={{ px: '24px' }}>
                    <AppTableSort
                      canSort={header.column.getCanSort()}
                      currentSort={header.column.getIsSorted()}
                      onSort={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </AppTableSort>
                  </AppTableCell>
                ))}
              </AppTableRow>
            ))}
          </AppTableHead>
          <AppTableBody dataCount={dataCount} loading={loading} emptyText={emptyText}>
            {table.getRowModel().rows.map((row) => (
              <AppTableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <AppTableCell key={cell.id} sx={{ px: '24px' }}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </AppTableCell>
                ))}
              </AppTableRow>
            ))}
          </AppTableBody>
        </AppTable>
      </AppTableContainer>
      <Box position='relative'>
        <TablePagination
          sx={{ bgcolor: 'white', borderTop: '1px solid #eee' }}
          component='div'
          count={dataCount}
          page={pageIndex}
          onPageChange={(e, page) => table.setPageIndex(page)}
          rowsPerPage={pageSize}
          onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
        <FormAddButton onClick={goToRoleForm}>Add Role</FormAddButton>
      </Box>
    </>
  );
};

export default RoleTable;
