import MuiDrawer, { DrawerProps } from "@mui/material/Drawer";
import Stack, { StackProps } from "@mui/material/Stack";
import List from "@mui/material/List";

import { useLayout } from "./layoutContext";

export function DrawerHeader(props: StackProps) {
  return (
    <Stack bgcolor='white' height={64} alignItems='center' justifyContent='center' {...props}>
      {props.children}
    </Stack>
  );
}

interface AppDrawerProps extends DrawerProps {
  bgcolor: string;
}

const Drawer = ({ bgcolor, ...props }: AppDrawerProps) => {
  const { sidebarWidth, sidebarOpen, collapseToWidth } = useLayout();

  const width = sidebarOpen ? sidebarWidth : collapseToWidth ? collapseToWidth : sidebarWidth;

  return (
    <MuiDrawer
      variant='persistent'
      anchor='left'
      sx={{
        width: width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: width,
          boxSizing: "border-box",
          bgcolor,
          border: 0,
          overflow: "hidden",
        },
      }}
      open={collapseToWidth ? true : sidebarOpen}
      {...props}
    >
      <List component='nav' disablePadding sx={{ mx: "30px" }}>
        {props.children}
      </List>
    </MuiDrawer>
  );
};

export default Drawer;
