import { useState } from 'react';
import { flexRender } from '@tanstack/react-table';

import purchaseOrderColumns from './purchaseOrderColumnDef';
import { useAppSelector } from '../../../store';
import { selectPurchaseOrderState } from '../../../store/purchaseOrder/selectors';
import { Box, TablePagination, Stack } from '@mui/material';
import { PurchaseOrder } from '../../../store/purchaseOrder/types';
import useDefaultTable from '../../../components/common/app-table/useDefaultTable';
import CompletionStatusFilter from './CompletionStatusFilter';
import AppSearchField from '../../../components/common/forms/AppSearchField';
import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from '../../../components/common/app-table/ui';
import { useNavigate } from 'react-router-dom';
import FormAddButton from '../../../components/common/FormAddButton';
import { useRequestedAssetAccess } from '../../../hooks/usePermissions';

const PurchaseOrderTable = () => {
  const [currentCompStatusFilter, setCurrentCompStatusFilter] = useState<string>('All');

  const { data, status } = useAppSelector(selectPurchaseOrderState);

  const navigate = useNavigate();

  const acc = useRequestedAssetAccess();

  const [minStr, maxStr] = currentCompStatusFilter?.replace(/%/g, '')?.split(' - ') as string[];
  const [min, max] = [parseInt(minStr), parseInt(maxStr)];

  const filteredData =
    currentCompStatusFilter === 'All'
      ? data
      : currentCompStatusFilter === '0%'
      ? data.filter((d) => d.completionStatus === 0)
      : currentCompStatusFilter === '100%'
      ? data.filter((d) => d.completionStatus === 100)
      : data.filter((d) => d.completionStatus >= min && d.completionStatus <= max);

  const { table, dataCount, searchFilter, setSearchFilter, pageIndex, pageSize } =
    useDefaultTable<PurchaseOrder>(filteredData, purchaseOrderColumns);

  const loading = status === 'fetching';

  const emptyText = searchFilter ? 'No requested assets found' : 'No requested assets yet';

  const goToPurchaseOrderForm = () => navigate('/requested-assets/create');

  return (
    <>
      <Box position='absolute' top={-56} right={0}>
        <AppSearchField
          onSearchKeywordChange={setSearchFilter}
          searchKeyword={searchFilter ?? ''}
        />
      </Box>
      <AppTableContainer>
        <AppTable stickyHeader>
          <AppTableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <AppTableRow key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <AppTableCell key={header.id} align={index > 1 ? 'center' : 'left'}>
                    {header.column.getCanFilter() ? (
                      <Stack direction='row'>
                        <AppTableSort
                          canSort={header.column.getCanSort()}
                          currentSort={header.column.getIsSorted()}
                          onSort={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </AppTableSort>

                        <CompletionStatusFilter onClick={setCurrentCompStatusFilter} />
                      </Stack>
                    ) : (
                      <AppTableSort
                        canSort={header.column.getCanSort()}
                        currentSort={header.column.getIsSorted()}
                        onSort={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}{' '}
                      </AppTableSort>
                    )}
                  </AppTableCell>
                ))}
              </AppTableRow>
            ))}
          </AppTableHead>
          <AppTableBody dataCount={dataCount} loading={loading} emptyText={emptyText}>
            {table.getRowModel().rows.map((row) => (
              <AppTableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <AppTableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </AppTableCell>
                ))}
              </AppTableRow>
            ))}
          </AppTableBody>
        </AppTable>
      </AppTableContainer>
      <Box position='relative'>
        <TablePagination
          sx={{ bgcolor: 'white', borderTop: '1px solid #eee' }}
          component='div'
          count={dataCount}
          page={pageIndex}
          onPageChange={(e, page) => table.setPageIndex(page)}
          rowsPerPage={pageSize}
          onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
        {acc?.access.addPO && (
          <FormAddButton onClick={goToPurchaseOrderForm}>Add Purchase Order</FormAddButton>
        )}
      </Box>
    </>
  );
};

export default PurchaseOrderTable;
