import { createColumnHelper } from '@tanstack/react-table';
import type { Role } from '../../../store/role/types';

import RoleActions from './RoleActions';

const columnHelper = createColumnHelper<Role>();

const roleColumns = [
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Role',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('description', {
    id: 'description',
    header: 'Role Description',
    cell: (row) => row.getValue(),
    enableGlobalFilter: false,
  }),
  columnHelper.display({
    id: 'actions',
    header: 'Action',
    cell: ({ row }) => <RoleActions role={row.original} />,
  }),
];

export default roleColumns;
