import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';

import type { Order, OrderStatus } from '../../../store/order/types';
import { formatCurrency } from '../../../utils/helpers';

const ORDER_STATUS: OrderStatus[] = ['Incoming', 'On Hand', 'Arranged', 'Delivered', 'Failed'];

const columnHelper = createColumnHelper<Order>();

const previewOrderColumns = [
  columnHelper.accessor('partNumber', {
    id: 'partNumber',
    header: "Manufacturer's Part Number",
    cell: (row) => row.renderValue(),
  }),
  columnHelper.accessor('serialNumber', {
    id: 'serialNumber',
    header: 'Serial Number',
    cell: (row) => (
      <Typography
        component='span'
        fontSize='inherit'
        color={
          row.table
            .getRowModel()
            .rows.some(
              (r) =>
                r.original.serialNumber === row.getValue() && r.original.id !== row.row.original.id
            )
            ? 'error'
            : 'unset'
        }
      >
        {row.renderValue()}
      </Typography>
    ),
  }),
  columnHelper.accessor('assetType', {
    id: 'assetType',
    header: 'Asset Type',
    cell: (row) => row.renderValue(),
  }),
  columnHelper.accessor('model', {
    id: 'model',
    header: 'Model',
    cell: (row) => row.renderValue(),
  }),
  columnHelper.accessor('inventoryLocation', {
    id: 'inventoryLocation',
    header: 'Inventory Location',
    cell: (row) => row.renderValue(),
  }),
  columnHelper.accessor('itSpec', {
    id: 'itSpec',
    header: 'IT Spec',
    cell: (row) => row.renderValue(),
  }),
  columnHelper.accessor('poNumber', {
    id: 'poNumber',
    header: 'PO Number',
    cell: (row) => row.renderValue(),
  }),
  columnHelper.accessor('trackingNumber', {
    id: 'trackingNumber',
    header: 'Tracking Number',
    cell: (row) => row.renderValue(),
  }),
  columnHelper.accessor('vendor', {
    id: 'vendor',
    header: 'Vendor',
    cell: (row) => row.renderValue(),
  }),
  columnHelper.accessor('purchasePrice', {
    id: 'purchasePrice',
    header: 'Purchase Price',
    cell: (row) => formatCurrency(row.getValue(), 'Php'),
  }),
  columnHelper.accessor('purchaseDate', {
    id: 'purchaseDate',
    header: 'Purchase Date',
    cell: (row) => row.renderValue(),
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: 'Status',
    cell: (row) => (
      <Typography
        fontSize='inherit'
        component='span'
        color={ORDER_STATUS.includes(row.getValue()) ? 'text.primary' : 'error.main'}
      >
        {row.getValue()}
      </Typography>
    ),
  }),
];

export default previewOrderColumns;
