import { styled } from '@mui/system';
import { ChangeEventHandler, forwardRef } from 'react';

const height = 24;
const width = 42;
const sliderSize = height - 6;
const tx = width - height - 2;

const Switch = styled('label')(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  width,
  height,

  '& > .slider': {
    position: 'absolute',
    cursor: 'pointer',
    inset: 0,
    height,
    width: '100%',
    backgroundColor: '#ccc',
    webkitTransition: '.4s',
    transition: '.4s',
    borderRadius: height,

    '&::before': {
      position: 'absolute',
      content: '""',
      height: sliderSize,
      width: sliderSize,
      left: 4,
      bottom: 3,
      backgroundColor: '#fff',
      webkitTransition: '.3s',
      transition: '.3s',
      borderRadius: '50%',
    },
  },
}));

const SwitchInput = styled('input')(({ theme }) => ({
  opacity: 0,
  width: 0,
  height: 0,

  '&:checked + .slider': {
    backgroundColor: theme.palette.primary.main,
  },

  '&:focus + .slider': {
    // boxShadow: `0 0 1px ${theme.palette.primary.main}`,
  },

  '&:checked + .slider::before': {
    webkitTransform: `translateX(${tx}px)`,
    msTransform: `translateX(${tx}px)`,
    transform: `translateX(${tx}px)`,
  },
}));

interface AppSwitchProps {
  label?: string;
  checked: boolean;
  onChange: (checkedState: boolean) => void;
}

const AppSwitch = forwardRef<HTMLInputElement, AppSwitchProps>(
  ({ label, checked, onChange }, ref) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      onChange(e.currentTarget.checked);
    };

    return (
      <Switch>
        <SwitchInput
          ref={ref}
          aria-label={label}
          type='checkbox'
          onChange={handleChange}
          checked={checked}
        />
        <span className='slider'></span>
      </Switch>
    );
  }
);

export default AppSwitch;
