import { Middleware, MiddlewareAPI, isRejectedWithValue } from '@reduxjs/toolkit';
import { showErrorToast } from '../toast/slice';

export const errorHandler: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    const bypassTheseActions = ['auth/login/rejected', 'auth/set-password/rejected'];
    if (isRejectedWithValue(action)) {
      if (bypassTheseActions.includes(action.type)) return next(action);

      let error: any;
      if (action.payload) {
        error = action.payload.error === 'Invalid token' ? 'Session expired' : action.payload.error;
      } else {
        error = action.error.message;
      }
      dispatch(showErrorToast(error));
    }
    return next(action);
  };
