import { Outlet } from 'react-router-dom';

import PageContainer from '../../components/common/PageContainer';
import { useCachedCartData } from '../../store/asset/cartSlice';
import AssetTable from './AssetTable';

const AssetList = () => {
  useCachedCartData();

  return (
    <PageContainer title='Asset'>
      <AssetTable />
      <Outlet />
    </PageContainer>
  );
};

export default AssetList;
