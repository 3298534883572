import { useMemo, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  SortingState,
  PaginationState,
  ColumnDef,
} from "@tanstack/react-table";

export default function useDefaultTable<T>(
  data: T[],
  columns: ColumnDef<T, any>[]
) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [searchFilter, setSearchFilter] = useState("");

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: data ? data : [],
    columns,
    enableFilters: true,
    enableColumnFilters: true,

    state: {
      sorting,
      pagination,
      globalFilter: searchFilter,
    },
    onGlobalFilterChange: setSearchFilter,

    onSortingChange: setSorting,
    globalFilterFn: "auto",
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false,
  });

  return {
    table,
    dataCount: !searchFilter ? data.length : table.getRowModel().rows.length,
    searchFilter,
    setSearchFilter,
    pageIndex,
    pageSize,
  };
}
