import { IconButton, Tooltip } from '@mui/material';
import ImportIcon from '@mui/icons-material/FileUploadOutlined';

const ImportButton = ({ onChange }: { onChange: (file: File | null) => void }) => {
  return (
    <Tooltip title='Import'>
      <IconButton
        color='primary'
        aria-label='import'
        component='label'
        sx={{
          bgcolor: 'primary.main',
          color: 'white',
          borderRadius: 2,
          '&:hover': { bgcolor: 'primary.main' },
        }}
      >
        <input
          hidden
          type='file'
          accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          onChange={(e) => {
            // if (!e.currentTarget.files) return;
            onChange(e.currentTarget.files ? e.currentTarget.files[0] : null);
          }}
        />
        <ImportIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ImportButton;
