import { createColumnHelper } from '@tanstack/react-table';

import type { Product } from '../../store/product/types';
import { formatCurrency } from '../../utils/helpers';
import ProductDetail from '../Product/ProductDetail';
import ProductThumbnail from '../Product/ProductThumbnail';
import AddToOrderButton from './AddToOrderButton';

const columnHelper = createColumnHelper<Product>();

const cartColumns = [
  columnHelper.display({
    id: 'thumbnail',
    cell: ({ row }) => (
      <ProductThumbnail src={row.original.media[0]?.source as string} alt={row.original.name} />
    ),
  }),
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Product Details',
    cell: ({ row }) => <ProductDetail product={row.original} />,
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('price', {
    id: 'price',
    header: 'Price',
    cell: (row) => formatCurrency(row.getValue(), 'Php'),
    enableGlobalFilter: false,
  }),
  columnHelper.display({
    id: 'actions',
    header: 'Action',
    cell: ({ row }) => <AddToOrderButton productId={row.original.id} inCheckout />,
  }),
];

export default cartColumns;
