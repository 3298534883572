import { useRef } from 'react';
import useMediaPreview from '../../hooks/useMediaPreview';
import defaultThumnail from '../../assets/images/product-default-thumbnail.png';
import { Box, IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ProductPhotoProps {
  src: File | string;
  name: string;
  onDelete?: () => void;
  onReplace?: (file: File) => void;
}

const ProductPhoto = ({ src, name, onDelete, onReplace }: ProductPhotoProps) => {
  const { fileName, mediaPreview } = useMediaPreview({ initialSrc: src, initialFileName: name });
  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const handleOpenFileDialog = () => {
    if (!inputFileRef.current) return;

    inputFileRef.current.click();
  };

  const handleReplace = (file: File | null) => {
    if (!file) return;

    if (onReplace) onReplace(file);
  };

  return (
    <Box
      width={138}
      height={138}
      sx={{
        p: 1,
        position: 'relative',
        border: `1px solid #ccc`,
        borderRadius: '4px',
        cursor: 'pointer',

        '& > img': { objectFit: 'cover', objectPosition: 'center', borderRadius: '4px' },
      }}
    >
      <Tooltip title='Click to Delete' placement='top'>
        <IconButton
          onClick={onDelete}
          aria-label='delete photo'
          sx={{
            position: 'absolute',
            top: -10,
            right: -10,
            bgcolor: 'error.main',
            color: 'white',
            width: 20,
            height: 20,
            '&:hover': {
              bgcolor: 'error.light',
            },
          }}
        >
          <CloseIcon sx={{ fontSize: 14 }} />
        </IconButton>
      </Tooltip>
      <Tooltip title='Click to Replace' placement='top'>
        <img
          onClick={handleOpenFileDialog}
          src={mediaPreview ? mediaPreview : defaultThumnail}
          alt={fileName}
          width='100%'
          height='100%'
        />
      </Tooltip>
      <input
        ref={inputFileRef}
        type='file'
        accept='image/*'
        hidden
        onChange={(e) => handleReplace(e.currentTarget.files ? e.currentTarget.files[0] : null)}
      />
    </Box>
  );
};

export default ProductPhoto;
