import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { useAppSelector } from '../../../store';

const NoVisibleFieldsView = () => {
  const { status, user } = useAppSelector((state) => state.auth);

  if (status === 'loading' || !user) return <LoadingIndicator show />;

  return (
    <Stack p={3}>
      <Stack>
        <Alert severity='error'>
          <AlertTitle>Access Denied</AlertTitle>
          You do not have permission to view this page.
        </Alert>
      </Stack>
    </Stack>
  );
};

export default NoVisibleFieldsView;
