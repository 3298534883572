import { useParams } from 'react-router-dom';
import { useAppSelector } from '../store';
import { selectRoleById } from '../store/role/slice';

export default function useRoleById() {
  const { id } = useParams<{ id: string }>();
  const role = useAppSelector(selectRoleById(id as string));

  return role;
}
