import { Box, Stack, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import FailedIcon from '@mui/icons-material/Close';

import type { OrderHistoryItem } from '../../../store/order/types';
import { appColors } from '../../../theme';
import { formatDate, formatTime } from '../../../utils/helpers';

interface OrderJourneyProps {
  orderHistory: OrderHistoryItem[];
}

const orderStatusMap: Record<OrderHistoryItem['statusLabel'], string> = {
  Incoming: 'Incoming Order',
  'On Hand': 'Order On Hand',
  Arranged: 'Arranged Order',
  Delivered: 'Delivered Order',
  Failed: 'Failed Delivery',
};

const OrderJourney = ({ orderHistory }: OrderJourneyProps) => {
  const hLength = orderHistory.length;

  const journeyLength =
    hLength === 1 ? 'max-content' : hLength === 2 ? 25 * (hLength - 1) + '%' : 25 * hLength + '%';

  return (
    <Stack>
      <Typography component='h4' fontWeight='bold' mb={1}>
        Order Journey
      </Typography>
      <Box bgcolor={appColors.bluishWhite} width='100%' height={32} borderRadius='100px'>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          bgcolor='primary.main'
          height='100%'
          width={journeyLength}
          px='4px'
          borderRadius='100px'
        >
          {orderHistory.map((history, index) => (
            <Stack
              key={history.statusLabel}
              width={hLength === 1 ? 'max-content' : '110px'}
              alignItems={index === hLength - 1 ? 'flex-end' : 'flex-start'}
            >
              <Stack
                width={24}
                height={24}
                borderRadius='100px'
                alignItems='center'
                justifyContent='center'
                bgcolor={history.statusLabel === 'Failed' ? 'error.main' : 'white'}
              >
                {history.statusLabel === 'Failed' ? (
                  <FailedIcon sx={{ color: 'white', fontSize: 20 }} />
                ) : (
                  <CheckIcon sx={{ color: 'primary.main', fontSize: 20 }} />
                )}
              </Stack>
            </Stack>
          ))}
        </Box>
      </Box>

      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        width={journeyLength}
        mt={1}
      >
        {orderHistory.map((history, index) => (
          <Box key={history.statusId} width='110px' px={index === 0 ? 1 : 0}>
            <Typography fontWeight='bold' fontSize={12}>
              {orderStatusMap[history.statusLabel]}
            </Typography>
            <Typography fontSize={12} color='text.secondary'>
              {formatDate(history.dateUpdated)}
            </Typography>
            <Typography fontSize={12} color='text.secondary'>
              {formatTime(history.dateUpdated)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Stack>
  );
};

export default OrderJourney;
