import { Backdrop, CircularProgress } from '@mui/material';

const SectionLoadingIndicator = ({ open }: { open: boolean }) => {
  return (
    <Backdrop
      open={open}
      sx={{
        backgroundColor: 'rgba(255,255,255,0.1)',
        width: '100%',
        height: 300,
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: (theme) => theme.zIndex.appBar + 1,
      }}
    >
      <CircularProgress color='primary' />
    </Backdrop>
  );
};

export default SectionLoadingIndicator;
