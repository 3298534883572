import { Controller, Control, FieldPath } from 'react-hook-form';

import Typography from '@mui/material/Typography';
import AppSwitch from '../../../components/common/forms/AppSwitch';

import type { RoleFields } from './role-utils';

interface RoleAccessSwitchProps {
  label: string;
  control: Control<RoleFields>;
  name: FieldPath<RoleFields>;
}

const RoleAccessSwitch = ({ control, name, label }: RoleAccessSwitchProps) => {
  return (
    <>
      <Typography fontSize={14}>{label}</Typography>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <AppSwitch
            checked={Object.values(value).every((v) => v)}
            onChange={(checked) => {
              onChange({
                view: checked,
                add: checked,
                edit: checked,
                delete: checked,
              });
            }}
          />
        )}
      />
    </>
  );
};

export default RoleAccessSwitch;
