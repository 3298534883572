import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody, { TableBodyProps } from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Box, { BoxProps } from "@mui/material/Box";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { styled } from "@mui/system";
import ArrowUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";

import useTableContainerWidth from "./useTableContainerWidth";
import { ReactNode } from "react";

const shadow = "rgba(0, 0, 0, 0.04) 0px 3px 5px";
const cellTextColor = "#9D9D9D";

interface AppTableBodyProps extends TableBodyProps {
  loading?: boolean;
  emptyText?: string;
  dataCount: number;
}

export const AppTableContainer = styled(TableContainer)(({ theme }) => ({
  height: "calc(100% - 64px)",
  maxHeight: "calc(100% - 64px)",
  backgroundColor: "white",
  boxShadow: shadow,
}));

export const AppTableHead = styled(TableHead)(({ theme }) => ({}));

export const AppTableBody = (props: AppTableBodyProps) => {
  const tableWidth = useTableContainerWidth("app-table");
  const { loading, emptyText, dataCount, children } = props;

  return (
    <TableBody id='app-table' sx={{ position: "relative", overflow: "hidden" }}>
      {loading && (
        <TableRow>
          <AppTableCell className='loading'>
            <Backdrop
              open={loading}
              sx={{
                backgroundColor: "rgba(255,255,255,0.1)",
                width: tableWidth,
                height: 300,
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: (theme) => theme.zIndex.appBar + 1,
              }}
            >
              <CircularProgress color='primary' />
            </Backdrop>
          </AppTableCell>
        </TableRow>
      )}
      {dataCount === 0 && !loading && (
        <TableRow>
          <AppTableCell className='empty'>
            <Box
              sx={{
                py: "40px",
                bgcolor: "white",
                width: tableWidth,
                height: 300,
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
              }}
            >
              <Typography color='GrayText'>{emptyText}</Typography>
            </Box>
          </AppTableCell>
        </TableRow>
      )}
      {dataCount > 0 && !loading && children}
    </TableBody>
  );
};

export const AppTablePagination = styled(TablePagination)(({ theme }) => ({
  bgcolor: "white",
  borderTop: "1px solid #eee",
}));

export const AppTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    fontSize: "12px",
    backgroundColor: "#fff",
  },
  [`&.${tableCellClasses.alignCenter}`]: {
    "& > div": {
      justifyContent: "center",
    },
  },

  [`&.${tableCellClasses.head}`]: {
    whiteSpace: "nowrap",
    fontSize: 12,
    fontWeight: "normal",
    color: cellTextColor,

    "&.pinned": {
      position: "sticky",
      right: 0,
      zIndex: 2,
    },

    "&.order-status": {
      position: "sticky",
      right: 110,
      zIndex: 2,
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    whiteSpace: "nowrap",

    "&.empty": {
      backgroundColor: "white",
      padding: "40px 10px",
      textAlign: "center",
      border: "none",
      position: "relative",
    },

    "&.loading": {
      border: "none",
      position: "relative",
    },

    "&.pinned": {
      position: "sticky",
      right: 0,
      zIndex: 1,
      backgroundColor: "#fff",
    },

    "&.order-status": {
      position: "sticky",
      right: 110,
      zIndex: 1,
      backgroundColor: "#fff",
    },
  },
}));

interface AppTableSortProps {
  onSort: BoxProps["onClick"];
  canSort: boolean;
  currentSort?: any;
  children: ReactNode;
}

export const AppTableSort = ({
  onSort,
  canSort,
  currentSort,
  children,
}: AppTableSortProps) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      onClick={onSort}
      sx={{
        userSelect: "none",
        cursor: canSort ? "pointer" : "unset",
      }}
    >
      {children}
      {{
        asc: <ArrowUpIcon />,
        desc: <ArrowDownIcon />,
      }[currentSort as string] ?? null}
    </Box>
  );
};

export const AppTable = Table;

export const AppTableRow = TableRow;
