import * as yup from "yup";
import { PurchaseOrder } from "../../../store/purchaseOrder/types";

export const purchaseOrderSchema = yup.object({
  orderDate: yup
    .date()
    .typeError("Order Date is required")
    .required("Order Date is required"),
  poNumber: yup.string().required("Purchase Order is required").trim(),
});

// export const StockStatusOptions: SelectOptionType[] = [
//   {
//     id: 'available',
//     label: 'Available',
//     value: 'Available',
//   },
//   {
//     id: 'order-basis',
//     label: 'Order Basis',
//     value: 'Order Basis',
//   },
// ];

export type PurchaseOrderFields = Omit<
  PurchaseOrder,
  | "id"
  | "isActive"
  | "dateCreated"
  | "dateUpdated"
  | "completionStatus"
  | "userId"
>;

export const purchaseOrderInitialValues: PurchaseOrderFields = {
  orderDate: new Date(Date.now()),
  poNumber: "",
};
