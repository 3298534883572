import {
  createContext,
  ReactNode,
  useMemo,
  useCallback,
  useContext,
  useReducer,
  useEffect,
} from "react";
import { useLayout } from "../layout-ui/layoutContext";

import { SidebarContextValueType, SidebarMode, SidebarProps } from "./types";

const SidebarContext = createContext<SidebarContextValueType | null>(null);

SidebarContext.displayName = "Sidebar";

interface SidebarContextProviderProps {
  children: ReactNode;
  contextProps: SidebarProps;
}

interface SidebarAction {
  type: SidebarMode;
  payload: number;
}

const sidebarReducer = (activeItems: number[], action: SidebarAction) => {
  switch (action.type) {
    case "single":
      const collapsing = activeItems.includes(action.payload);
      return collapsing ? [] : [action.payload];
    case "reset":
      return [];
    default:
      return activeItems;
  }
};

const SidebarContextProvider = (props: SidebarContextProviderProps) => {
  const { contextProps, children } = props;

  const [activeItems, sidebarDispatch] = useReducer(sidebarReducer, []);
  const { sidebarOpen, setSidebarOpen } = useLayout();

  useEffect(() => {
    if (!sidebarOpen) {
      sidebarDispatch({ type: "reset", payload: -1 });
    }
  }, [sidebarOpen]);

  const onMenuClick = useCallback(
    (menuIndex: number) => {
      if (menuIndex === undefined) return;
      sidebarDispatch({ type: "single", payload: menuIndex });

      if (contextProps.menuItems[menuIndex].subItems && !sidebarOpen) {
        setSidebarOpen(true);
      }
    },
    [contextProps.menuItems, setSidebarOpen, sidebarOpen]
  );

  const contextValue = useMemo(
    () => ({ ...contextProps, activeItems, onMenuClick }),
    [activeItems, onMenuClick, contextProps]
  );

  return <SidebarContext.Provider value={contextValue}>{children}</SidebarContext.Provider>;
};

export function useSidebar() {
  const context = useContext(SidebarContext);
  if (!context) throw new Error("useSidebar must only be used within the Sidebar Context.");
  return context;
}

export default SidebarContextProvider;
