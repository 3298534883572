import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../store';
import { getOrdersByPO } from '../store/order/slice';

export default function useGetOrdersByPO() {
  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!purchaseOrderId) return;

    dispatch(getOrdersByPO(purchaseOrderId));
  }, [dispatch, purchaseOrderId]);
}
