import { Button, CircularProgress } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';

interface Ownprops {
  isLoading: boolean;
  label: string;
  isForLogin: boolean;
  width: number;
  disabled?: boolean;
}

const AuthActionButton = (props: Ownprops) => {
  const { isLoading, label, isForLogin, width, disabled } = props;
  return (
    <Button
      disabled={disabled}
      disableElevation
      variant='contained'
      startIcon={isLoading ? null : isForLogin && <LoginIcon />}
      type='submit'
      sx={{
        textTransform: 'capitalize',
        borderRadius: 2,
        minWidth: width,
        my: 2,

        bgcolor: `${isLoading ? '#DAE6FF' : 'primary'}`,
        '&:hover': {
          bgcolor: `${isLoading ? '#DAE6FF' : 'primary'}`,
        },
        '&:disabled': {
          bgcolor: '#DAE6FF',
        },
      }}
    >
      {isLoading ? <CircularProgress color='primary' size={24} /> : `${label}`}
    </Button>
  );
};

export default AuthActionButton;
