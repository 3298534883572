import { yupResolver } from '@hookform/resolvers/yup';
import {
  useForm,
  SubmitErrorHandler,
  SubmitHandler,
  Controller,
} from 'react-hook-form';

import { Button, Stack, Typography } from '@mui/material';
import { appStyles } from '../../../theme';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { useEffect } from 'react';
import AppTextField from '../../../components/common/forms/AppTextField';
import PageContainer from '../../../components/common/PageContainer';
import { selectPurchaseOrderState } from '../../../store/purchaseOrder/selectors';
import {
  PurchaseOrderFields,
  purchaseOrderInitialValues,
  purchaseOrderSchema,
} from './purchaseOrder-utils';
import {
  createPurchaseOrder,
  updatePurchaseOrder,
} from '../../../store/purchaseOrder/slice';
import { useAppDispatch, useAppSelector } from '../../../store';

import useCurrentPO from '../../../hooks/useCurrentPO';
import AppDatePicker from '../../../components/common/forms/AppDatePicker';
import { useCallbackPrompt } from '../../../hooks/useCallbackPrompt';
import ConfirmPromptDialog from '../../../components/common/ConfirmPromptDialog';

const PurchaseOrderForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { status } = useAppSelector(selectPurchaseOrderState);
  const currentPurchaseOrder = useCurrentPO();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty, errors },
  } = useForm<PurchaseOrderFields>({
    defaultValues: purchaseOrderInitialValues,
    resolver: yupResolver(purchaseOrderSchema),
    mode: 'onChange',
  });

  const [showPrompt, confirmNavigation, cancelNavigation, blockMessage] =
    useCallbackPrompt(isDirty);

  useEffect(() => {
    if (!currentPurchaseOrder) return;

    Object.keys(currentPurchaseOrder).forEach((key) => {
      const _key = key as keyof PurchaseOrderFields;
      setValue(_key, currentPurchaseOrder[_key]);
    });
  }, [currentPurchaseOrder, setValue]);

  const onError: SubmitErrorHandler<PurchaseOrderFields> = (err) => {
    console.error(err);
  };

  // usePrompt('Are you sure you want to go back without saving', isDirty);
  const onSubmit: SubmitHandler<PurchaseOrderFields> = (purchaseOrder) => {
    reset({}, { keepDirty: false });

    if (currentPurchaseOrder) {
      return dispatch(
        updatePurchaseOrder({ id: currentPurchaseOrder.id, purchaseOrder })
      )
        .unwrap()
        .then(() => navigate('/requested-assets'));
    }

    dispatch(createPurchaseOrder(purchaseOrder))
      .unwrap()
      .then(() => navigate('/requested-assets'));
  };

  const title = currentPurchaseOrder
    ? currentPurchaseOrder.poNumber
    : 'Add Purchase Order';

  if (status === 'fetching') return <LoadingIndicator show />;

  return (
    <>
      <ConfirmPromptDialog
        showDialog={showPrompt as boolean}
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        blockMessage={blockMessage}
      />
      <PageContainer
        title={title}
        backTo='/requested-assets'
        controls={
          <Button
            onClick={handleSubmit(onSubmit, onError)}
            disableElevation
            variant='contained'
            sx={{ textTransform: 'capitalize', borderRadius: 1.5 }}
            disabled={status === 'loading'}
          >
            {status === 'loading' ? 'Saving...' : 'Save'}
          </Button>
        }
      >
        <Stack
          component='form'
          height='100%'
          bgcolor='white'
          py={3}
          boxShadow={appStyles.shadow}
          borderRadius={1}
        >
          <Stack px={3} height='100%' overflow='auto' gap={1}>
            <Typography component='h4' fontWeight='bold' fontSize={20} mb={1}>
              Purchase Order Information
            </Typography>
            <Controller
              control={control}
              name='orderDate'
              render={({ field: { value, onChange } }) => (
                <AppDatePicker
                  name='orderDate'
                  value={value}
                  onChange={onChange}
                  required
                  label='Order Date'
                  error={!!errors.orderDate}
                  helperText={errors.orderDate?.message}
                />
              )}
            />
            <AppTextField
              required
              label='Purchase Order'
              placeholder='Purchase Order'
              {...register('poNumber')}
              error={!!errors.poNumber}
              helperText={errors.poNumber?.message}
            />
          </Stack>
        </Stack>
      </PageContainer>
    </>
  );
};

export default PurchaseOrderForm;
