import { useEffect } from 'react';
import { useAppDispatch } from '../store';
import { getPurchaseOrders } from '../store/purchaseOrder/slice';

export default function useGetPurchaseOrders() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPurchaseOrders());
  }, [dispatch]);
}
