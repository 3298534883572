import { Stack, Tooltip } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DuplicateIcon from '@mui/icons-material/ContentCopyRounded';
import AppSwitch from '../../components/common/forms/AppSwitch';

import { useAppDispatch } from '../../store';
import { duplicateProduct, updateProduct } from '../../store/product/slice';
import { Product } from '../../store/product/types';
import { useNavigate } from 'react-router-dom';
import ActionButton from '../../components/common/ActionButton';

interface ProductActionsProps {
  product: Product;
}

const ProductActions = ({ product }: ProductActionsProps) => {
  const { id, isDisplayedOnAssets } = product;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleDuplicateProduct = () => {
    dispatch(duplicateProduct(id));
  };

  const goToProductForm = () => navigate(`/products/edit/${id}`);

  const openDeleteDialog = () => navigate(`/products/delete/${id}`);

  const handleUpdateDisplayOnAssets = (checkedState: boolean) => {
    const productData = { ...product, isDisplayedOnAssets: checkedState } as Partial<Product>;
    dispatch(
      updateProduct({
        id,
        product: productData,
        prevDisplayInAssetsVal: isDisplayedOnAssets,
        forDisplayInAsset: true,
      })
    );
  };

  return (
    <Stack direction='row' alignItems='center' gap={1.5}>
      <Tooltip title='Edit' placement='top'>
        <ActionButton aria-label='edit' onClick={goToProductForm}>
          <EditIcon />
        </ActionButton>
      </Tooltip>
      <Tooltip title='Delete' placement='top' onClick={openDeleteDialog}>
        <ActionButton aria-label='delete'>
          <DeleteIcon />
        </ActionButton>
      </Tooltip>
      <Tooltip title='Duplicate' placement='top'>
        <ActionButton aria-label='duplicate' onClick={handleDuplicateProduct}>
          <DuplicateIcon />
        </ActionButton>
      </Tooltip>
      <Tooltip title='Display in Assets' placement='top'>
        <Stack>
          <AppSwitch
            aria-label='display in assets'
            checked={isDisplayedOnAssets}
            onChange={handleUpdateDisplayOnAssets}
          />
        </Stack>
      </Tooltip>
    </Stack>
  );
};

export default ProductActions;
