import { Routes, Route } from 'react-router-dom';
import NoAccessView from '../../../components/common/NoAccessView';
import { useIsSuperAdmin } from '../../../hooks/usePermissions';
import RoleDeleteDialog from './RoleDeleteDialog';
import RoleForm from './RoleForm';
import RoleList from './RoleList';

const RolesPage = () => {
  const isSuperAdmin = useIsSuperAdmin();

  if (!isSuperAdmin) return <NoAccessView />;

  return (
    <>
      <Routes>
        <Route path='/' element={<RoleList />}>
          <Route path='delete/:id' element={<RoleDeleteDialog />} />
        </Route>
        <Route path='/create' element={<RoleForm />} />
        <Route path='/edit/:id' element={<RoleForm />} />
      </Routes>
    </>
  );
};

export default RolesPage;
