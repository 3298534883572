import { flexRender } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';

import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from '../../../components/common/app-table/ui';
import { UserAccount } from '../../../store/accounts/types';
import accountsColumns from './accountsColumns';
import AppSearchField from '../../../components/common/forms/AppSearchField';
import FormAddButton from '../../../components/common/FormAddButton';
import { selectUserAccountsState } from '../../../store/accounts/selectors';
import { useAppSelector } from '../../../store';
import useDefaultTable from '../../../components/common/app-table/useDefaultTable';
import { Stack } from '@mui/material';
import AccountActiveFilter from './AccountActiveFilter';
import { useState } from 'react';

const AccountsTable = () => {
  const { data, status } = useAppSelector(selectUserAccountsState);
  const [activeFilter, setActiveFilter] = useState<string>('All');

  const filteredData =
    activeFilter === 'All'
      ? data
      : activeFilter === 'Active'
      ? data.filter((d) => d.isActive === true)
      : data.filter((d) => d.isActive === false);

  const { table, dataCount, searchFilter, setSearchFilter, pageIndex, pageSize } =
    useDefaultTable<UserAccount>(filteredData, accountsColumns);

  const navigate = useNavigate();

  const loading = status === 'fetching';

  const emptyText = searchFilter ? 'No user found' : 'No user yet';

  const goToAccountsForm = () => navigate('/users/accounts/create-user');

  return (
    <>
      <Box position='absolute' top={-56} right={0}>
        <AppSearchField
          onSearchKeywordChange={setSearchFilter}
          searchKeyword={searchFilter ?? ''}
        />
      </Box>
      <AppTableContainer>
        <AppTable stickyHeader>
          <AppTableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <AppTableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <AppTableCell key={header.id}>
                    {header.column.getCanFilter() ? (
                      <Stack direction='row'>
                        <AppTableSort
                          canSort={header.column.getCanSort()}
                          currentSort={header.column.getIsSorted()}
                          onSort={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </AppTableSort>

                        <AccountActiveFilter onClick={setActiveFilter} />
                      </Stack>
                    ) : (
                      <AppTableSort
                        canSort={header.column.getCanSort()}
                        currentSort={header.column.getIsSorted()}
                        onSort={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}{' '}
                      </AppTableSort>
                    )}
                  </AppTableCell>
                ))}
              </AppTableRow>
            ))}
          </AppTableHead>
          <AppTableBody dataCount={dataCount} loading={loading} emptyText={emptyText}>
            {table.getRowModel().rows.map((row) => (
              <AppTableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <AppTableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </AppTableCell>
                ))}
              </AppTableRow>
            ))}
          </AppTableBody>
        </AppTable>
      </AppTableContainer>
      <Box position='relative'>
        <TablePagination
          sx={{ bgcolor: 'white', borderTop: '1px solid #eee' }}
          component='div'
          count={dataCount}
          page={pageIndex}
          onPageChange={(e, page) => table.setPageIndex(page)}
          rowsPerPage={pageSize}
          onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
        <FormAddButton onClick={goToAccountsForm}>Add User</FormAddButton>
      </Box>
    </>
  );
};

export default AccountsTable;
