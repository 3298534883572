import { Snackbar, Alert } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store';
import { hideToast, selectToast } from '../../store/toast/slice';

import SuccessIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';

const AppToast = () => {
  const dispatch = useAppDispatch();
  const toast = useAppSelector(selectToast);
  const { visible, type, message } = toast;

  const hideAlert = () => dispatch(hideToast());

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={3000}
      open={visible}
      onClose={hideAlert}
    >
      <Alert
        variant='filled'
        severity={type}
        sx={{ minWidth: 180 }}
        iconMapping={{
          success: <SuccessIcon />,
          error: <ErrorIcon />,
          info: <InfoIcon />,
          warning: <WarningIcon />,
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AppToast;
