import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import type { ApiError, RootState } from '../index';
import type { Role, RoleState } from './types';
import roleApi from '../../services/role';
import { showSuccessToast } from '../toast/slice';

const initialState: RoleState = {
  status: 'idle',
  error: undefined,
  data: [],
};

export const getRoles = createAsyncThunk<
  Role[],
  void,
  {
    rejectValue: ApiError;
    state: RootState;
  }
>('roles/getAll', async (_, thunkAPI) => {
  try {
    const response = await roleApi.getRoles();

    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createRole = createAsyncThunk<
  Role,
  Partial<Role>,
  {
    rejectValue: ApiError;
    state: RootState;
  }
>('roles/create', async (role, thunkAPI) => {
  try {
    const response = await roleApi.createRole(role);
    // show success toast
    thunkAPI.dispatch(showSuccessToast('Role has been successfully added'));

    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateRole = createAsyncThunk<
  Role,
  { id: string; role: Partial<Role> },
  {
    rejectValue: ApiError;
    state: RootState;
  }
>('roles/update', async ({ id, role }, thunkAPI) => {
  try {
    const response = await roleApi.updateRole(id, role);
    // show success toast
    thunkAPI.dispatch(showSuccessToast('Role has been successfully updated'));

    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deleteRole = createAsyncThunk<
  void,
  string,
  {
    rejectValue: ApiError;
    state: RootState;
  }
>('roles/delete', async (id, thunkAPI) => {
  try {
    const response = await roleApi.deleteRole(id);

    // show success toast
    thunkAPI.dispatch(showSuccessToast('Role has been successfully deleted'));

    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// slice
export const roleSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    clearRoleState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getRoles.pending, (state) => {
      state.status = 'fetching';
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(createRole.fulfilled, (state, action) => {
      state.data.unshift(action.payload);
    });
    builder.addCase(updateRole.fulfilled, (state, action) => {
      const { id } = action.meta.arg;
      const updatedRoleIndex = state.data.findIndex((item) => item.id === id);

      state.data[updatedRoleIndex] = action.payload;
    });
    builder.addCase(deleteRole.fulfilled, (state, action) => {
      state.data = state.data.filter((item) => item.id !== action.meta.arg); // arg is `id`
    });
    builder.addMatcher(
      isAnyOf(createRole.pending, updateRole.pending, deleteRole.pending),
      (state) => {
        state.status = 'loading';
      }
    );
    builder.addMatcher(
      isAnyOf(getRoles.fulfilled, createRole.fulfilled, updateRole.fulfilled, deleteRole.fulfilled),
      (state) => {
        state.status = 'idle';
        state.error = undefined;
      }
    );
    builder.addMatcher(
      isAnyOf(getRoles.rejected, createRole.rejected, updateRole.rejected, deleteRole.rejected),
      (state, action) => {
        state.status = 'idle';

        if (action.payload) {
          state.error = action.payload.error;
        } else {
          state.error = action.error.message;
        }
      }
    );
  },
});

const roleReducer = roleSlice.reducer;

// actions
export const { clearRoleState } = roleSlice.actions;

// selectors
export const selectRoleState = (state: RootState) => state.roles;

export const selectRoleById = (id: string) => (state: RootState) =>
  state.roles.data.find((p) => p.id === id);

export default roleReducer;
