import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ReactNode } from 'react';

export const appColors = {
  primary: '#0052FF',
  bluishWhite: '#F0F5FF',
  darkerBluishWhite: '#DDE8FF',
  offWhite: '#F8F9FA',
  dark: '#222529',
  mediumDark: '#707070',
  lightDark: '#D0D0D0',
  lightestDark: '#00000029',
  textGray: '#9D9D9D',
  error: '#E84040',
  invalid: '#fecaca',
};

export const appStyles = {
  shadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px',
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#0052FF',
    },
    secondary: {
      main: '#9a9a9a',
    },
    error: {
      main: '#E84040',
    },
    text: {
      primary: '#222529',
      secondary: '#9a9a9a',
    },
  },
  typography: {
    fontFamily: ['"Helvetica Neue"', 'Roboto', 'sans-serif'].join(','),
  },
});

export default function AppThemeProvider({ children }: { children: ReactNode }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
