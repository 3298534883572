import { CSSProperties } from 'react';
import Stack from '@mui/material/Stack';
import Sidebar from '../../components/sidebar/index';

import { appColors } from '../../theme';
import Drawer from '../../components/layout-ui/Drawer';

import CoinbaseLogo from '../../assets/images/coinbase-logo.svg';
import { useVisibleSideMenu } from '../../hooks/usePermissions';

const { primary, dark } = appColors;

const activeStyles: CSSProperties = {
  backgroundColor: primary,
  color: 'white',
};

const subItemActiveStyles: CSSProperties = {
  backgroundColor: dark,
  color: 'white',
};

const AppSidebarHeader = () => {
  return (
    <Stack flexDirection='row' alignItems='center' justifyContent='center' bgcolor='primary' py={3}>
      <Stack>
        <img src={CoinbaseLogo} alt='coinbase' height={48} width={170} />
      </Stack>
    </Stack>
  );
};

const AppSidebar = () => {
  const visibleMenu = useVisibleSideMenu();

  return (
    <Drawer bgcolor={dark}>
      <AppSidebarHeader />
      <Sidebar
        activeStyles={activeStyles}
        subItemActiveStyles={subItemActiveStyles}
        menuItems={visibleMenu}
      />
    </Drawer>
  );
};

export default AppSidebar;
