import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store';
import { selectOrderById } from '../store/order/selectors';
import { getOrdersByPO } from '../store/order/slice';

export default function useGetOrderById() {
  const { purchaseOrderId, orderId } = useParams<{ purchaseOrderId: string; orderId: string }>();
  const order = useAppSelector(selectOrderById(orderId as string));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!purchaseOrderId) return;

    dispatch(getOrdersByPO(purchaseOrderId));
  }, [dispatch, purchaseOrderId]);

  return order;
}
