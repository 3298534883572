import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppDialog from "../../../components/common/AppDialog";
import useCurrentPO from "../../../hooks/useCurrentPO";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectPurchaseOrderState } from "../../../store/purchaseOrder/selectors";
import { deletePurchaseOrder } from "../../../store/purchaseOrder/slice";

const PurchaseOrderDeleteDialog = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectPurchaseOrderState);
  const currentPurchaseOrder = useCurrentPO();

  const handleClose = () => navigate("/requested-assets", { replace: true });

  const handleDelete = () => {
    if (!currentPurchaseOrder) return null;

    dispatch(deletePurchaseOrder(currentPurchaseOrder.id))
      .unwrap()
      .then(() => navigate("/requested-assets", { replace: true }));
  };

  const loading = status === "loading";
  return (
    <AppDialog open onDialogClose={handleClose} title='Delete Purchase Order'>
      <Typography textAlign='center'>
        Would you like to delete this purchase order??
      </Typography>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='center'
        mt={4}
        mb={2}
        gap={2}
      >
        <Button
          variant='contained'
          color='error'
          disableElevation
          sx={{ textTransform: "capitalize", pointerEvents: "initial" }}
          onClick={handleDelete}
        >
          {loading ? "Deleting..." : "Yes"}
        </Button>
        <Button
          color='secondary'
          variant='outlined'
          disableElevation
          sx={{ textTransform: "capitalize" }}
          onClick={handleClose}
        >
          No
        </Button>
      </Stack>
    </AppDialog>
  );
};

export default PurchaseOrderDeleteDialog;
