import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AppDialog from '../../components/common/AppDialog';
import useCurrentProduct from '../../hooks/useCurrentProduct';
import { useAppDispatch, useAppSelector } from '../../store';
import { deleteProduct, selectProductState } from '../../store/product/slice';

const ProductDeleteDialog = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectProductState);
  const currentProduct = useCurrentProduct();

  if (!currentProduct) return null;

  const handleClose = () => navigate('/products', { replace: true });

  const handleDelete = () => {
    if (!currentProduct) return null;

    dispatch(deleteProduct(currentProduct.id))
      .unwrap()
      .then(() => navigate('/products', { replace: true }));
  };

  const loading = status === 'loading';

  return (
    <AppDialog open onDialogClose={handleClose} title={`Delete: ${currentProduct?.name}`}>
      <Typography textAlign='center'>Would you like to delete this product?</Typography>
      <Stack direction='row' alignItems='center' justifyContent='center' mt={4} mb={2} gap={2}>
        <Button
          variant='contained'
          color='error'
          disableElevation
          sx={{ textTransform: 'capitalize', pointerEvents: loading ? 'none' : 'initial' }}
          onClick={handleDelete}
        >
          {loading ? 'Deleting...' : 'Yes'}
        </Button>
        <Button
          color='secondary'
          variant='outlined'
          disableElevation
          sx={{ textTransform: 'capitalize' }}
          onClick={handleClose}
        >
          No
        </Button>
      </Stack>
    </AppDialog>
  );
};

export default ProductDeleteDialog;
