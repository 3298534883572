import ExpandableSidebarItem from "./ExpandableSidebarItem";
import { useSidebar } from "./sidebarContext";
import SidebarItem from "./SidebarItem";

const SidebarMenuItems = () => {
  const { menuItems } = useSidebar();

  return (
    <>
      {menuItems.map((item, index) =>
        item.subItems ? (
          <ExpandableSidebarItem index={index} key={item.label} sidebarItem={item} />
        ) : (
          <SidebarItem index={index} key={item.label} sidebarItem={item} />
        )
      )}
    </>
  );
};

export default SidebarMenuItems;
