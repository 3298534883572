import { Outlet } from 'react-router-dom';
import PageContainer from '../../../components/common/PageContainer';
import useGetPurchaseOrders from '../../../hooks/useGetPurchaseOrder';
import PurchaseOrderTable from './PurchaseOrderTable';

const PurchaseOrderList = () => {
  useGetPurchaseOrders();

  return (
    <PageContainer title='Requested Asset'>
      <PurchaseOrderTable />
      <Outlet />
    </PageContainer>
  );
};

export default PurchaseOrderList;
