import { IconButton } from '@mui/material';
import { styled } from '@mui/system';

const ActionButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  borderRadius: 6,
  width: 32,
  height: 32,

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },

  '& > svg': {
    fontSize: 18,
  },

  '&:disabled': {
    backgroundColor: '#eee',
    cursor: 'not-allowed',
  },
}));

export default ActionButton;
