import PageContainer from '../../../components/common/PageContainer';

import { Stack } from '@mui/material';
import ExportButton from '../../../components/common/ExportButton';
import ImportButton from '../../../components/common/ImportButton';
import OrderTable from './OrderTable';
import { useAppDispatch, useAppSelector } from '../../../store';
import { exportToExcel, formatDate, parseOrderExcel } from '../../../utils/helpers';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import OrderImportPreviewDialog from './OrderImportPreviewDialog';
import { Order } from '../../../store/order/types';
import { selectOrderState } from '../../../store/order/selectors';
import { getOrdersByPO, importOrders } from '../../../store/order/slice';
import {
  selectPurchaseOrderById,
  selectPurchaseOrderState,
} from '../../../store/purchaseOrder/selectors';
import useGetOrdersByPO from '../../../hooks/useGetOrdersByPO';
import NotFoundView from '../../../components/common/NotFoundView';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { useRequestedAssetAccess } from '../../../hooks/usePermissions';
import NoVisibleFieldsView from './NoVisibleFieldsView';

const OrderList = () => {
  useGetOrdersByPO();

  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>();

  const { status, data: orders } = useAppSelector(selectOrderState);
  const { status: poState } = useAppSelector(selectPurchaseOrderState);

  const currentPurchaseOrder = useAppSelector(selectPurchaseOrderById(purchaseOrderId as string));

  const dispatch = useAppDispatch();

  const acc = useRequestedAssetAccess();

  const [ordersToImport, setOrdersToImport] = useState<Order[]>([]);
  const [ordersFile, setOrdersFile] = useState<File | null>(null);
  const [previewDialogShown, setPreviewDialogShown] = useState(false);

  const openPreviewDialog = () => setPreviewDialogShown(true);
  const closePreviewDialog = () => setPreviewDialogShown(false);

  const handleExport = () => {
    exportToExcel(orders, 'OPSPH_Coinbase updated Inventory');
  };

  const handleImport = async (file: File | null) => {
    if (!file) return;

    const parsedOrders = await parseOrderExcel(file);
    setOrdersToImport(parsedOrders);

    openPreviewDialog();

    setOrdersFile(file);
  };

  const handleImportSave = () => {
    if (!ordersFile || !purchaseOrderId) return;

    const formData = new FormData();
    formData.append('purchaseOrderId', purchaseOrderId);
    formData.append('orders', ordersFile);

    dispatch(importOrders(formData))
      .unwrap()
      .then(() => {
        closePreviewDialog();
        setOrdersFile(null);
        setOrdersToImport([]);
        dispatch(getOrdersByPO(purchaseOrderId as string));
      });
  };

  if (poState === 'fetching') return <LoadingIndicator show />;

  if (!currentPurchaseOrder && status === 'idle')
    return (
      <NotFoundView
        name='Purchase Order'
        backTo='/requested-assets'
        buttonText='Back to Requested Asset Page'
      />
    );

  if (acc?.displayFields.length === 0 && currentPurchaseOrder) return <NoVisibleFieldsView />;

  const title = currentPurchaseOrder
    ? `${formatDate(currentPurchaseOrder.orderDate)} Order`
    : 'Order';

  return (
    <PageContainer
      title={title}
      backTo='/requested-assets'
      controls={
        <Stack direction='row' gap={1} px={2}>
          {acc?.access.exportOrders && <ExportButton onClick={handleExport} />}
          {acc?.access.importOrders && (
            <ImportButton key={previewDialogShown ? 'render' : 'non'} onChange={handleImport} />
          )}
        </Stack>
      }
    >
      <OrderTable />

      <OrderImportPreviewDialog
        key={ordersToImport.length}
        open={previewDialogShown}
        ordersToImport={ordersToImport}
        onClose={closePreviewDialog}
        onSave={handleImportSave}
        isLoading={status === 'loading'}
      />
    </PageContainer>
  );
};

export default OrderList;
