import { Box, TablePagination } from '@mui/material';
import { flexRender } from '@tanstack/react-table';
import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from '../../components/common/app-table/ui';
import useDefaultTable from '../../components/common/app-table/useDefaultTable';
import AppSearchField from '../../components/common/forms/AppSearchField';
import { useAppSelector } from '../../store';
import { selectProductsForAsset, selectProductState } from '../../store/product/slice';
import { Product } from '../../store/product/types';
import CartButton from './CartButton';
import assetColumns from './assetColumns';
import { useNavigate } from 'react-router-dom';
import StockStatusFilter from './StockStatusFilter';
import { useState } from 'react';
import { useSaveCartToCache } from '../../store/asset/cartSlice';

const AssetTable = () => {
  useSaveCartToCache();

  const navigate = useNavigate();
  const { status } = useAppSelector(selectProductState);
  const [stockFilter, setStockFilter] = useState<'All' | Product['stockStatus']>('All');
  const products = useAppSelector(selectProductsForAsset(stockFilter));

  const { table, dataCount, searchFilter, setSearchFilter, pageIndex, pageSize } =
    useDefaultTable<Product>(products, assetColumns);

  const loading = status === 'fetching';

  const emptyText = searchFilter ? 'No products found' : 'No product to display';

  const showDetail = (id: string) => {
    navigate(`/assets/${id}`);
  };

  return (
    <>
      <Box position='absolute' top={-56} right={0}>
        <StockStatusFilter value={stockFilter} onChange={setStockFilter} />
        <AppSearchField
          onSearchKeywordChange={setSearchFilter}
          searchKeyword={searchFilter ?? ''}
        />
      </Box>
      <AppTableContainer>
        <AppTable stickyHeader>
          <AppTableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <AppTableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <AppTableCell key={header.id}>
                    <AppTableSort
                      canSort={header.column.getCanSort()}
                      currentSort={header.column.getIsSorted()}
                      onSort={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </AppTableSort>
                  </AppTableCell>
                ))}
              </AppTableRow>
            ))}
          </AppTableHead>
          <AppTableBody dataCount={dataCount} loading={loading} emptyText={emptyText}>
            {table.getRowModel().rows.map((row) => (
              <AppTableRow key={row.id} sx={{ cursor: 'pointer' }}>
                {row.getVisibleCells().map((cell) => (
                  <AppTableCell
                    key={cell.id}
                    onClick={() => {
                      if (cell.id.includes('actions')) return;
                      showDetail(row.original.id);
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </AppTableCell>
                ))}
              </AppTableRow>
            ))}
          </AppTableBody>
        </AppTable>
      </AppTableContainer>
      <Box position='relative'>
        <TablePagination
          sx={{ bgcolor: 'white', borderTop: '1px solid #eee' }}
          component='div'
          count={dataCount}
          page={pageIndex}
          onPageChange={(e, page) => table.setPageIndex(page)}
          rowsPerPage={pageSize}
          onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
      </Box>
      <CartButton />
    </>
  );
};

export default AssetTable;
