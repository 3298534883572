import React from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';

import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import LogoutIcon from '@mui/icons-material/PowerSettingsNew';
import { useAppDispatch, useAppSelector } from '../../store';
import { getAuth } from '../../store/auth/selector';
import { logout } from '../../store/auth/slice';

const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const authUser = useAppSelector(getAuth);
  const dispatch = useAppDispatch();

  if (!authUser) return null;

  const { firstName, lastName } = authUser;
  const userName = `${firstName} ${lastName}`;

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSignOut = () => {
    dispatch(logout());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Tooltip title='Account'>
        <Button
          sx={{
            fontWeight: '500',
            textTransform: 'capitalize',
            color: 'text.primary',
            '&:hover': {
              bgcolor: '#eee',
            },
          }}
          variant='text'
          onClick={handleClick}
          endIcon={
            open ? (
              <ExpandLess sx={{ color: 'text.primary' }} />
            ) : (
              <ExpandMore sx={{ color: 'text.primary' }} />
            )
          }
        >
          {userName}
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        elevation={1}
        PaperProps={{
          sx: { width: 250, bgcolor: 'white', paddingX: 2, paddingY: 1 },
        }}
      >
        <Stack alignItems='center'>
          <Button
            color='primary'
            fullWidth
            variant='text'
            startIcon={<LogoutIcon />}
            sx={{
              textTransform: 'capitalize',
              color: 'text.primary',
              justifyContent: 'start',
              px: 2,
              fontWeight: '500',
              '&:hover': {
                bgcolor: '#eee',
              },
            }}
            onClick={handleSignOut}
          >
            Log Out
          </Button>
        </Stack>
      </Menu>
    </React.Fragment>
  );
};

export default AccountMenu;
