import * as XLSX from 'xlsx';
import { format, parseISO } from 'date-fns';
import type { Order, OrderHistoryItem } from '../store/order/types';
import { orderBy } from 'lodash';

const orderPropertyMap = {
  ID: 'id',
  "Manufacturer's Part Number": 'partNumber',
  'Serial Number': 'serialNumber',
  'Asset Type': 'assetType',
  Model: 'model',
  'Inventory Location': 'inventoryLocation',
  'IT Spec': 'itSpec',
  'PO Number': 'poNumber',
  'Tracking Number': 'trackingNumber',
  Vendor: 'vendor',
  'Purchase Price': 'purchasePrice',
  'Purchase Date': 'purchaseDate',
  Status: 'status',
};

export const parseOrderExcel = async (orderExcelFile: File) => {
  const fileData = await orderExcelFile.arrayBuffer();
  const workbook = XLSX.read(fileData, {
    type: 'buffer',
    cellText: false,
    cellDates: true,
  });
  const sheetName = workbook.SheetNames[0];
  const workSheet = workbook.Sheets[sheetName];

  const orderJsonData = XLSX.utils.sheet_to_json(workSheet, {
    raw: false,
    dateNF: 'mm/dd/yyyy',
  });

  const orders = orderJsonData.map((order: any) => {
    const mappedOrder = Object.keys(orderPropertyMap).reduce(
      (mapped, currKey) => {
        const newKey =
          orderPropertyMap[currKey as keyof typeof orderPropertyMap];
        if (!newKey) return mapped;
        return {
          ...mapped,
          [newKey]:
            currKey === 'Purchase Price'
              ? order['Purchase Price']
                ? order['Purchase Price'].replace(/\Php |,/g, '')
                : ''
              : order[currKey]
              ? order[currKey]
              : '',
        };
      },
      {}
    );
    return mappedOrder;
  });

  return orders as Order[];
};

export const exportToExcel = (orders: Order[], filename: string) => {
  const ordersData = [...orders].map(
    ({
      isActive,
      dateCreated,
      dateUpdated,
      purchaseOrderId,
      userId,
      history,
      ...rest
    }) => {
      return {
        ...rest,
        purchasePrice: formatCurrency(rest.purchasePrice, 'Php'),
      };
    }
  );

  const headings = [Object.keys(orderPropertyMap)];
  const columnHeaders = [...Object.values(orderPropertyMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);
  XLSX.utils.sheet_add_json(worksheet, ordersData, {
    origin: 'A2',
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = format(Date.now(), 'MM_dd_yyyy');
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Hardware Machine');

  const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
    wch: i === 0 ? 50 : 20,
  }));

  worksheet['!cols'] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};

export const formatCurrency = (price: number | string, currency: string = '') =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'PHP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(typeof price === 'string' ? Number(price) : price)
    .replace('₱', currency + ' ');

export const formatDate = (date: string | Date) => {
  return format(parseISO(date.toString()), 'MM/dd/yyy');
};

export const formatHistoryDate = (date: string | Date) => {
  return format(parseISO(date.toString()), 'MM/dd/yyyy hh:mm aa');
};

export const formatTime = (date: string | Date) => {
  return format(parseISO(date.toString()), 'hh:mm aa');
};

export const sortOderHistory = (orderHistory: OrderHistoryItem[]) =>
  orderBy(orderHistory, ['statusId'], ['desc']);
