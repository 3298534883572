import { Outlet } from 'react-router-dom';
import PageContainer from '../../../components/common/PageContainer';
import RoleTable from './RoleTable';

const RoleList = () => {
  return (
    <PageContainer title='Role & Accessibility'>
      <RoleTable />
      <Outlet />
    </PageContainer>
  );
};

export default RoleList;
