import { Routes, Route, Navigate } from 'react-router-dom';
import NoAccessView from '../../components/common/NoAccessView';
import useGetRoles from '../../hooks/useGetRoles';
import AccountDeleteDialog from './Accounts/AccountDeleteDialog';
import AccountsForm from './Accounts/AccountsForm';
import { useIsSuperAdmin } from '../../hooks/usePermissions';
import AccountsPage from './Accounts/AccountsPage';
import RolesPage from './Roles/RolesPage';

const UsersPage = () => {
  const hasPermission = useGetRoles();

  const isSuperAdmin = useIsSuperAdmin();

  if (!hasPermission) return <NoAccessView />;

  return (
    <Routes>
      <Route path='roles/*' element={<RolesPage />} />
      <Route path='accounts' element={<AccountsPage />}>
        <Route path='delete/:id' element={<AccountDeleteDialog />} />
      </Route>
      <Route path='accounts/create-user' element={<AccountsForm />} />
      <Route path='accounts/edit/:id' element={<AccountsForm />} />

      <Route path='/' element={<Navigate to={isSuperAdmin ? 'roles' : 'accounts'} />} />
    </Routes>
  );
};

export default UsersPage;
