import menu from '../modules/Layout/appMenuItems';
import { useAppSelector } from '../store';
import type { Role } from '../store/role/types';

type Module = 'Asset' | 'Requested Asset' | 'Product Management' | 'User Management';

const ModuleMap: Record<Module, keyof Role['permissions']> = {
  Asset: 'asset',
  'Requested Asset': 'requestedAsset',
  'Product Management': 'product',
  'User Management': 'user',
};

// this is used to check if the user has permission to view a page/module
// they can attempt to access the module via URL
export function usePermission(moduleName: Module) {
  const authUser = useAppSelector((state) => state.auth.user);

  if (!authUser) return false; // no access

  const permissionKey = ModuleMap[moduleName];
  const { permissions } = authUser.userRole;

  return permissionKey === 'requestedAsset'
    ? permissions[permissionKey].access.viewPO
    : permissions[permissionKey].access.view;
}

// this is used to hide/show a module item in the sidebar
export function useVisibleSideMenu() {
  const authUser = useAppSelector((state) => state.auth.user);

  if (!authUser) return []; // no access

  const isSuperAdmin = authUser.roleId === 'ROLE::1';

  const visibleMenu = menu
    .filter((m) => {
      const permissionKey = ModuleMap[m.label as Module];

      if (permissionKey === 'requestedAsset')
        return authUser.userRole.permissions[permissionKey].access.viewPO;

      return authUser.userRole.permissions[permissionKey].access.view;
    })
    .map((i) =>
      i.label === 'User Management' && i.subItems
        ? !isSuperAdmin
          ? { ...i, subItems: [i.subItems[1]] }
          : i
        : i
    );

  return visibleMenu;
}

// check if the user is super admin
export function useIsSuperAdmin() {
  const authUser = useAppSelector((state) => state.auth.user);

  if (!authUser) return false; // no access

  return authUser.roleId === 'ROLE::1';
}

// manage access of Requested Asset Features
export function useRequestedAssetAccess() {
  const authUser = useAppSelector((state) => state.auth.user);

  if (!authUser) return undefined;

  return authUser.userRole.permissions.requestedAsset;
}

export function useModuleToShowFirst() {
  const authUser = useAppSelector((state) => state.auth.user);

  if (!authUser) return '/assets';

  // get modules where `view` access is true
  const permissions = authUser.userRole.permissions;
  type PKey = keyof typeof permissions;
  const modulesWithAccess = Object.keys(permissions).filter((accessKey) => {
    const _key = accessKey as PKey;
    return _key === 'requestedAsset'
      ? permissions.requestedAsset.access.viewPO
      : permissions[_key].access.view;
  });

  return modulesWithAccess.length === 0
    ? '/assets'
    : modulesWithAccess[0] === 'requestedAsset'
    ? '/requested-assets'
    : `/${modulesWithAccess[0]}s`;
}
