import * as yup from 'yup';
import type { RequestedAssetAccess, RequestedAssetFieldKey, Role } from '../../../store/role/types';

export const RequestedAssetAccessOptions: Array<{
  label: string;
  accessKey: keyof Omit<RequestedAssetAccess, 'viewPO' | 'viewOrders'>;
}> = [
  { accessKey: 'editPO', label: 'Edit Purchase Order' },
  { accessKey: 'deletePO', label: 'Delete Purchase Order' },
  { accessKey: 'addPO', label: 'Add Purchase Order' },
  { accessKey: 'exportOrders', label: 'Export File' },
  { accessKey: 'importOrders', label: 'Import File' },
  { accessKey: 'updateOrders', label: 'Update Status' },
];

export const RequestedAssetDisplayFieldsOptions: Array<{
  label: string;
  fieldKey: RequestedAssetFieldKey;
}> = [
  { label: "Manufacturer's Part Number", fieldKey: 'partNumber' },
  { label: 'Serial Number', fieldKey: 'serialNumber' },
  { label: 'Asset Type', fieldKey: 'assetType' },
  { label: 'Model', fieldKey: 'model' },
  { label: 'Inventory Location', fieldKey: 'inventoryLocation' },
  { label: 'IT Spec', fieldKey: 'itSpec' },
  { label: 'PO Number', fieldKey: 'poNumber' },
  { label: 'Tracking Number', fieldKey: 'trackingNumber' },
  { label: 'Vendor', fieldKey: 'vendor' },
  { label: 'Purchase Price', fieldKey: 'purchasePrice' },
  { label: 'Purchase Date', fieldKey: 'purchaseDate' },
  { label: 'Status', fieldKey: 'status' },
];

export const roleSchema = yup.object({
  name: yup.string().required('Role name is required').trim(),
  description: yup.string().required('Role description is required').trim(),
});

export type RoleFields = Omit<
  Role,
  'id' | 'isActive' | 'createdBy' | 'updatedBy' | 'dateCreated' | 'dateUpdated'
>;

export const roleInitialValues: RoleFields = {
  name: '',
  description: '',
  permissions: {
    asset: {
      access: {
        view: false,
        add: false,
        edit: false,
        delete: false,
      },
    },
    product: {
      access: {
        view: false,
        add: false,
        edit: false,
        delete: false,
      },
    },
    user: {
      access: {
        view: false,
        add: false,
        edit: false,
        delete: false,
      },
    },
    requestedAsset: {
      access: {
        viewPO: false,
        addPO: false,
        editPO: false,
        deletePO: false,
        viewOrders: false,
        importOrders: false,
        exportOrders: false,
        updateOrders: false,
      },
      displayFields: [],
    },
  },
};
