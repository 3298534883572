import * as yup from 'yup';
import type { Product } from '../../store/product/types';
import type { SelectOptionType } from '../../components/common/forms/form-types';

export const productSchema = yup.object({
  name: yup.string().required('Product Name/Model is required').trim(),
  partNumber: yup.string().required('Part Number is required').trim(),
  price: yup
    .number()
    .required('Price is required')
    .positive('Invalid price value')
    .typeError('Invalid price value'),
  media: yup.array().max(10, 'You can only upload a maximum of 10 photos'),
});

export const StockStatusOptions: SelectOptionType[] = [
  {
    id: 'available',
    label: 'Available',
    value: 'Available',
  },
  {
    id: 'order-basis',
    label: 'Order Basis',
    value: 'Order Basis',
  },
];

export type ProductFields = Omit<
  Product,
  | 'id'
  | 'isActive'
  | 'isDisplayedOnAssets'
  | 'createdBy'
  | 'updatedBy'
  | 'dateCreated'
  | 'dateUpdated'
>;

export const productInitialValues: ProductFields = {
  name: '',
  partNumber: '',
  price: 0,
  assetType: '',
  itSpec: '',
  brand: '',
  stockStatus: 'Available',
  leadTime: '',
  color: '',
  variant: '',
  description: '',
  media: [],
};
