import { useEffect } from 'react';
import { useAppDispatch } from '../store';
import { getProducts } from '../store/product/slice';
import { usePermission } from './usePermissions';

export default function useProductsForAssets() {
  const dispatch = useAppDispatch();

  const hasPermission = usePermission('Asset');

  useEffect(() => {
    if (!hasPermission) return;

    dispatch(getProducts());
  }, [dispatch, hasPermission]);

  return hasPermission;
}
