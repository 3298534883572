import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import ChevronIcon from '@mui/icons-material/ExpandMoreOutlined';
import { OrderStatus } from '../../../store/order/types';
import { useAppDispatch } from '../../../store';
import { updateOrderStatus } from '../../../store/order/slice';
import { useRequestedAssetAccess } from '../../../hooks/usePermissions';

const menuStyles: SxProps = { fontSize: 14 };

const ORDER_STATUS: OrderStatus[] = ['Incoming', 'On Hand', 'Arranged', 'Delivered', 'Failed'];

interface OrderStatusSelectProps {
  value: OrderStatus;
  orderId: string;
}

const OrderStatusSelect = ({ value, orderId }: OrderStatusSelectProps) => {
  const dispatch = useAppDispatch();

  const acc = useRequestedAssetAccess();

  const statusIndex = ORDER_STATUS.indexOf(value) + 1;

  const handleChange = (e: SelectChangeEvent<number>) => {
    const status = ORDER_STATUS[+e.target.value - 1];
    const previousStatus = value;
    dispatch(updateOrderStatus({ id: orderId, status, previousStatus }));
  };

  if (!acc?.access.updateOrders) return <>{value}</>;

  return (
    <Select
      IconComponent={ChevronIcon}
      size='small'
      labelId='order-status-label'
      id='order-status-select'
      sx={{ fontSize: 13, height: 31, minWidth: 110 }}
      value={statusIndex}
      onChange={handleChange}
      renderValue={(selected) => {
        return (
          <Typography fontSize='inherit' color={value === 'Failed' ? 'error.main' : ''}>
            {ORDER_STATUS[selected - 1]}
          </Typography>
        );
      }}
    >
      {ORDER_STATUS.map((item, index) => (
        <MenuItem
          key={item}
          value={index + 1}
          sx={menuStyles}
          disabled={(statusIndex < 3 || statusIndex === 4) && item === 'Failed'}
        >
          {item}
        </MenuItem>
      ))}
    </Select>
  );
};

export default OrderStatusSelect;
