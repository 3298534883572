import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import userAccountsReducer from './accounts/slice';
import { cartReducer } from './asset/cartSlice';

import authReducer from './auth/slice';
import { errorHandler } from './middleware/errorHandler';
import ordersReducer from './order/slice';
import productReducer from './product/slice';
import purchaseOrdersReducer from './purchaseOrder/slice';
import roleReducer from './role/slice';
import toastReducer from './toast/slice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    toast: toastReducer,
    orders: ordersReducer,
    purchaseOrders: purchaseOrdersReducer,
    products: productReducer,
    roles: roleReducer,
    userAccounts: userAccountsReducer,
    cart: cartReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(errorHandler),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppStatus = 'idle' | 'loading' | 'submitting' | 'fetching' | 'success' | 'error';

// temporary
export interface ApiError {
  status: 'failed';
  error: string;
}

export type AppError = Error | string | undefined;

export interface FeatureState<TData> {
  status: AppStatus;
  error: AppError;
  data: TData[];
}

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
