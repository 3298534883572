import { Stack, Typography } from '@mui/material';
import { createColumnHelper, flexRender } from '@tanstack/react-table';
import useDefaultTable from '../../../components/common/app-table/useDefaultTable';

import {
  AppTableContainer,
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableHead,
  AppTableRow,
} from '../../../components/common/app-table/ui';

import type { Order } from '../../../store/order/types';
import { formatCurrency } from '../../../utils/helpers';

const columnHelper = createColumnHelper<Order>();

const orderColumns = [
  columnHelper.accessor('serialNumber', {
    id: 'serialNumber',
    header: 'Serial Number',
    cell: (row) => row.getValue(),
  }),
  columnHelper.accessor('assetType', {
    id: 'assetType',
    header: 'Asset Type',
    cell: (row) => row.getValue(),
  }),
  columnHelper.accessor('model', {
    id: 'model',
    header: 'Model',
    cell: (row) => row.getValue(),
  }),
  columnHelper.accessor('inventoryLocation', {
    id: 'inventoryLocation',
    header: 'Inventory Location',
    cell: (row) => row.getValue(),
  }),
  columnHelper.accessor('itSpec', {
    id: 'itSpec',
    header: 'IT Spec',
    cell: (row) => row.getValue(),
  }),
  columnHelper.accessor('poNumber', {
    id: 'poNumber',
    header: 'PO Number',
    cell: (row) => row.getValue(),
  }),
  columnHelper.accessor('trackingNumber', {
    id: 'trackingNumber',
    header: 'Tracking Number',
    cell: (row) => row.getValue(),
  }),
  columnHelper.accessor('vendor', {
    id: 'vendor',
    header: 'Vendor',
    cell: (row) => row.getValue(),
  }),
  columnHelper.accessor('purchasePrice', {
    id: 'purchasePrice',
    header: 'Purchase Price',
    cell: (row) => formatCurrency(row.getValue(), 'Php'),
  }),
  columnHelper.accessor('purchaseDate', {
    id: 'purchaseDate',
    header: 'Purchase Date',
    cell: (row) => row.getValue(),
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: 'Status',
    cell: (row) => row.getValue(),
  }),
];

const OrderItem = ({ order }: { order: Order }) => {
  const { table, dataCount } = useDefaultTable<Order>([order], orderColumns);
  return (
    <Stack>
      <Typography component='h4' fontWeight='bold' mb={1}>
        Item
      </Typography>
      <AppTableContainer sx={{ height: 'auto', maxHeight: 'unset', pb: 3 }}>
        <AppTable>
          <AppTableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <AppTableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <AppTableCell sx={{ py: 1.2 }} key={header.id}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </AppTableCell>
                ))}
              </AppTableRow>
            ))}
          </AppTableHead>
          <AppTableBody dataCount={dataCount} emptyText='No updates yet'>
            {table.getRowModel().rows.map((row) => (
              <AppTableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <AppTableCell sx={{ py: 1.4 }} key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </AppTableCell>
                ))}
              </AppTableRow>
            ))}
          </AppTableBody>
        </AppTable>
      </AppTableContainer>
    </Stack>
  );
};

export default OrderItem;
