import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import { useAppDispatch, useAppSelector } from '../../../store';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Navigate, useNavigate } from 'react-router-dom';
import {
  createRole,
  selectRoleState,
  updateRole,
} from '../../../store/role/slice';
import type {
  RequestedAssetFieldKey,
  RequestedAssetAccess,
} from '../../../store/role/types';

import LoadingIndicator from '../../../components/common/LoadingIndicator';
import PageContainer from '../../../components/common/PageContainer';
import AppTextField from '../../../components/common/forms/AppTextField';
import AppSwitch from '../../../components/common/forms/AppSwitch';
import { appColors, appStyles } from '../../../theme';
import {
  RequestedAssetAccessOptions,
  RequestedAssetDisplayFieldsOptions,
  RoleFields,
  roleInitialValues,
  roleSchema,
} from './role-utils';
import RoleAccessSwitch from './RoleAccessSwitch';
import useRoleById from '../../../hooks/useRoleById';
import ConfirmPromptDialog from '../../../components/common/ConfirmPromptDialog';
import { useCallbackPrompt } from '../../../hooks/useCallbackPrompt';

const GridBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '250px auto',
  alignItems: 'center',
  borderBottom: `1px solid #ccc`,
  padding: '16px 8px',
});

const RoleForm = () => {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectRoleState);
  const navigate = useNavigate();
  const currentRole = useRoleById();
  const [requestedAssetExpanded, setRequestedAssetExpanded] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<RoleFields>({
    defaultValues: roleInitialValues,
    mode: 'onChange',
    resolver: yupResolver(roleSchema),
  });

  const [showPrompt, confirmNavigation, cancelNavigation, blockMessage] =
    useCallbackPrompt(isDirty);

  useEffect(() => {
    if (!currentRole) return;

    setValue('name', currentRole.name);
    setValue('description', currentRole.description);
    setValue('permissions.asset.access', currentRole.permissions.asset.access);
    setValue(
      'permissions.product.access',
      currentRole.permissions.product.access
    );
    setValue('permissions.user.access', currentRole.permissions.user.access);

    setValue(
      'permissions.requestedAsset.displayFields',
      currentRole.permissions.requestedAsset.displayFields
    );

    Object.keys(currentRole.permissions.requestedAsset.access).forEach(
      (key) => {
        const _key = key as keyof RequestedAssetAccess;
        setValue(
          `permissions.requestedAsset.access.${_key}`,
          currentRole.permissions.requestedAsset.access[_key]
        );
      }
    );
  }, [currentRole, setValue]);

  const toggleRequestedAssetExpanded = () =>
    setRequestedAssetExpanded((old) => !old);

  const currentDisplayFields = watch(
    'permissions.requestedAsset.displayFields'
  );

  const handleDisplayFieldsChange = (
    included: boolean,
    field: RequestedAssetFieldKey
  ) => {
    if (included && !currentDisplayFields.includes(field)) {
      setValue('permissions.requestedAsset.displayFields', [
        ...currentDisplayFields,
        field,
      ]);
    } else {
      setValue(
        'permissions.requestedAsset.displayFields',
        currentDisplayFields.filter((f) => f !== field)
      );
    }
  };

  const onError = (err: typeof errors) => {
    console.log(err);
  };

  const onSubmit = (role: RoleFields) => {
    reset(
      {
        name: '',
        description: '',
        permissions: {
          asset: {
            access: {
              view: false,
              add: false,
              edit: false,
              delete: false,
            },
          },
          product: {
            access: {
              view: false,
              add: false,
              edit: false,
              delete: false,
            },
          },
          user: {
            access: {
              view: false,
              add: false,
              edit: false,
              delete: false,
            },
          },
          requestedAsset: {
            access: {
              viewPO: false,
              addPO: false,
              editPO: false,
              deletePO: false,
              viewOrders: false,
              importOrders: false,
              exportOrders: false,
              updateOrders: false,
            },
            displayFields: [],
          },
        },
      },
      { keepDirty: false }
    );

    if (currentRole) {
      return dispatch(updateRole({ id: currentRole.id, role }))
        .unwrap()
        .then(() => navigate('/users/roles'));
    }

    dispatch(createRole(role))
      .unwrap()
      .then(() => navigate('/users/roles'));
  };

  const title = currentRole ? currentRole.name : 'Add Role';

  if (status === 'fetching') return <LoadingIndicator show />;

  if (currentRole?.id === 'ROLE::1')
    return <Navigate to='/users/roles' replace />;

  return (
    <>
      <ConfirmPromptDialog
        showDialog={showPrompt as boolean}
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        blockMessage={blockMessage}
      />
      <PageContainer
        title={title}
        backTo='/users/roles'
        controls={
          <Button
            onClick={handleSubmit(onSubmit, onError)}
            disableElevation
            variant='contained'
            sx={{ textTransform: 'capitalize', borderRadius: 1.5 }}
            disabled={status === 'loading'}
          >
            {status === 'loading' ? 'Saving...' : 'Save'}
          </Button>
        }
      >
        <Stack
          component='form'
          height='100%'
          bgcolor='white'
          py={3}
          boxShadow={appStyles.shadow}
          borderRadius={1}
        >
          <Stack px={3} height='100%' overflow='auto'>
            <Typography component='h4' fontWeight='bold' fontSize={20} mb={1}>
              Role Details
            </Typography>
            <AppTextField
              required
              label='Role'
              placeholder='Role'
              {...register('name')}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
            <AppTextField
              required
              label='Role Description'
              placeholder='Role description'
              multiline
              rows={4}
              {...register('description')}
              error={!!errors.description}
              helperText={errors.description?.message}
            />
            <Typography component='h4' fontWeight='bold' fontSize={20} my={1}>
              Permission
            </Typography>
            <GridBox color={appColors.textGray}>
              <Typography fontSize={13}>Module</Typography>
              <Typography fontSize={13}>Accessibility</Typography>
            </GridBox>
            <GridBox>
              <RoleAccessSwitch
                label='Asset'
                control={control}
                name='permissions.asset.access'
              />
            </GridBox>
            <GridBox>
              <Typography fontSize={14} sx={{ cursor: 'pointer' }}>
                Requested Asset
              </Typography>
              <Stack
                direction='row'
                sx={{ cursor: 'pointer' }}
                role='button'
                onClick={toggleRequestedAssetExpanded}
              >
                <Controller
                  control={control}
                  name='permissions.requestedAsset.access.viewPO'
                  render={({ field: { value, onChange } }) => (
                    <AppSwitch checked={value} onChange={onChange} />
                  )}
                />
                {requestedAssetExpanded ? (
                  <ExpandLess sx={{ ml: 'auto' }} />
                ) : (
                  <ExpandMore sx={{ ml: 'auto' }} />
                )}
              </Stack>
              <Stack />
              <Collapse in={requestedAssetExpanded}>
                <Stack my={2}>
                  {RequestedAssetAccessOptions.map(({ label, accessKey }) => (
                    <Controller
                      key={accessKey}
                      control={control}
                      name={`permissions.requestedAsset.access.${accessKey}`}
                      render={({ field: { name, onChange, value } }) => (
                        <FormControlLabel
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            '& .MuiFormControlLabel-label': {
                              fontSize: 14,
                              mt: 0.2,
                            },
                          }}
                          control={
                            <Checkbox
                              name={name}
                              onChange={onChange}
                              checked={value}
                            />
                          }
                          label={label}
                        />
                      )}
                    />
                  ))}
                </Stack>
                <Stack my={2}>
                  <Typography fontSize={14} fontWeight='bold' mb={2}>
                    Display Fields
                  </Typography>
                  {RequestedAssetDisplayFieldsOptions.map(
                    ({ label, fieldKey }) => (
                      <FormControlLabel
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          '& .MuiFormControlLabel-label': {
                            fontSize: 14,
                            mt: 0.2,
                          },
                        }}
                        key={fieldKey}
                        control={
                          <Checkbox
                            name={fieldKey}
                            checked={currentDisplayFields.includes(fieldKey)}
                            onChange={(e, checked) =>
                              handleDisplayFieldsChange(checked, fieldKey)
                            }
                          />
                        }
                        label={label}
                      />
                    )
                  )}
                </Stack>
              </Collapse>
            </GridBox>
            <GridBox>
              <RoleAccessSwitch
                label='Product Management'
                control={control}
                name='permissions.product.access'
              />
            </GridBox>
            <GridBox>
              <RoleAccessSwitch
                label='User Management'
                control={control}
                name='permissions.user.access'
              />
            </GridBox>
          </Stack>
        </Stack>
      </PageContainer>
    </>
  );
};

export default RoleForm;
