import { yupResolver } from '@hookform/resolvers/yup';
import {
  useForm,
  SubmitErrorHandler,
  SubmitHandler,
  Controller,
  useFieldArray,
} from 'react-hook-form';

import { Button, Stack, Typography } from '@mui/material';
import AppSelect from '../../components/common/forms/AppSelect';
import AppTextField from '../../components/common/forms/AppTextField';
import PageContainer from '../../components/common/PageContainer';

import { appStyles } from '../../theme';
import {
  productInitialValues,
  productSchema,
  StockStatusOptions,
  ProductFields,
} from './product-utils';
import AppMediaUploadButton from '../../components/common/forms/AppMediaUploadButton';
import ProductPhoto from './ProductPhoto';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  createProduct,
  selectProductState,
  updateProduct,
} from '../../store/product/slice';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { useEffect } from 'react';
import useCurrentProduct from '../../hooks/useCurrentProduct';
import { showErrorToast } from '../../store/toast/slice';
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt';
import ConfirmPromptDialog from '../../components/common/ConfirmPromptDialog';

const ProductForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { status } = useAppSelector(selectProductState);
  const currentProduct = useCurrentProduct();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm<ProductFields>({
    defaultValues: productInitialValues,
    resolver: yupResolver(productSchema),
    mode: 'onChange',
  });

  const [showPrompt, confirmNavigation, cancelNavigation, blockMessage] =
    useCallbackPrompt(isDirty);

  const productPhotos = watch('media');

  const { append, remove, update } = useFieldArray({ control, name: 'media' });

  useEffect(() => {
    if (!currentProduct) return;

    Object.keys(currentProduct).forEach((key) => {
      const _key = key as keyof ProductFields;
      setValue(_key, currentProduct[_key]);
    });
  }, [currentProduct, setValue]);

  const onError: SubmitErrorHandler<ProductFields> = (err) => {
    console.error(err);
  };

  const onSubmit: SubmitHandler<ProductFields> = (product) => {
    reset(
      {
        name: '',
        partNumber: '',
        price: 0,
        assetType: '',
        itSpec: '',
        brand: '',
        stockStatus: 'Available',
        leadTime: '',
        color: '',
        variant: '',
        description: '',
        media: [],
      },
      { keepDirty: false }
    );
    if (currentProduct) {
      return dispatch(updateProduct({ id: currentProduct.id, product }))
        .unwrap()
        .then(() => {
          navigate('/products');
        });
    }

    dispatch(createProduct(product))
      .unwrap()
      .then(() => {
        navigate('/products');
      });
  };

  const handleMediaUpload = (files: FileList | null) => {
    if (!files) return;

    // check if the resulting number of photos is more than 10
    const resultingCount = productPhotos.length + files.length;
    if (resultingCount > 10) {
      dispatch(showErrorToast('You can only upload a maximum of 10 photos'));
      return;
    }

    // check for possible duplicate photos (lookup value is filename)
    const productPhotosFilenames = productPhotos.map((item) => item.fileName);

    for (let i = 0; i < files.length; i++) {
      if (productPhotosFilenames.includes(files[i].name)) {
        dispatch(showErrorToast(`${files[i].name} already exists`));
        return;
      }
    }

    Array.from(files).forEach((file) => {
      append({
        fileName: file.name,
        source: file,
      });
    });
  };

  const title = currentProduct ? currentProduct.name : 'Add Product';

  if (status === 'fetching') return <LoadingIndicator show />;

  return (
    <>
      <ConfirmPromptDialog
        showDialog={showPrompt as boolean}
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        blockMessage={blockMessage}
      />
      <PageContainer
        title={title}
        backTo='/products'
        controls={
          <Button
            onClick={handleSubmit(onSubmit, onError)}
            disableElevation
            variant='contained'
            sx={{ textTransform: 'capitalize', borderRadius: 1.5 }}
            disabled={status === 'loading'}
          >
            {status === 'loading' ? 'Saving...' : 'Save'}
          </Button>
        }
      >
        <Stack
          component='form'
          height='100%'
          bgcolor='white'
          py={3}
          boxShadow={appStyles.shadow}
          borderRadius={1}
        >
          <Stack px={3} height='100%' overflow='auto' gap={1}>
            <Typography component='h4' fontWeight='bold' fontSize={20} mb={1}>
              Product Information
            </Typography>
            <AppTextField
              required
              label='Product Name/Model'
              placeholder='Product Name/Model'
              {...register('name')}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
            <AppTextField
              required
              label={`Manufacturer's Part Number`}
              placeholder={`Manufacturer's Part Number`}
              {...register('partNumber')}
              error={!!errors.partNumber}
              helperText={errors.partNumber?.message}
            />
            <AppTextField
              required
              label='Price'
              placeholder='Price'
              {...register('price')}
              error={!!errors.price}
              helperText={errors.price?.message}
            />
            <AppTextField
              label='Asset Type'
              placeholder='Asset Type'
              {...register('assetType')}
            />
            <AppTextField
              label='IT Spec'
              placeholder='IT Spec'
              {...register('itSpec')}
            />
            <AppTextField
              label='Brand'
              placeholder='Brand'
              {...register('brand')}
            />
            <Controller
              control={control}
              name='stockStatus'
              render={({ field }) => (
                <AppSelect
                  label='Stock Status'
                  placeholder='Stock Status'
                  options={StockStatusOptions}
                  {...field}
                />
              )}
            />

            <AppTextField
              label='Lead Time'
              placeholder='Lead Time'
              {...register('leadTime')}
            />
            <AppTextField
              label='Color'
              placeholder='Color'
              {...register('color')}
            />
            <AppTextField
              label='Variant'
              placeholder='Variant'
              {...register('variant')}
            />
            <AppTextField
              label='Product Description'
              placeholder='Product Description'
              multiline
              rows={4}
              {...register('description')}
            />
            <Typography component='h4' fontWeight='bold' fontSize={20} mb={1}>
              Media
            </Typography>
            {productPhotos.length > 10 && (
              <Typography fontSize={12} mb={1} color='error.main'>
                You can only upload a maximum of 10 photos
              </Typography>
            )}
            <Stack direction='row' gap={2} flexWrap='wrap'>
              {productPhotos.map(({ source, fileName }, index) => (
                <ProductPhoto
                  key={fileName}
                  src={source}
                  name={fileName}
                  onDelete={() => remove(index)}
                  onReplace={(file) =>
                    update(index, { source: file, fileName: file.name })
                  }
                />
              ))}
              <AppMediaUploadButton
                key={productPhotos.length}
                disabled={productPhotos.length >= 10}
                label='Add Photo'
                multiple
                onChange={(e) => handleMediaUpload(e.currentTarget.files)}
              />
            </Stack>
          </Stack>
        </Stack>
      </PageContainer>
    </>
  );
};

export default ProductForm;
