import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Typography } from '@mui/material';
import { appColors } from '../../../theme';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
export default function AccountActiveButton(props: RadioGroupProps) {
  return (
    <FormControl>
      <Typography
        id='account-active-button-label'
        color={appColors.textGray}
        component='label'
        fontSize={13}
        mb='5px'
      >
        Status
      </Typography>
      <RadioGroup
        row
        aria-labelledby='account-active-button-label'
        name='radio-buttons-group'
        {...props}
      >
        <FormControlLabel
          value={true}
          control={<Radio checkedIcon={<CheckCircleIcon />} />}
          label='Active'
        />
        <FormControlLabel
          value={false}
          control={<Radio checkedIcon={<CheckCircleIcon />} />}
          label='Inactive'
        />
      </RadioGroup>
    </FormControl>
  );
}
