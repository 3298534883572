import { useEffect } from 'react';
import { useAppDispatch } from '../store';
import { getRoles } from '../store/role/slice';
import { usePermission } from './usePermissions';

export default function useGetRoles() {
  const dispatch = useAppDispatch();

  const hasPermission = usePermission('User Management');

  useEffect(() => {
    if (!hasPermission) return;
    dispatch(getRoles());
  }, [dispatch, hasPermission]);

  return hasPermission;
}
