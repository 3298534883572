import { useParams } from 'react-router-dom';
import { useAppSelector } from '../store';
import { selectProductById } from '../store/product/slice';

export default function useCurrentProduct() {
  const { id } = useParams<{ id: string }>();
  const currentProduct = useAppSelector(selectProductById(id as string));

  return currentProduct;
}
