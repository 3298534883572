import { useAppDispatch, useAppSelector } from '../../store';
import { forgotPassword } from '../../store/auth/slice';
import { Stack, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormHelperText from '@mui/material/FormHelperText';
import { getAuthState } from '../../store/auth/selector';
import ChangePasswordSuccessDialog from './ChangePasswordSuccessDialog';
import AppTextField from '../../components/common/forms/AppTextField';
import AuthActionButton from '../../components/common/AuthActionButton';

type AuthFields = { email: string };

const forgotPasswordSchema = yup.object({
  email: yup.string().email('Invalid email').required('Email is required'),
});

const ForgotPasswordPage = () => {
  const [successShown, setSuccessShown] = useState(false);
  const { status, error: serverError } = useAppSelector(getAuthState);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AuthFields>({
    defaultValues: { email: '' },
    resolver: yupResolver(forgotPasswordSchema),
    mode: 'onChange',
  });

  const onSubmit = ({ email }: AuthFields) => {
    dispatch(forgotPassword({ email }))
      .unwrap()
      .then(() => {
        setSuccessShown(true);
      });
  };

  const handleDialogOk = () => {
    setSuccessShown(false);
    navigate('/auth', { replace: true });
  };

  return (
    <>
      {successShown && (
        <ChangePasswordSuccessDialog
          onOk={handleDialogOk}
          message='A reset password link has been sent to your email'
          title='Forgot Password'
        />
      )}
      <Stack
        width='100vw'
        height='100vh'
        bgcolor='#FDFDFD'
        alignItems='center'
        justifyContent='center'
      >
        <Stack
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          width={350}
          borderRadius='10px'
          bgcolor='white'
          sx={{ boxShadow: '0px 0px 15px rgba(0,0,0,0.15)' }}
          gap={3}
          p={4}
        >
          <Stack alignItems='flex-start'>
            <img

              alt='coinbase'
              width='auto'
              height={32}
              style={{ alignSelf: 'flex-start' }}
            />
          </Stack>
          <Stack gap={1}>
            <Typography fontWeight='bold' fontSize={22} mb={1}>
              Forgot Password
            </Typography>
            <AppTextField
              label='Email'
              type={'text'}
              placeholder='Enter your email'
              {...register('email')}
              error={!!errors.email}
              helperText={errors.email?.message}
            />

            {serverError && (
              <FormHelperText
                error
                sx={{ justifyContent: 'center', display: 'flex' }}
              >
                {serverError}
              </FormHelperText>
            )}
          </Stack>
          <AuthActionButton
            isForLogin={false}
            isLoading={status === 'loading' ? true : false}
            label='Email Reset Password'
            width={37}
          />

          <Typography fontSize={14}>
            Already have an account? <Link to={`/auth`}>Sign in</Link>
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default ForgotPasswordPage;
