import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Box, Typography } from '@mui/material';
import AppDialog from '../../components/common/AppDialog';
import AppTextField from '../../components/common/forms/AppTextField';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  clearCart,
  clearCartCache,
  createAssetTransaction,
  getCartStatus,
  getTotalCartQuantity,
  getTotalPrice,
} from '../../store/asset/cartSlice';
import { formatCurrency } from '../../utils/helpers';
import CheckoutTable from './CheckoutTable';
import CheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import CheckoutConfirm from './CheckoutConfirm';
import { Count } from './AddToOrderButton';
import SectionLoadingIndicator from '../../components/common/SectionLoadingIndicator';
import { selectProductState } from '../../store/product/slice';

const CheckoutForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const totalPrice = useAppSelector(getTotalPrice);
  const totalQty = useAppSelector(getTotalCartQuantity);
  const cartStatus = useAppSelector(getCartStatus);
  const { status: productStatus } = useAppSelector(selectProductState);
  const [showConfirm, setShowConfirm] = useState(false);
  const [poNumber, setPoNumber] = useState('');

  const handleClose = () => navigate('/assets', { replace: true });

  const handleCheckout = () => {
    if (cartStatus === 'loading') return;

    dispatch(createAssetTransaction(poNumber))
      .unwrap()
      .then(() => {
        dispatch(clearCart());
        clearCartCache();
        handleClose();
      });
  };

  const title = `Order Subtotal - ${formatCurrency(totalPrice, 'Php')}`;

  if (productStatus === 'fetching') return null;

  return (
    <AppDialog open onDialogClose={handleClose} title={title} maxWidth='md'>
      {totalQty > 0 ? (
        <Box maxHeight='450px' overflow='auto' position='relative'>
          <SectionLoadingIndicator open={cartStatus === 'loading'} />
          <Stack>
            <CheckoutTable />
          </Stack>
          <Stack mt={6}>
            <AppTextField
              label='Enter a P.O. number (if any)'
              placeholder='#########'
              value={poNumber}
              onChange={(e) => setPoNumber(e.currentTarget.value)}
            />
            <Stack alignItems='center' my={4}>
              <Button
                onClick={() => setShowConfirm(true)}
                disableElevation
                variant='contained'
                startIcon={<CheckoutIcon />}
                sx={{
                  textTransform: 'capitalize',
                  borderRadius: '6px',
                  minWidth: 190,
                }}
              >
                Checkout <Count sx={{ ml: 1 }}>{totalQty}</Count>
              </Button>
            </Stack>
          </Stack>
        </Box>
      ) : (
        <Box gap={2} textAlign='center' py={2}>
          <Typography fontSize={20} color='#bbb'>
            Your cart is empty
          </Typography>
          <Button
            onClick={handleClose}
            disableElevation
            variant='contained'
            sx={{
              mt: 2,
              textTransform: 'capitalize',
              borderRadius: '6px',
              minWidth: 190,
            }}
          >
            Select Assets Now
          </Button>
        </Box>
      )}
      {showConfirm && (
        <CheckoutConfirm onCheckout={handleCheckout} onClose={() => setShowConfirm(false)} />
      )}
    </AppDialog>
  );
};

export default CheckoutForm;
