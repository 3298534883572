import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import BackIcon from '@mui/icons-material/ArrowBack';
import { Stack } from '@mui/material';

interface PageContainerProps {
  children: ReactNode;
  title: string;
  backTo?: string;
  controls?: ReactNode;
  loading?: boolean;
}

const PageContainer = (props: PageContainerProps) => {
  const navigate = useNavigate();

  const { children, title, backTo, controls, loading } = props;

  const handleBack = () => {
    if (!backTo) return;

    navigate(backTo, { replace: true });
  };

  return (
    <Stack p={3} height='calc(100% - 64px)'>
      <Stack direction='row' alignItems='center' gap={2} mb={2} sx={{ opacity: loading ? 0 : 1 }}>
        {backTo && (
          <IconButton aria-label='back' onClick={handleBack} edge='start' color='primary'>
            <BackIcon />
          </IconButton>
        )}
        <Typography fontWeight={500} fontSize={30} letterSpacing={0.5}>
          {title}
        </Typography>
        {controls && (
          <Stack ml='auto' direction='row' alignItems='center'>
            {controls}
          </Stack>
        )}
      </Stack>
      <Box height='calc(100% - 61px)' position='relative'>
        {children}
      </Box>
    </Stack>
  );
};

export default PageContainer;
