import { Navigate, Route, Routes } from 'react-router-dom';
import ForgotPasswordPage from './ForgotPasswordPage';
import LoginPage from './LoginPage';
import SetPasswordPage from './SetPasswordPage';
import ResetPasswordPage from './ResetPasswordPage';

const AuthPage = () => {
  return (
    <Routes>
      <Route path='/' element={<LoginPage />} />
      <Route path='/new-password/:token' element={<SetPasswordPage />} />
      <Route path='/forgot-password' element={<ForgotPasswordPage />} />
      <Route path='/reset-password/:token' element={<ResetPasswordPage />} />
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default AuthPage;
