import { useState, ChangeEvent, useEffect } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/AddRounded';
import MinusIcon from '@mui/icons-material/Remove';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppDispatch, useAppSelector } from '../../store';
import { getCartByProductId, changeQuantity, removeFromCart } from '../../store/asset/cartSlice';

const CartButton = styled(Button)(({ theme }) => ({
  textTransform: 'unset',
  fontSize: 13,
  borderRadius: '6px',
  padding: '6px 16px',
  height: 36.38,

  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1,
  transition: 'opacity 0.2s ease-in',

  '&.fade': {
    transition: 'opacity 0.2s ease-in',
    opacity: 0,
    zIndex: -1,
  },
}));

const CheckButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  borderRadius: 6,
  width: 34,
  height: 36,

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },

  '& > svg': {
    fontSize: 18,
  },
}));

const QtyBtn = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'white',
  color: theme.palette.primary.main,
  borderRadius: 6,
  width: 30,
  height: 30,

  '&:hover': {
    backgroundColor: 'white',
  },

  '& > svg': {
    fontSize: 18,
  },
}));

const QtyInput = styled('input')(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  padding: '5px 4px',
  height: '100%',
  boxSizing: 'border-box',
  width: '100%',
  textAlign: 'center',

  '&:focus': {
    outlineWidth: 0,
  },
}));

export const Count = styled('span')(({ theme }) => ({
  fontSize: 'inherit',
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 21,
  height: 21,
  backgroundColor: 'white',
  borderRadius: '50%',
  marginRight: '8px',
}));

const AddToOrderButton = ({
  productId,
  inCheckout,
}: {
  productId: string;
  inCheckout?: boolean;
}) => {
  const [addToOrderClicked, setAddToOrderClicked] = useState(false);
  const dispatch = useAppDispatch();
  const cart = useAppSelector(getCartByProductId(productId));

  const [quantity, setQuantity] = useState<number | ''>(() => (cart ? cart.quantity : 0));

  useEffect(() => {
    setQuantity(() => (cart ? cart.quantity : 0));
  }, [cart]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isValidNumber = value.match(/^\d*$/);

    if (!isValidNumber) return;
    setQuantity(() => (!value ? '' : parseInt(value)));

    if (inCheckout) {
      dispatch(changeQuantity({ productId, quantity: +quantity }));
    }
  };

  const increment = () => {
    setQuantity((qty) => +qty + 1);

    if (inCheckout) {
      dispatch(changeQuantity({ productId, quantity: +quantity + 1 }));
    }
  };

  const decrement = () => {
    setQuantity((qty) => (qty === 0 ? 0 : +qty - 1));

    if (inCheckout) {
      dispatch(changeQuantity({ productId, quantity: quantity === 0 ? quantity : +quantity - 1 }));
    }
  };

  const handleFinalChange = () => {
    if (quantity === 0) {
      dispatch(removeFromCart(productId));
    } else {
      dispatch(changeQuantity({ productId, quantity: +quantity }));
    }
    setAddToOrderClicked(false);
  };

  const handleRemoveFromCart = () => {
    dispatch(removeFromCart(productId));
  };

  return (
    <Box width={180}>
      <Box position='relative'>
        {!inCheckout && (
          <CartButton
            onClick={() => {
              setAddToOrderClicked(true);
              if (quantity === 0) {
                setQuantity(1);
              }
            }}
            className={addToOrderClicked ? 'fade' : ''}
            fullWidth
            disableElevation
            variant='contained'
            startIcon={quantity > 0 ? undefined : <AddIcon />}
            sx={{
              ...(quantity > 0 && {
                bgcolor: '#DDE8FF',
                color: 'primary.main',
                '&:hover': {
                  bgcolor: '#DDE8FF',
                  color: 'primary.main',
                },
              }),
            }}
          >
            {quantity > 0 ? (
              <>
                <Count>{quantity}</Count> Added to Order
              </>
            ) : (
              'Add to Order'
            )}
          </CartButton>
        )}
        <Box display='flex' alignItems='center' maxWidth='100%' gap={0.8}>
          <Box display='flex' alignItems='center' bgcolor='#F5F5F5' p={0.4} borderRadius='6px'>
            <QtyBtn
              aria-label='subtract quantity'
              onClick={decrement}
              disabled={inCheckout && quantity === 1}
            >
              <MinusIcon />
            </QtyBtn>
            <QtyInput
              aria-label='quantity'
              name='quantity'
              placeholder='0'
              value={quantity}
              onChange={handleChange}
            />
            <QtyBtn aria-label='add quantity' onClick={increment}>
              <AddIcon />
            </QtyBtn>
          </Box>
          {!inCheckout && (
            <CheckButton aria-label='confirm quantty' onClick={handleFinalChange}>
              <CheckIcon />
            </CheckButton>
          )}
          {inCheckout && (
            <CheckButton aria-label='remove from cart' onClick={handleRemoveFromCart}>
              <DeleteIcon />
            </CheckButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AddToOrderButton;
