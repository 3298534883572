import { createColumnHelper } from "@tanstack/react-table";

import type { PurchaseOrder } from "../../../store/purchaseOrder/types";
import { formatDate } from "../../../utils/helpers";
import PurchaseOrderActions from "./PurchaseOrderActions";
import PurchaseOrderStatus from "./purchaseOrderStatus";

const columnHelper = createColumnHelper<PurchaseOrder>();

const purchaseOrderColumns = [
  columnHelper.accessor("orderDate", {
    id: "orderDate",
    header: "Order Date",
    cell: (row) => formatDate(row.getValue()),
    enableColumnFilter: false,
    enableGlobalFilter: true,
  }),
  columnHelper.accessor("poNumber", {
    id: "poNumber",
    header: "P.O. Number",
    cell: (row) => row.getValue(),
    enableColumnFilter: false,
    enableGlobalFilter: true,
    enableMultiSort: false,
    enableSorting: false,
  }),
  columnHelper.accessor("completionStatus", {
    id: "completionStatus",
    header: "Completion Status",
    cell: ({ row }) => <PurchaseOrderStatus row={row} />,
    enableColumnFilter: true,
    enableGlobalFilter: false,
  }),
  columnHelper.display({
    id: "actions",
    header: "Action",
    cell: ({ row }) => (
      <PurchaseOrderActions state={row} purchaseOrder={row.original} />
    ),
  }),
];

export default purchaseOrderColumns;
