import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';

interface NotFoundViewProps {
  name: string;
  buttonText: string;
  backTo: string;
}

const NotFoundView = ({ name, buttonText, backTo }: NotFoundViewProps) => {
  const navigate = useNavigate();

  return (
    <Stack m={4}>
      <Alert severity='error'>
        <AlertTitle>{name} not found.</AlertTitle>
        The {name} that you're trying to view/update does not exist or was already deleted.
        <Stack mt={4}>
          <Button
            onClick={() => navigate(backTo, { replace: true })}
            color='error'
            variant='contained'
            size='medium'
            disableElevation
            sx={{ width: 'max-content', textTransform: 'capitalize' }}
          >
            {buttonText}
          </Button>
        </Stack>
      </Alert>
    </Stack>
  );
};

export default NotFoundView;
