import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ApiError } from '..';
import authApi from '../../services/auth';
import { clearOrderState } from '../order/slice';
import { clearProductState } from '../product/slice';
import { clearPurchaseOrderState } from '../purchaseOrder/slice';
import { clearRoleState } from '../role/slice';
import { AuthState, AuthUser } from './types';

const initialState: AuthState = {
  status: 'idle',
  error: undefined,
  user: null,
};

export const login = createAsyncThunk<
  AuthUser,
  { email: string; password: string }
>('auth/login', async (credentials, thunkAPI) => {
  try {
    const { email, password } = credentials;
    const user = await authApi.login(email, password);
    return user;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getLoggedInUser = createAsyncThunk<
  AuthUser,
  { userId: string; accessToken: string }
>('auth/getLoggedInUser', async ({ userId, accessToken }, thunkAPI) => {
  try {
    const user = await authApi.getLoggedInUser(userId, accessToken);
    return user;
  } catch (err) {
    thunkAPI.dispatch(logout());

    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  try {
    await authApi.logout();

    // clear the store
    thunkAPI.dispatch(clearPurchaseOrderState());
    thunkAPI.dispatch(clearOrderState());
    thunkAPI.dispatch(clearProductState());
    thunkAPI.dispatch(clearRoleState());
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    throw error.response.data.error;
  }
});

export const forgotPassword = createAsyncThunk<string, { email: string }>(
  'auth/forgot-password',
  async (args, thunkAPI) => {
    try {
      const { email } = args;
      const user = await authApi.forgotPassword(email);
      return user;
    } catch (err) {
      let error = err as AxiosError<ApiError>;
      if (!error.response) throw err;

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const setNewPassword = createAsyncThunk<
  string,
  { newPassword: string; confirmPassword: string; token: string }
>('auth/set-password', async (args, thunkAPI) => {
  const { newPassword, confirmPassword, token } = args;

  try {
    const response = await authApi.setNewPassword(
      newPassword,
      confirmPassword,
      token
    );
    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state, action) => {
      state.status = 'idle';
      state.error = undefined;
      state.user = null;
    });
    builder.addCase(setNewPassword.fulfilled, (state, action) => {
      state.status = 'idle';
      state.error = undefined;
      state.user = null;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.status = 'idle';
      state.error = undefined;
      state.user = null;
    });

    builder.addMatcher(
      isAnyOf(
        login.pending,
        getLoggedInUser.pending,
        logout.pending,
        setNewPassword.pending,
        forgotPassword.pending
      ),
      (state, action) => {
        state.status = 'loading';
        state.error = undefined;
      }
    );

    builder.addMatcher(
      isAnyOf(
        login.rejected,
        getLoggedInUser.rejected,
        logout.rejected,
        setNewPassword.rejected,
        forgotPassword.rejected
      ),

      (state, action) => {
        state.status = 'idle';

        if (action.payload) {
          state.error = (action.payload as ApiError).error;
        } else {
          state.error = action.error.message;
        }
      }
    );

    builder.addMatcher(
      isAnyOf(login.fulfilled, getLoggedInUser.fulfilled),
      (state, action) => {
        state.status = 'idle';
        state.error = undefined;
        state.user = action.payload;
      }
    );
  },
});

export default authSlice.reducer;
