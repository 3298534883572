import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import SidebarItem from "./SidebarItem";
import { useSidebar } from "./sidebarContext";
import { SidebarItemProps } from "./types";
import AppNavLink from "./AppNavLink";

const ExpandableSidebarItem = (props: SidebarItemProps) => {
  const { sidebarItem, index } = props;

  const { activeItems, onMenuClick, activeStyles } = useSidebar();

  const isExpanded = activeItems.includes(index as number);

  return (
    <div style={{ marginBottom: "8px" }}>
      <AppNavLink
        to={sidebarItem.path as string}
        style={({ isActive }) => ({
          ...(isActive ? activeStyles : undefined),
        })}
      >
        <ListItemButton
          onClick={() => onMenuClick(index as number)}
          sx={{
            borderRadius: "10px",
            height: 48,
            color: "white",
            bgcolor: "unset",
          }}
        >
          <ListItemIcon
            sx={{
              color: "inherit",
              minWidth: "auto",
              mr: 2,
            }}
          >
            {sidebarItem.icon}
          </ListItemIcon>
          <Typography color='inherit' fontSize={13} lineHeight={1}>
            {sidebarItem.label}
          </Typography>
          <ListItemIcon sx={{ color: "inherit", minWidth: "auto", ml: "auto", mt: 0.2 }}>
            {isExpanded ? <ExpandLessIcon fontSize='small' /> : <ExpandMoreIcon fontSize='small' />}
          </ListItemIcon>
        </ListItemButton>
      </AppNavLink>
      <Collapse in={isExpanded} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {sidebarItem.subItems?.map((item) => (
            <SidebarItem key={item.label} sidebarItem={item} />
          ))}
        </List>
      </Collapse>
    </div>
  );
};

export default ExpandableSidebarItem;
