import { createColumnHelper } from '@tanstack/react-table';
import { UserAccount } from '../../../store/accounts/types';
import AccountsActionButton from './AccountsActionButton';
const columnHelper = createColumnHelper<UserAccount>();

const accountsColumns = [
  columnHelper.accessor((row) => `${row.firstName} ${row.lastName}`, {
    id: 'fullName',
    header: 'Name',
    cell: (row) => row.getValue(),
    enableColumnFilter: false,
  }),
  columnHelper.accessor('email', {
    id: 'email',
    header: 'Email',
    cell: (row) => row.getValue(),
    enableColumnFilter: false,
  }),
  columnHelper.accessor('userRole.name', {
    id: 'role',
    header: 'Role',
    cell: (row) => (row.getValue() ? row.getValue() : 'No roles'),
    enableColumnFilter: false,
  }),
  columnHelper.accessor('isActive', {
    id: 'isActive',
    header: 'Status',
    cell: (row) => (row.getValue() ? 'Active' : 'Inactive'),
    enableColumnFilter: true,
  }),
  columnHelper.display({
    id: 'actions',
    header: 'Action',
    cell: ({ row }) => <AccountsActionButton userAccount={row.original} />,
  }),
];

export default accountsColumns;
