import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import NotFoundView from '../../../components/common/NotFoundView';
import PageContainer from '../../../components/common/PageContainer';
import useGetOrderById from '../../../hooks/useGetOrderById';
import { useAppSelector } from '../../../store';
import { selectOrderState } from '../../../store/order/selectors';
import { appStyles } from '../../../theme';
import OrderItem from './OrderItem';
import OrderJourney from './OrderJourney';
import OrderUpdates from './OrderUpdates';

const OrderHistory = () => {
  const { status } = useAppSelector(selectOrderState);
  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>();
  const order = useGetOrderById();

  if (status === 'fetching') return <LoadingIndicator show />;

  if (!order)
    return (
      <NotFoundView
        name='Order'
        backTo={`/requested-assets/po/${purchaseOrderId}`}
        buttonText='Back to Orders List'
      />
    );

  const backTo = `/requested-assets/po/${order.purchaseOrderId}`;

  const title = order.poNumber;

  return (
    <PageContainer title={title} backTo={backTo}>
      <Stack
        height='100%'
        bgcolor='white'
        py={2}
        boxShadow={appStyles.shadow}
        borderRadius={1}
      >
        <Stack px={3} gap={2} height='100%' overflow='auto'>
          <OrderJourney orderHistory={order.history} />
          <OrderUpdates orderHistory={order.history} />
          <OrderItem order={order} />
        </Stack>
      </Stack>
    </PageContainer>
  );
};

export default OrderHistory;
