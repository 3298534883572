import { apiClient, apiEndpoints } from './apiClient';
import { Role } from '../store/role/types';

type GetReturnType = {
  status: string;
  count: number;
  roles: Role[];
};

type GetOneReturnType = {
  status: string;
  role: Role;
};

const getRoles = async () => {
  const response = await apiClient.get<GetReturnType>(apiEndpoints.roles);
  return response.data.roles;
};

const getRoleById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(`${apiEndpoints.roles}/${id}`);
  return response.data.role;
};

const createRole = async (role: Partial<Role>) => {
  const response = await apiClient.post<GetOneReturnType>(apiEndpoints.roles, role);
  return response.data.role;
};

const updateRole = async (id: string, role: Partial<Role>) => {
  const response = await apiClient.put<GetOneReturnType>(`${apiEndpoints.roles}/${id}`, role);
  return response.data.role;
};

const deleteRole = async (id: string) => {
  const response = await apiClient.delete(`${apiEndpoints.roles}/${id}`);
  return response.data.role;
};

const roleApi = {
  getRoles,
  getRoleById,
  createRole,
  updateRole,
  deleteRole,
};

export default roleApi;
