import { Box } from '@mui/material';
import defaultProductThumbnail from '../../assets/images/product-default-thumbnail.png';

interface ProductThumbnailProps {
  src: string;
  alt: string;
}

const ProductThumbnail = ({ src, alt }: ProductThumbnailProps) => {
  return (
    <Box
      width='100%'
      mx='auto'
      textAlign='center'
      sx={{ '& > img': { objectFit: 'cover', aspectRatio: '1 / 1' } }}
    >
      <img src={src ? src : defaultProductThumbnail} alt={alt} width={56} />
    </Box>
  );
};

export default ProductThumbnail;
