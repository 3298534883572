import jwt_decode from 'jwt-decode';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store';
import { getAuth } from '../store/auth/selector';
import { getLoggedInUser } from '../store/auth/slice';

export default function useAuth() {
  const authUser = useAppSelector(getAuth);
  const dispatch = useAppDispatch();

  const storedToken = localStorage.getItem('accessToken');
  const token = storedToken ? JSON.parse(storedToken) : null;

  useEffect(() => {
    if (authUser) return;

    if (!token) {
      return;
    }

    // decode token
    const decoded: any = jwt_decode(token);

    const userId = decoded?.id;

    if (!userId) {
      return;
    }

    dispatch(getLoggedInUser({ userId, accessToken: token }));
  }, [authUser, dispatch, token]);

  return authUser || token ? true : false;
}
