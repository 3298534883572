import { yupResolver } from '@hookform/resolvers/yup';
import {
  useForm,
  SubmitErrorHandler,
  SubmitHandler,
  Controller,
} from 'react-hook-form';

import { Button, Stack } from '@mui/material';
import { appStyles } from '../../../theme';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { useEffect } from 'react';
import AppTextField from '../../../components/common/forms/AppTextField';
import PageContainer from '../../../components/common/PageContainer';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  userAccountSchema,
  UserAccountsFields,
  userAccountsInitialValues,
} from './accounts-utils';
import AppSelect from '../../../components/common/forms/AppSelect';
import {
  createUserAccount,
  updateUserAccount,
} from '../../../store/accounts/slice';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { selectRoleState } from '../../../store/role/slice';
import { SelectOptionType } from '../../../components/common/forms/form-types';
import { selectUserAccountsState } from '../../../store/accounts/selectors';
import AccountActiveButton from './AccountActiveButton';
import { useIsSuperAdmin } from '../../../hooks/usePermissions';
import ConfirmPromptDialog from '../../../components/common/ConfirmPromptDialog';
import { useCallbackPrompt } from '../../../hooks/useCallbackPrompt';

const AccountsForm = () => {
  const { data: roles } = useAppSelector(selectRoleState);
  const superAdmin = useIsSuperAdmin();
  const roleOptions: SelectOptionType[] = roles
    .filter((e) => (superAdmin ? e : e.id !== 'ROLE::1'))
    .map((e) => ({
      id: e.id,
      label: e.name,
      value: e.id,
    }));

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { status } = useAppSelector(selectUserAccountsState);
  const currentUserAccount = useCurrentUser();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,

    formState: { errors, isDirty },
  } = useForm<UserAccountsFields>({
    defaultValues: userAccountsInitialValues,
    resolver: yupResolver(userAccountSchema),
    mode: 'onChange',
  });

  const [showPrompt, confirmNavigation, cancelNavigation, blockMessage] =
    useCallbackPrompt(isDirty);

  useEffect(() => {
    if (!currentUserAccount) return;

    Object.keys(currentUserAccount).forEach((key) => {
      const _key = key as keyof UserAccountsFields;
      setValue(_key, currentUserAccount[_key]);
    });
  }, [currentUserAccount, setValue]);

  const onError: SubmitErrorHandler<UserAccountsFields> = (err) => {
    console.error(err);
  };

  const onSubmit: SubmitHandler<UserAccountsFields> = (userAccount) => {
    reset({}, { keepDirty: false });

    if (currentUserAccount) {
      return dispatch(
        updateUserAccount({
          id: currentUserAccount.id,
          userAccount,
        })
      )
        .unwrap()
        .then(() => navigate('/users/accounts'));
    }

    dispatch(createUserAccount(userAccount))
      .unwrap()
      .then(() => navigate('/users/accounts'));
  };

  const title = currentUserAccount
    ? `${currentUserAccount.firstName} ${currentUserAccount.lastName}`
    : 'Add User Account';

  if (status === 'fetching') return <LoadingIndicator show />;

  return (
    <>
      <ConfirmPromptDialog
        showDialog={showPrompt as boolean}
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        blockMessage={blockMessage}
      />
      <PageContainer
        title={title}
        backTo='/users/accounts'
        controls={
          !superAdmin && currentUserAccount?.roleId === 'ROLE::1' ? null : (
            <Button
              onClick={handleSubmit(onSubmit, onError)}
              disableElevation
              variant='contained'
              sx={{ textTransform: 'capitalize', borderRadius: 1.5 }}
              disabled={status === 'loading'}
            >
              {status === 'loading' ? 'Saving...' : 'Save'}
            </Button>
          )
        }
      >
        <Stack
          component='form'
          height='100%'
          bgcolor='white'
          py={3}
          boxShadow={appStyles.shadow}
          borderRadius={1}
        >
          <Stack px={3} height='100%' overflow='auto' gap={1}>
            <AppTextField
              required
              disabled={
                !superAdmin && currentUserAccount?.roleId === 'ROLE::1'
                  ? true
                  : false
              }
              label='First Name'
              placeholder='First Name'
              {...register('firstName')}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
            />
            <AppTextField
              required
              disabled={
                !superAdmin && currentUserAccount?.roleId === 'ROLE::1'
                  ? true
                  : false
              }
              label='Last Name'
              placeholder='Last Name'
              {...register('lastName')}
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
            />
            <AppTextField
              required
              disabled={
                !superAdmin && currentUserAccount?.roleId === 'ROLE::1'
                  ? true
                  : false
              }
              label='Email'
              placeholder='Email'
              {...register('email')}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
            {!superAdmin && currentUserAccount?.roleId === 'ROLE::1'
              ? null
              : currentUserAccount && (
                  <Controller
                    control={control}
                    name='isActive'
                    render={({ field: { onChange, value } }) => (
                      <AccountActiveButton
                        value={value}
                        onChange={(e, v) => onChange(v)}
                      />
                    )}
                  />
                )}
            {!superAdmin && currentUserAccount?.roleId === 'ROLE::1' ? null : (
              <Controller
                control={control}
                name='roleId'
                render={({ field }) => (
                  <AppSelect
                    label='Role'
                    placeholder='Role'
                    options={roleOptions}
                    {...field}
                  />
                )}
              />
            )}
          </Stack>
        </Stack>
      </PageContainer>
    </>
  );
};

export default AccountsForm;
