import Box from "@mui/material/Box";

import LayoutContextProvider from "../../components/layout-ui/layoutContext";
import Main from "../../components/layout-ui/Main";
import AMIContent from "../AMIContent";
import AppHeader from "./AppHeader";
import AppSidebar from "./AppSidebar";

const AppContent = () => {
  return (
    <LayoutContextProvider sidebarWidth={270}>
      <Box display='flex'>
        <AppHeader />
        <AppSidebar />
        <Main>
          <AMIContent />
        </Main>
      </Box>
    </LayoutContextProvider>
  );
};

export default AppContent;
