import { MenuItem, Select } from '@mui/material';
import ChevronIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Product } from '../../store/product/types';

type StockFilter = 'All' | Product['stockStatus'];

interface StockStatusFilterProps {
  value: StockFilter;
  onChange: (value: StockFilter) => void;
}

const options: Array<StockFilter> = ['All', 'Available', 'Order Basis'];

const StockStatusFilter = ({ value, onChange }: StockStatusFilterProps) => {
  return (
    <Select
      IconComponent={ChevronIcon}
      size='small'
      labelId='asset-stock-status-filter-label'
      id='asset-stock-status-filter'
      sx={{ fontSize: 13, height: 37, minWidth: 170, bgcolor: 'white', mr: 3 }}
      value={value}
      onChange={(e) => onChange(e.target.value as StockFilter)}
      renderValue={(selected) => {
        return selected;
      }}
    >
      {options.map((item) => (
        <MenuItem key={item} value={item} sx={{ fontSize: 14 }}>
          {item}
        </MenuItem>
      ))}
    </Select>
  );
};

export default StockStatusFilter;
