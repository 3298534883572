import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';

import { SidebarItemProps } from './types';
import { useSidebar } from './sidebarContext';
import AppNavLink from './AppNavLink';

const SidebarItem = (props: SidebarItemProps) => {
  const { sidebarItem, index, indented } = props;

  const { activeStyles, subItemActiveStyles, onMenuClick } = useSidebar();

  const _activeStyles = index === undefined ? subItemActiveStyles : activeStyles;

  return (
    <AppNavLink
      to={sidebarItem.path as string}
      style={({ isActive }) => ({
        ...(isActive ? _activeStyles : undefined),
      })}
    >
      <ListItemButton
        onClick={() => onMenuClick(index as number)}
        disableGutters
        sx={{
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: 48,
          padding: 2,
        }}
      >
        {sidebarItem.icon ? (
          <ListItemIcon
            sx={{
              color: 'inherit',
              minWidth: 'auto',
              mr: 2,
              pl: indented ? 4 : 0,
            }}
          >
            {sidebarItem.icon}
          </ListItemIcon>
        ) : (
          <ListItemIcon sx={{ minWidth: 37, mr: 2 }} />
        )}
        <Typography color='inherit' fontSize={13} lineHeight={1}>
          {sidebarItem.label}
        </Typography>
      </ListItemButton>
    </AppNavLink>
  );
};

export default SidebarItem;
