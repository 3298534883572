import { Typography, Stack, Button } from '@mui/material';
import AppDialog from '../../components/common/AppDialog';
import { useAppSelector } from '../../store';
import { getCartStatus, getTotalCartQuantity } from '../../store/asset/cartSlice';

interface CheckoutConfirmProps {
  onClose: () => void;
  onCheckout: () => void;
}

const CheckoutConfirm = ({ onClose, onCheckout }: CheckoutConfirmProps) => {
  const totalQty = useAppSelector(getTotalCartQuantity);

  const cartStatus = useAppSelector(getCartStatus);

  const loading = cartStatus === 'loading';

  const message =
    totalQty > 1
      ? 'Would you like to check out these items?'
      : 'Would you like to check out this item?';

  const handleClose = () => {
    if (loading) return;

    onClose();
  };

  return (
    <AppDialog open onDialogClose={handleClose} title='Confirm Checkout'>
      <Typography textAlign='center'>{message}</Typography>
      <Stack direction='row' alignItems='center' justifyContent='center' mt={4} mb={2} gap={2}>
        <Button
          variant='contained'
          disableElevation
          sx={{ textTransform: 'capitalize', pointerEvents: loading ? 'none' : 'initial' }}
          onClick={onCheckout}
        >
          {loading ? 'Processing...' : 'Yes'}
        </Button>
        <Button
          color='secondary'
          variant='outlined'
          disableElevation
          sx={{ textTransform: 'capitalize' }}
          onClick={handleClose}
        >
          No
        </Button>
      </Stack>
    </AppDialog>
  );
};

export default CheckoutConfirm;
