import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import type { ApiError, RootState } from '../index';
import type { Order, OrderState } from './types';
import orderApi from '../../services/order';
import { AxiosError } from 'axios';
import { getPurchaseOrders } from '../purchaseOrder/slice';
import { showSuccessToast } from '../toast/slice';

const initialState: OrderState = {
  status: 'idle',
  error: undefined,
  data: [],
};

export const getOrdersByPO = createAsyncThunk<
  Order[],
  string,
  {
    rejectValue: ApiError;
    state: RootState;
  }
>('orders/getByPurchaseOrderId', async (purchaseOrderId, thunkAPI) => {
  try {
    // check first if PO is in the store
    const purchaseOrders = thunkAPI.getState().purchaseOrders.data;

    const foundPO = purchaseOrders.find((po) => po.id === purchaseOrderId);

    // if no PO, fetch all POs
    if (!foundPO) {
      await thunkAPI.dispatch(getPurchaseOrders());
    }

    const response = await orderApi.getOrdersByPO(purchaseOrderId);
    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const importOrders = createAsyncThunk<
  Order[],
  FormData,
  {
    rejectValue: ApiError;
  }
>('orders/import', async (formData, thunkAPI) => {
  try {
    const purchaseOrderId = formData.get('purchaseOrderId')?.toString();
    if (!purchaseOrderId) throw new Error('Purchase Order ID is required');

    const response = await orderApi.importOrders(formData);

    // show success toast
    thunkAPI.dispatch(showSuccessToast('Orders imported!'));

    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateOrderStatus = createAsyncThunk<
  Order,
  { id: string; status: Order['status']; previousStatus: Order['status'] },
  {
    rejectValue: ApiError;
  }
>('orders/updateStatus', async ({ id, status }, thunkAPI) => {
  try {
    const response = await orderApi.updateOrderStatus(id, status);

    return response;
  } catch (err) {
    let error = err as AxiosError<ApiError>;
    if (!error.response) throw err;

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    clearOrderState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getOrdersByPO.pending, (state) => {
      state.status = 'fetching';
    });

    builder.addCase(getOrdersByPO.fulfilled, (state, action) => {
      state.data = action.payload;
    });

    builder.addCase(updateOrderStatus.pending, (state, action) => {
      // optimistic update
      const updatedOrder = state.data.find((item) => item.id === action.meta.arg.id);

      if (updatedOrder) {
        updatedOrder.status = action.meta.arg.status;
      }
    });

    builder.addCase(updateOrderStatus.rejected, (state, action) => {
      // if rejected, revoke the update
      const updatedOrder = state.data.find((item) => item.id === action.meta.arg.id);

      if (updatedOrder) {
        updatedOrder.status = action.meta.arg.previousStatus;
      }
    });

    builder.addMatcher(isAnyOf(importOrders.pending, updateOrderStatus.pending), (state) => {
      state.status = 'loading';
    });

    builder.addMatcher(
      isAnyOf(getOrdersByPO.fulfilled, importOrders.fulfilled, updateOrderStatus.fulfilled),
      (state) => {
        state.status = 'idle';
        state.error = undefined;
      }
    );

    builder.addMatcher(
      isAnyOf(getOrdersByPO.rejected, importOrders.rejected, updateOrderStatus.rejected),
      (state, action) => {
        state.status = 'idle';

        if (action.payload) {
          state.error = action.payload.error;
        } else {
          state.error = action.error.message;
        }
      }
    );
  },
});

export const { clearOrderState } = orderSlice.actions;

export default orderSlice.reducer;
