import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

const NoAccessView = () => {
  return (
    <Stack p={3}>
      <Stack>
        <Alert severity='error'>
          <AlertTitle>Access Denied</AlertTitle>
          You do not have permission to access this page.
        </Alert>
      </Stack>
    </Stack>
  );
};

export default NoAccessView;
