import { ReactNode } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import {
  RequestedAssetIcon,
  ProductIcon,
  UserIcon,
  AssetIcon,
} from "../../assets/icons/CoinbaseIcons";
import { Stack } from "@mui/material";

interface SidebarMenu {
  label: string;
  icon?: ReactNode;
  path?: string;
  subItems?: Omit<SidebarMenu, "subItems">[];
}

// NOTE: ROUTES CAN BE CHANGED
const menu: SidebarMenu[] = [
  {
    label: "Asset",
    icon: <AssetIcon fontSize='small' />,
    path: "/assets",
  },
  {
    label: "Requested Asset",
    icon: <RequestedAssetIcon fontSize='small' />,
    path: "/requested-assets",
  },
  {
    label: "Product Management",
    icon: <ProductIcon fontSize='small' />,
    path: "/products",
  },
  {
    label: "User Management",
    icon: <UserIcon fontSize='small' />,
    path: "/users",
    subItems: [
      {
        label: "Role & Accessibility",
        path: "/users/roles",
        icon: (
          <Stack width={20} alignItems='center'>
            <CircleIcon className='sub-icon' sx={{ fontSize: 10, color: "primary.main" }} />
          </Stack>
        ),
      },
      {
        label: "User Account",
        path: "/users/accounts",
        icon: (
          <Stack width={20} alignItems='center'>
            <CircleIcon className='sub-icon' sx={{ fontSize: 10, color: "primary.main" }} />
          </Stack>
        ),
      },
    ],
  },
];

export default menu;
