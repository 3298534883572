import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../index';
import type { ToastState } from './types';

const initialState: ToastState = {
  type: 'success',
  visible: false,
  message: null,
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast: (toast, action: PayloadAction<Omit<ToastState, 'visible'>>) => {
      toast.type = action.payload.type;
      toast.message = action.payload.message;
      toast.visible = true;
    },
    showSuccessToast: (toast, action: PayloadAction<ToastState['message']>) => {
      toast.type = 'success';
      toast.message = action.payload;
      toast.visible = true;
    },
    showErrorToast: (toast, action: PayloadAction<ToastState['message']>) => {
      toast.type = 'error';
      toast.message = action.payload;
      toast.visible = true;
    },
    hideToast: (toast) => {
      toast.visible = false;
      toast.message = null;
    },
  },
});

const toastReducer = toastSlice.reducer;

export const { showToast, showSuccessToast, showErrorToast, hideToast } = toastSlice.actions;

export const selectToast = (state: RootState) => state.toast;

export default toastReducer;
