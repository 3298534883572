import { Alert, AlertTitle, Button, Stack, Typography } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import AppDialog from '../../../components/common/AppDialog';
import useRoleById from '../../../hooks/useRoleById';
import { useAppDispatch, useAppSelector } from '../../../store';
import { deleteRole, selectRoleState } from '../../../store/role/slice';

const RoleDeleteDialog = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectRoleState);
  const currentRole = useRoleById();

  if (!currentRole) return null;

  const handleClose = () => navigate('/users/roles', { replace: true });

  const handleDelete = () => {
    if (!currentRole) return null;

    dispatch(deleteRole(currentRole.id))
      .unwrap()
      .then(() => navigate('/users/roles', { replace: true }));
  };

  const loading = status === 'loading';

  if (currentRole?.id === 'ROLE::1') return <Navigate to='/users/roles' replace />;

  return (
    <AppDialog open onDialogClose={handleClose} title={`Delete: ${currentRole?.name}`}>
      <Alert severity='warning'>
        <AlertTitle>Note</AlertTitle>
        Deleting this role will cause the users with this role to have the Default Role
      </Alert>
      <Typography textAlign='center' sx={{ mt: 4 }}>
        Would you like to delete this role?
      </Typography>
      <Stack direction='row' alignItems='center' justifyContent='center' mt={4} mb={2} gap={2}>
        <Button
          variant='contained'
          color='error'
          disableElevation
          sx={{ textTransform: 'capitalize', pointerEvents: loading ? 'none' : 'initial' }}
          onClick={handleDelete}
        >
          {loading ? 'Deleting...' : 'Yes'}
        </Button>
        <Button
          color='secondary'
          variant='outlined'
          disableElevation
          sx={{ textTransform: 'capitalize' }}
          onClick={handleClose}
        >
          No
        </Button>
      </Stack>
    </AppDialog>
  );
};

export default RoleDeleteDialog;
