import { AuthUser } from '../store/auth/types';
import {
  apiClient,
  apiEndpoints,
  loginApiClient,
  changePasswordApiClient,
  forgotPasswordApiClient,
} from './apiClient';

type AuthResponse = {
  status: string;
  user: AuthUser;
};

const login = async (email: string, password: string) => {
  const response = await loginApiClient.post<AuthResponse>(apiEndpoints.login, {
    email,
    password,
  });

  localStorage.setItem(
    'accessToken',
    JSON.stringify(response.data.user.accessToken)
  );

  return response.data.user;
};

const getLoggedInUser = async (userId: string, accessToken: string) => {
  const response = await apiClient.get<AuthResponse>(
    `${apiEndpoints.users}/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return {
    ...response.data.user,
    accessToken,
  };
};

const logout = async () => {
  localStorage.removeItem('accessToken');
};

// password-related
const forgotPassword = async (email: string) => {
  const response = await forgotPasswordApiClient.put<{ data: string }>(
    apiEndpoints.forgotPassword,
    { email }
  );

  return response.data.data;
};

const setNewPassword = async (
  newPassword: string,
  confirmPassword: string,
  token: string
) => {
  const response = await changePasswordApiClient.put<{ message: string }>(
    `${apiEndpoints.resetPassword}/${token}`,
    { newPassword, confirmPassword }
  );
  return response.data.message;
};

const authApi = {
  login,
  logout,
  getLoggedInUser,
  setNewPassword,
  forgotPassword,
};

export default authApi;
