import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function RequestedAssetIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g
        id='Icon:_Requested_Asset'
        data-name='Icon: Requested Asset'
        transform='translate(-87.5 -17.485)'
      >
        <path
          id='Path_56'
          data-name='Path 56'
          d='M94.5,21a3.5,3.5,0,1,0-4.2,3.43v7.139a3.5,3.5,0,1,0,1.4,0V24.429A3.5,3.5,0,0,0,94.5,21Z'
          transform='translate(0 -0.015)'
          fill='#fff'
        />
        <path
          id='Path_57'
          data-name='Path 57'
          d='M315.643,31.554V26.234a5.949,5.949,0,0,0-5.249-5.9V18.185a.7.7,0,0,0-1.2-.493l-2.8,2.8a.7.7,0,0,0,0,.99l2.8,2.8a.7.7,0,0,0,1.2-.5v-2.04a4.549,4.549,0,0,1,3.85,4.49v5.319a3.5,3.5,0,1,0,1.4,0Z'
          transform='translate(-209.945)'
          fill='#fff'
        />
      </g>
    </SvgIcon>
  );
}

export function ProductIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path id='Path_10' data-name='Path 10' d='M0,0H24V24H0Z' fill='none' />
      <path
        id='Path_11'
        data-name='Path 11'
        d='M20,2H4A2.074,2.074,0,0,0,2,4V7.01A2.02,2.02,0,0,0,3,8.7V20a2.149,2.149,0,0,0,2,2H19a2.149,2.149,0,0,0,2-2V8.7a2.02,2.02,0,0,0,1-1.69V4A2.074,2.074,0,0,0,20,2ZM15,14H9V12h6Zm5-7H4V4l16-.02Z'
        fill='#fff'
      />
    </SvgIcon>
  );
}

export function UserIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g id='Icon:_User_Management' data-name='Icon: User Management' transform='translate(0.499)'>
        <path id='Path_10' data-name='Path 10' d='M0,0H24V24H0Z' fill='none' />
        <path
          id='Icon_awesome-user-edit'
          data-name='Icon awesome-user-edit'
          d='M8.75,10a5,5,0,1,0-5-5A5,5,0,0,0,8.75,10Zm3.5,1.25H11.6a6.8,6.8,0,0,1-5.7,0H5.25A5.251,5.251,0,0,0,0,16.5v1.625A1.875,1.875,0,0,0,1.875,20H12.613a1.876,1.876,0,0,1-.1-.832l.266-2.379.047-.434.309-.309,3.019-3.019a5.2,5.2,0,0,0-3.9-1.777Zm1.769,5.676-.266,2.383a.622.622,0,0,0,.687.687l2.379-.266,5.387-5.387-2.8-2.8-5.387,5.383ZM24.726,10.5l-1.48-1.48a.936.936,0,0,0-1.32,0L20.449,10.5l-.16.16,2.8,2.8,1.633-1.633a.941.941,0,0,0,0-1.324Z'
          transform='translate(-0.499 2)'
          fill='#fff'
        />
      </g>
    </SvgIcon>
  );
}

export function AssetIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path id='Path_21' data-name='Path 21' d='M0,0H24V24H0Z' fill='none' />
      <path id='Path_22' data-name='Path 22' d='M12,2,6.5,11h11Z' fill='#fff' />
      <circle
        id='Ellipse_2'
        data-name='Ellipse 2'
        cx='4.5'
        cy='4.5'
        r='4.5'
        transform='translate(13 13)'
        fill='#fff'
      />
      <path id='Path_23' data-name='Path 23' d='M3,13.5h8v8H3Z' fill='#fff' />
    </SvgIcon>
  );
}
