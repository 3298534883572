import * as yup from 'yup';

import { UserAccount } from '../../../store/accounts/types';

export const userAccountSchema = yup.object({
  firstName: yup.string().required('First Name is required').trim(),
  lastName: yup.string().required('Last Name is required').trim(),
  email: yup
    .string()
    .required('Email is required')
    .trim()
    .email('Must be a valid email'),
  roleId: yup.string().required('Role is required').trim(),
  isActive: yup.boolean().optional().nullable(),
});

export type UserAccountsFields = Omit<
  UserAccount,
  | 'id'
  | 'dateCreated'
  | 'dateUpdated'
  | 'completionStatus'
  | 'userId'
  | 'userRole'
>;

export const userAccountsInitialValues: UserAccountsFields = {
  firstName: '',
  email: '',
  isActive: true,
  lastName: '',
  roleId: '',
};
