import { apiClient, apiEndpoints, registerApiClient } from './apiClient';

import { UserAccount } from '../store/accounts/types';

type GetReturnType = {
  status: string;
  count: number;
  users: UserAccount[];
};

type GetOneReturnType = {
  status: string;
  user: UserAccount;
};

type IdReturnType = {
  status: string;
  user: {
    id: string;
  };
};

const getUserAccounts = async () => {
  const response = await apiClient.get<GetReturnType>(apiEndpoints.users);
  return response.data.users;
};

const getUserAccountById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(`${apiEndpoints.users}/${id}`);
  return response.data.user;
};

const createUserAccount = async (userAccountData: Partial<UserAccount>) => {
  const response = await registerApiClient.post<GetOneReturnType>(
    apiEndpoints.users,
    userAccountData
  );

  return response.data.user;
};

const deleteUserAccount = async (id: string) => {
  const response = await apiClient.delete(`${apiEndpoints.users}/${id}`);

  return response.data.purchaseOrder;
};

const updateUserAccount = async (id: string, userAccountData: Partial<UserAccount>) => {
  const response = await apiClient.put<IdReturnType>(
    `${apiEndpoints.users}/${id}`,
    userAccountData
  );
  return response.data.user;
};

const userAccountsApi = {
  getUserAccounts,
  getUserAccountById,
  createUserAccount,
  deleteUserAccount,
  updateUserAccount,
};

export default userAccountsApi;
