import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Stack, TextField, Typography } from '@mui/material';
import { appColors } from '../../../theme';

interface AppDatePickerProps {
  error?: boolean;
  helperText?: string;
  label: string;
  name: string;
  required?: boolean;
  value: Date | null;
  onChange: (value: any, keyboardInputValue?: string | undefined) => void;
}

const AppDatePicker = (props: AppDatePickerProps) => {
  const { error, helperText, value, onChange, label, required, name } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        inputFormat='MM/DD/YYYY'
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <Stack mb={1}>
            <Typography
              htmlFor={name}
              aria-required={required}
              color={appColors.textGray}
              component='label'
              fontSize={13}
              mb='5px'
            >
              {label}&nbsp;
              {required && (
                <Typography component='small' color='error.main'>
                  *
                </Typography>
              )}
            </Typography>
            <TextField
              {...params}
              id={name}
              error={error}
              helperText={helperText}
              size='small'
              InputProps={{ sx: { fontSize: '14px' }, ...params.InputProps }}
            />
          </Stack>
        )}
      />
    </LocalizationProvider>
  );
};

export default AppDatePicker;
