import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  flexRender,
} from '@tanstack/react-table';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import AppDialog from '../../../components/common/AppDialog';
import type { Order, OrderStatus } from '../../../store/order/types';
import { useState } from 'react';
import previewOrderColumns from './previewOrderColumnDef';
import { appColors } from '../../../theme';
import { Button, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../store';
import { selectPurchaseOrderById } from '../../../store/purchaseOrder/selectors';

interface OrderImportPreviewDialogProps {
  onClose: () => void;
  open: boolean;
  ordersToImport: Order[];
  onSave: () => void;
  isLoading?: boolean;
}

const ORDER_STATUS: OrderStatus[] = ['Incoming', 'On Hand', 'Arranged', 'Delivered', 'Failed'];

const OrderImportPreviewDialog = (props: OrderImportPreviewDialogProps) => {
  const { open, onClose, ordersToImport, onSave, isLoading } = props;

  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>();

  const po = useAppSelector(selectPurchaseOrderById(purchaseOrderId as string));

  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data: ordersToImport,
    columns: previewOrderColumns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleClose = () => {
    if (isLoading) return;

    onClose();
  };

  const invalidPONumberCount = ordersToImport.filter((i) => i.poNumber !== po?.poNumber).length;

  const hasInvalidStatus = ordersToImport.some((i) => !ORDER_STATUS.includes(i.status));

  const orderstoImportWithSN = ordersToImport.filter((o) => o.serialNumber !== '');

  const hasDuplicateSN =
    new Set(orderstoImportWithSN.map((o) => o.serialNumber)).size !== orderstoImportWithSN.length;

  const cantSave = invalidPONumberCount > 0 || hasInvalidStatus || hasDuplicateSN;

  return (
    <AppDialog
      open={open}
      onDialogClose={handleClose}
      title='Orders - Import Preview'
      maxWidth='md'
    >
      <Stack mb={2}>
        <Typography fontSize={14} fontWeight={500}>
          Total Rows: {ordersToImport.length}
        </Typography>
        <Typography fontSize={14} fontWeight={500}>
          P.O. Number: {po?.poNumber || 'Invalid'}
        </Typography>
        {invalidPONumberCount > 1 && (
          <Typography fontSize={14} fontWeight={500}>
            Invalid P.O. Number:{' '}
            <Typography component='span' color='error' fontSize={14} fontWeight={500}>
              {invalidPONumberCount}
            </Typography>
          </Typography>
        )}
        {hasInvalidStatus && (
          <Typography fontSize={14} fontWeight={500} color='error'>
            Check entries with invalid status
          </Typography>
        )}
        {hasDuplicateSN && (
          <Typography fontSize={14} fontWeight={500} color='error'>
            Check entries with duplicate serial numbers
          </Typography>
        )}
      </Stack>
      <Stack sx={{ maxHeight: '330px', overflow: 'hidden' }}>
        <TableContainer component={Paper}>
          <Table stickyHeader sx={{ height: '100%' }}>
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableCell
                      align='center'
                      key={header.id}
                      sx={{ whiteSpace: 'nowrap', fontSize: 12, color: appColors.textGray }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      sx={{
                        fontSize: 12,
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                        bgcolor: cell.getValue() ? 'white' : appColors.invalid,
                        color:
                          cell.column.columnDef.id === 'poNumber' &&
                          cell.getValue() !== po?.poNumber
                            ? 'error.main'
                            : 'unset',
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <Stack direction='row' gap={1.5} mt={4} justifyContent='end'>
        <Button
          onClick={onSave}
          disableElevation
          disabled={cantSave}
          variant='contained'
          sx={{ textTransform: 'capitalize', ml: 'auto' }}
        >
          {isLoading ? 'Saving...' : 'Import'}
        </Button>
        <Button
          onClick={handleClose}
          disableElevation
          variant='outlined'
          sx={{ textTransform: 'capitalize' }}
        >
          Cancel
        </Button>
      </Stack>
    </AppDialog>
  );
};

export default OrderImportPreviewDialog;
