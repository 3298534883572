import { Button } from '@mui/material';
import ViewIcon from '@mui/icons-material/Visibility';
import { appColors } from '../../theme';
import { useNavigate } from 'react-router-dom';

const ViewButton = ({ viewLink, state }: { viewLink: string; state?: any }) => {
  const navigate = useNavigate();

  const goToView = () => navigate(viewLink, { replace: true, state });

  return (
    <Button
      onClick={goToView}
      variant='outlined'
      size='small'
      startIcon={<ViewIcon />}
      sx={{
        borderColor: appColors.lightestDark,
        px: 1.5,
        textTransform: 'capitalize',

        '&:hover': {
          color: 'white',
          bgcolor: (theme) => theme.palette.primary.main,
        },
      }}
    >
      View
    </Button>
  );
};

export default ViewButton;
