import { apiClient, apiEndpoints } from './apiClient';
import { Product, ProductMedia } from '../store/product/types';
import { uploadMedia } from './upload';

type GetReturnType = {
  status: string;
  count: number;
  products: Product[];
};

type GetOneReturnType = {
  status: string;
  product: Product;
};

const getProducts = async () => {
  const response = await apiClient.get<GetReturnType>(apiEndpoints.products);
  return response.data.products;
};

const getProductById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(`${apiEndpoints.products}/${id}`);
  return response.data.product;
};

const createProduct = async (productData: Partial<Product>) => {
  // upload the media and map to ProductMedia array
  const { media } = productData;
  let productMedia: ProductMedia[] = [];

  if (media !== undefined) {
    productMedia = await uploadProductMedia(media);
  }

  const formData: Partial<Product> = {
    ...productData,
    media: productMedia,
  };

  const response = await apiClient.post<GetOneReturnType>(apiEndpoints.products, formData);
  return response.data.product;
};

const updateProduct = async (id: string, productData: Partial<Product>) => {
  const { media } = productData;
  let productMedia: ProductMedia[] = [];

  if (media !== undefined) {
    productMedia = await uploadProductMedia(media);
  }

  const formData: Partial<Product> = {
    ...productData,
    media: productMedia,
  };

  const product = { ...formData };
  delete product.id;

  const response = await apiClient.put<GetOneReturnType>(`${apiEndpoints.products}/${id}`, product);
  return response.data.product;
};

const deleteProduct = async (id: string) => {
  const response = await apiClient.delete(`${apiEndpoints.products}/${id}`);

  return response.data.product;
};

const uploadProductMedia = async (productMediaArr: ProductMedia[]) => {
  return await Promise.all<Promise<ProductMedia>[]>(
    productMediaArr.map(async (item) => {
      const { fileName, source } = item;

      let mediaUrl: string;
      if (typeof source === 'string') {
        mediaUrl = source;
      } else {
        const uploadRes = await uploadMedia(source, 'products');
        mediaUrl = uploadRes[0].path;
      }
      return {
        fileName,
        source: mediaUrl,
      };
    })
  ).then((values) => values);
};

const productApi = {
  getProducts,
  getProductById,
  createProduct,
  updateProduct,
  deleteProduct,
};

export default productApi;
