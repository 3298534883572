import { Routes, Route } from 'react-router-dom';
import NoAccessView from '../../components/common/NoAccessView';
import useProductsForAssets from '../../hooks/useProductsForAssets';
import AssetDetail from './AssetDetail';
import AssetList from './AssetList';
import CheckoutForm from './CheckoutForm';

const AssetPage = () => {
  const hasPermission = useProductsForAssets();

  if (!hasPermission) return <NoAccessView />;

  return (
    <>
      <Routes>
        <Route path='/' element={<AssetList />}>
          <Route path=':id' element={<AssetDetail />} />
          <Route path='checkout' element={<CheckoutForm />} />
        </Route>
        <Route path='*' element={<AssetList />} />
      </Routes>
    </>
  );
};

export default AssetPage;
