import { useNavigate } from 'react-router-dom';
import FormAddButton from '../../components/common/FormAddButton';
import CartIcon from '@mui/icons-material/ShoppingCart';
import { Typography } from '@mui/material';
import { useAppSelector } from '../../store';
import { getTotalCartQuantity } from '../../store/asset/cartSlice';

const CartButton = () => {
  const navigate = useNavigate();
  const totalQty = useAppSelector(getTotalCartQuantity);

  const openCheckoutForm = () => navigate('/assets/checkout', { replace: true });

  return (
    <FormAddButton
      onClick={openCheckoutForm}
      startIcon={undefined}
      disabled={totalQty === 0}
      sx={{ justifyContent: 'space-between' }}
    >
      <Typography
        component='span'
        fontSize='inherit'
        sx={{ display: 'inline-flex', alignItems: 'center', mr: 'auto', gap: 1, px: 1 }}
      >
        <CartIcon sx={{ fontSize: 18 }} />
        Order
      </Typography>
      <Typography
        component='span'
        fontSize='inherit'
        color='primary'
        fontWeight='bold'
        sx={{
          lineHeight: 1,
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 21,
          height: 21,
          bgcolor: 'white',
          borderRadius: '50%',
          mr: 0.8,
        }}
      >
        {totalQty}
      </Typography>
    </FormAddButton>
  );
};

export default CartButton;
