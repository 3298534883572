import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AppDialog from '../../../components/common/AppDialog';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { useAppDispatch, useAppSelector } from '../../../store';
import { selectUserAccountsState } from '../../../store/accounts/selectors';
import { deleteUserAccount } from '../../../store/accounts/slice';

const AccountDeleteDialog = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectUserAccountsState);
  const currentUserAccount = useCurrentUser();

  const handleClose = () => navigate('/users/accounts', { replace: true });

  const handleDelete = () => {
    if (!currentUserAccount) return null;

    dispatch(deleteUserAccount(currentUserAccount.id))
      .unwrap()
      .then(() => navigate('/users/accounts', { replace: true }));
  };

  const loading = status === 'loading';
  return (
    <AppDialog
      open
      onDialogClose={handleClose}
      title={`Delete: ${currentUserAccount?.firstName} ${currentUserAccount?.lastName}`}
    >
      <Typography textAlign='center'>
        Would you like to delete this user account?
      </Typography>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='center'
        mt={4}
        mb={2}
        gap={2}
      >
        <Button
          variant='contained'
          color='error'
          disableElevation
          sx={{ textTransform: 'capitalize', pointerEvents: 'initial' }}
          onClick={handleDelete}
        >
          {loading ? 'Deleting...' : 'Yes'}
        </Button>
        <Button
          color='secondary'
          variant='outlined'
          disableElevation
          sx={{ textTransform: 'capitalize' }}
          onClick={handleClose}
        >
          No
        </Button>
      </Stack>
    </AppDialog>
  );
};

export default AccountDeleteDialog;
