import { Stack, Typography } from '@mui/material';
import type { Product } from '../../store/product/types';
import { appColors } from '../../theme';

interface DetailRowProps {
  label: string;
  value: string;
}

const DetailRow = ({ label, value }: DetailRowProps) => {
  return (
    <Stack direction='row' gap={1}>
      <Typography fontSize={13} color={appColors.mediumDark}>
        {label}
      </Typography>
      <Typography fontSize={13} fontWeight={500}>
        {value ? value : '--'}
      </Typography>
    </Stack>
  );
};

const ProductDetail = ({ product }: { product: Product }) => {
  const { name, color, variant } = product;
  return (
    <Stack>
      <DetailRow label='Product Name:' value={name} />
      <DetailRow label='Variant:' value={variant} />
      <DetailRow label='Color:' value={color} />
    </Stack>
  );
};

export default ProductDetail;
