import { forwardRef } from 'react';
import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { appColors } from '../../../theme';

interface OwnProps {
  label?: string;
  required?: boolean;
  rootProps?: StackProps;
}

type AppFieldProps = OwnProps & Omit<TextFieldProps, 'label'>;

export const AppTextField = forwardRef<HTMLInputElement, AppFieldProps>(
  ({ label, required, rootProps, ...rest }, ref) => {
    return (
      <Stack mb={1} {...rootProps}>
        {label && (
          <Typography
            color={appColors.textGray}
            component='label'
            fontSize={13}
            htmlFor={rest.name}
            mb='5px'
          >
            {label}{' '}
            {required && (
              <Typography component='small' color='error.main'>
                *
              </Typography>
            )}
          </Typography>
        )}
        <TextField
          aria-required={required}
          id={rest.name}
          placeholder='Type here'
          {...rest}
          ref={ref}
          size='small'
          InputProps={{ sx: { fontSize: '14px' }, ...rest.InputProps }}
        />
      </Stack>
    );
  }
);

export default AppTextField;
